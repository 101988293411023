import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { Briefcase, Save, ChevronLeft, Eye, EyeOff } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm, Controller  } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'

// variables
import { SITENAME, _optCanton, _optCountry, _shortcodeLabelVisible, _optGender } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPOST, _apiPUT, _apiAvailability } from '../../controllers/Api';
import { _labelByValue, _itemByValue } from '../../controllers/Helpers';


export default function NotaryForm(props){

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch();
  const { id } = useParams()
  const auth = dispatch(_actions.auth.authStatus());

  const isShortcodeVisible = _shortcodeLabelVisible()

  const fields = {
    companyName: '',
    firstName: '',
    lastName: '',
    officeAddress:'',
    officeZipCode:'',
    officeMunicipality:'',
    officeCanton:'',
    agentFirstName:'',
    agentLastName:'',
    agentGender: '',
    agentDateBirth:null,
    agentAddress:'',
    agentZipCode:'',
    agentMunicipality:'',
    agentNationality:'',
    agentCityOrigin:'',
    agentCanton:''
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { control, register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    const getData = async () =>{
      setLoading(true)
      if(id !== 'new'){
        const res = await _apiGETone('/notary/'+id)
        console.log(res.error)
        if(!res.error){
            setFormData({...res.info})
            setValue('companyName', res.info.companyName)
            setValue('firstName', res.info.firstName)
            setValue('lastName', res.info.lastName)
            setValue('officeAddress', res.info.officeAddress)
            setValue('officeZipCode', res.info.officeZipCode)
            setValue('officeMunicipality', res.info.officeMunicipality)
            setValue('officeCanton', res.info.officeCanton)
            setValue('agentFirstName', res.info.agentFirstName)
            setValue('agentLastName', res.info.agentLastName)
            setValue('agentDateBirth', res.info.agentDateBirth)
            setValue('agentGender', res.info.agentGender)
            setValue('agentAddress', res.info.agentAddress)
            setValue('agentZipCode', res.info.agentZipCode)
            setValue('agentMunicipality', res.info.agentMunicipality)
            setValue('agentNationality', res.info.agentNationality)
            setValue('agentCityOrigin', res.info.agentCityOrigin)
            setValue('agentCanton', res.info.agentCanton)
        }
      }
      setLoading(false)
    }

    if(auth.status){
      getData()
    }

    return (() => [getData])
  },[id, auth.status])


  //
  //
  const _title = () =>{
    return `Notary - ${id==='new' ? 'New entry' : 'Update'}`
  }


  //
  //
  const _submit = async () => {

    if(id==='new'){

      const insert = await _apiPOST('/notary/', {clientId:auth.response.clientId, info:formData})
      if(insert.error){
        toast.error(insert.error.message);
      }else{
        toast.success(insert.message);
        history.push('/notary/'+insert._id)
      }

    }else{

      console.log(auth.response)

      const update = await _apiPUT('/notary/'+id, {clientId:auth.response.clientId, info:formData})
      if(update.error){
        toast.error(update.error.message);
      }else{
        toast.success(update.message);
      }

    }

  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <PageHead
        title={_title()}
        icon={<Briefcase size={52}/>}
      >
        <button className="button accent lg ml-1"><Save/>save</button>
      </PageHead>

      <div className="relative w-100">

        <div className="row">
          <div className="col-12 pt-3">

          <div className="row">

            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <label>Company name {isShortcodeVisible && <small>{'{NOTARY_COMPANY_NAME}'}</small>}</label>
                <input
                  {...register('companyName', { required: true })}
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={(e)=>_updateField('companyName',e.target.value)}
                  className={`form-control ${errors.companyName && 'error'}`}
                />
                {errors.companyName && <div className="errorField">This field is required</div>}
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>First name {isShortcodeVisible && <small>{'{NOTARY_FIRST_NAME}'}</small>}</label>
                <input
                  {...register('firstName', { required: true })}
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={(e)=>_updateField('firstName',e.target.value)}
                  className={`form-control ${errors.firstName && 'error'}`}
                />
                {errors.firstName && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>Last name {isShortcodeVisible && <small>{'{NOTARY_LAST_NAME}'}</small>}</label>
                <input
                  {...register('lastName', { required: true })}
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e)=>_updateField('lastName',e.target.value)}
                  className={`form-control ${errors.lastName && 'error'}`}
                />
                {errors.lastName && <div className="errorField">This field is required</div>}
              </div>
            </div>

            <div className="col-md-12">
              <hr/>
              <h3>Office</h3>
            </div>


            <div className="col-lg-6">
              <div className="form-group">
                <label>Office Address {isShortcodeVisible && <small>{'{NOTARY_OFFICE_ADDRESS}'}</small>}</label>
                <input
                  {...register('officeAddress', { required: true })}
                  type="text"
                  name="officeAddress"
                  value={formData.officeAddress}
                  onChange={(e)=>_updateField('officeAddress',e.target.value)}
                  className={`form-control ${errors.officeAddress && 'error'}`}
                />
                {errors.officeAddress && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>Office Zip code {isShortcodeVisible && <small>{'{NOTARY_OFFICE_ZIPCODE}'}</small>}</label>
                <input
                  {...register('officeZipCode', { required: true })}
                  type="text"
                  name="officeZipCode"
                  value={formData.officeZipCode}
                  onChange={(e)=>_updateField('officeZipCode',e.target.value)}
                  className={`form-control ${errors.officeZipCode && 'error'}`}
                />
                {errors.officeZipCode && <div className="errorField">Required</div>}
              </div>
            </div>


            <div className="col-md-5">
              <div className="form-group">
                <label>Office Municipality {isShortcodeVisible && <small>{'{NOTARY_OFFICE_MUNICIPALITY}'}</small>}</label>
                <input
                  {...register('officeMunicipality', { required: true })}
                  type="text"
                  name="officeMunicipality"
                  value={formData.officeMunicipality}
                  onChange={(e)=>_updateField('officeMunicipality',e.target.value)}
                  className={`form-control ${errors.officeMunicipality && 'error'}`}
                />
                {errors.officeMunicipality && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Office Canton {isShortcodeVisible && <small>{'{NOTARY_OFFICE_CANTON}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="officeCanton"
                      value={_itemByValue(formData.officeCanton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateField('officeCanton',e.value)}}
                      className={`form-control p-0 ${errors.officeCanton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="officeCanton"
                />
                {errors.officeCanton && <div className="errorField">This field is required</div>}
              </div>
            </div>

            <div className="col-md-12">
              <hr/>
              <h3>Agent</h3>
            </div>


            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>Agent First name {isShortcodeVisible && <small>{'{NOTARY_AGENT_FIRST_NAME}'}</small>}</label>
                <input
                  {...register('agentFirstName', { required: true })}
                  type="text"
                  name="agentFirstName"
                  value={formData.agentFirstName}
                  onChange={(e)=>_updateField('agentFirstName',e.target.value)}
                  className={`form-control ${errors.agentFirstName && 'error'}`}
                />
                {errors.agentFirstName && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>Agent Last name {isShortcodeVisible && <small>{'{NOTARY_AGENT_LAST_NAME}'}</small>}</label>
                <input
                  {...register('agentLastName', { required: true })}
                  type="text"
                  name="agentLastName"
                  value={formData.agentLastName}
                  onChange={(e)=>_updateField('agentLastName',e.target.value)}
                  className={`form-control ${errors.agentLastName && 'error'}`}
                />
                {errors.agentLastName && <div className="errorField">This field is required</div>}
              </div>
            </div>


            <div className="col-md-6 col-lg-3">
              {/* <div className="form-group">
                <label>Agent Date of birth {isShortcodeVisible && <small>{'{NOTARY_AGENT_BIRTHDATE}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <DatePicker
                      selected={formData.agentDateBirth ? new Date(formData.agentDateBirth) : formData.agentDateBirth}
                      onChange={(date)=> {field.onChange(date); _updateField('agentDateBirth',date)}}
                      name="agentDateBirth"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control"
                      dateFormat="dd.MM.yyyy"
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="agentDateBirth"
                />
                {errors.agentDateBirth && <div className="errorField">This field is required</div>}
              </div> */}
              <div className="form-group">
                <label>Agent Date of birth {isShortcodeVisible && <small>{'{NOTARY_AGENT_BIRTHDATE}'}</small>}</label>
                <input
                  {...register('agentDateBirth', { required: true })}
                  type="text"
                  name={"agentDateBirth"}
                  value={formData.agentDateBirth}
                  onChange={(e)=> _updateField('agentDateBirth',e.target.value)}
                  className={`form-control ${errors.agentDateBirth && 'error'}`}
                />
                {errors.agentDateBirth && <div className="errorField">This field is required</div>}
              </div>
            </div>


            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>Gender {isShortcodeVisible && <small>{'(gender)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="agentGender"
                      value={_itemByValue(formData.agentGender, _optGender)}
                      options={_optGender}
                      onChange={(e)=> {field.onChange(e.value); _updateField('agentGender',e.value)}}
                      className={`form-control p-0 ${errors.agentGender && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="agentGender"
                />
                {errors.agentGender && <div className="errorField">This field is required</div>}
              </div>
            </div>


            <div className="col-md-6">
              <div className="form-group">
                <label>Agent Address {isShortcodeVisible && <small>{'{NOTARY_AGENT_ADDRESS}'}</small>}</label>
                <input
                  {...register('agentAddress', { required: true })}
                  type="text"
                  name="agentAddress"
                  value={formData.agentAddress}
                  onChange={(e)=>_updateField('agentAddress',e.target.value)}
                  className={`form-control ${errors.agentAddress && 'error'}`}
                />
                {errors.agentAddress && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Agent Zip code {isShortcodeVisible && <small>{'{NOTARY_AGENT_ZIPCODE}'}</small>}</label>
                <input
                  {...register('agentZipCode', { required: true })}
                  type="text"
                  name="agentZipCode"
                  value={formData.agentZipCode}
                  onChange={(e)=>_updateField('agentZipCode',e.target.value)}
                  className={`form-control ${errors.agentZipCode && 'error'}`}
                />
                {errors.agentZipCode && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Agent Municipality {isShortcodeVisible && <small>{'{NOTARY_AGENT_MUNICIPALITY}'}</small>}</label>
                <input
                  {...register('agentMunicipality', { required: true })}
                  type="text"
                  name="agentMunicipality"
                  value={formData.agentMunicipality}
                  onChange={(e)=>_updateField('agentMunicipality',e.target.value)}
                  className={`form-control ${errors.agentMunicipality && 'error'}`}
                />
                {errors.agentMunicipality && <div className="errorField">Required</div>}
              </div>
            </div>

            <div className="col-md-12">
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Agent Nationality {isShortcodeVisible && <small>{'{NOTARY_AGENT_NATIONALITY}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="agentNationality"
                      value={_itemByValue(formData.agentNationality, _optCountry, 'code')}
                      options={_optCountry}
                      onChange={(e)=> {field.onChange(e.code); _updateField('agentNationality',e.code)}}
                      className={`form-control p-0 ${errors.agentNationality && 'error'}`}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.code}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="agentNationality"
                />
                {errors.agentNationality && <div className="errorField">This field is required</div>}
              </div>
            </div>
            {formData.agentNationality === 'CH' &&
            <>
            <div className="col-md-4">
              <div className="form-group">
                <label>Agent City of origin {isShortcodeVisible && <small>{'{NOTARY_AGENT_CITY_ORIGIN}'}</small>}</label>
                <input
                  {...register('agentCityOrigin', { required: true })}
                  type="text"
                  name="agentCityOrigin"
                  value={formData.agentCityOrigin}
                  onChange={(e)=>_updateField('agentCityOrigin',e.target.value)}
                  className={`form-control ${errors.agentCityOrigin && 'error'}`}
                />
                {errors.agentCityOrigin && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Agent Canton {isShortcodeVisible && <small>{'{NOTARY_AGENT_CANTON}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="agentCanton"
                      value={_itemByValue(formData.agentCanton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateField('agentCanton',e.value)}}
                      className={`form-control p-0 ${errors.agentCanton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="agentCanton"
                />
                {errors.agentCanton && <div className="errorField">This field is required</div>}
              </div>
            </div>
            </>
            }

          </div>

          </div>
        </div>

        <Loading status={loading}/>
      </div>
      </form>

    </Fragment>
  );

}
