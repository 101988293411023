import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { GitPullRequest, ChevronLeft, CheckCircle, MessageSquare, FileText, Activity, Mail, Clock } from 'react-feather'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs';
import fileDownload from 'js-file-download'

// variables
import { SITENAME, _optRequestStatuses, _shortcodeLabelVisible } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';


import RequestFormsStep0 from './step0';
import RequestFormsStep11 from './step11';

import TimeSheet from './timeSheet'

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPOST, _apiPUT, _apiGetFile } from '../../controllers/Api';
import { _goBack, _itemByValue, _returnMaxStep } from '../../controllers/Helpers';


export default function ChangeOwnershipForms(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const [ loading, setLoading ] = React.useState(true);
  const [ data, setData ] = React.useState({})

  const [ step, setStep ] = React.useState(0)
  const [ validStep, setValidStep ] = React.useState(0)
  const [ status, setStatus ] = React.useState(1)
  const [ rand, setRand ] = React.useState(1)

  const [ notesTotal ,setNotesTotal ] = React.useState(0)
  const [ timesheetTotal ,setTimesheetTotal ] = React.useState(0)

  const [ isShortcodeVisible, setIsShortcodeVisible ] = React.useState(false)


  //
  //
  const _title = () =>{
    return 'Request for ownership changes'
  }


  //
  //
  const _submit = async (k,v) => {

    if(id==='new'){

      const formData = {
        ...v,
        status:1,
        userId: auth.response._id,
        clientId: auth.response.clientId
      }

      const insert = await _apiPOST('/change-ownership/', formData)
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        history.push('/requests/change-ownership/'+insert._id)
      }

    }else{

      let formData = {invoiceNumber: data.invoiceNumber ? data.invoiceNumber : null, ...v}

      const update = await _apiPUT('/change-ownership/'+id, formData)
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        setRand(rand+1)
      }

    }

  }


  //
  //
  const _getPdf = async (filename, doc) => {

    const response = await _apiGetFile('/change-ownership/pdf/', {
      documentKey:doc,
      requestId:id,
      userId:auth.response._id,
      clientId:auth.response.clientId
    })
    if(response){
      fileDownload(response, filename);
      setRand(rand+1)
    }

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    let route = '/change-ownership/'

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone(route+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          setData({...res})
          setStatus(res.status)

          const _validStep = _returnMaxStep(res)
          // console.log('_returnMaxStep(res)',_validStep, rand)
          setValidStep(_validStep)
          // if(rand==1){
          //   setStep(_validStep)
          // }
        }
      }
      setLoading(false)
    }
    if(auth.status){
      setIsShortcodeVisible(_shortcodeLabelVisible())
      getData()
    }

    return (() => [getData])
  },[id, auth.status, auth.response.role, rand])


  //
  //
  const _updateRand = () => {
    setRand(rand+1);
    // console.log('rand',rand)
  }


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <PageHead
        title={_title()}
        description={`Last update: ${data.dateUpdated ?  dayjs.tz(data.dateUpdated).format('DD.MM.YYYY') : 'N/A'} | by: ${auth.response.firstName} ${auth.response.lastName}`}
        icon={<GitPullRequest size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
      </PageHead>

      <div className="relative w-100 tabs">

        <div className="tabNav">
          <div>
            <button onClick={()=> setStep(0)} className={`${id !== 'new' && 'completed'} ${step === 0 && 'active'}`}>{id !== 'new' && <CheckCircle size={16}/>}Request</button>
          </div>
          <div>
            <button onClick={()=> setStep(12)} className={`${step === 12 && 'active'}`} disabled={id === 'new' ? true : false}><Clock size={16}/>Timesheet{timesheetTotal !== 0 && ' ('+timesheetTotal+')'}</button>
            <button onClick={()=> setStep(11)} className={`${step === 11 && 'active'}`} disabled={id === 'new' ? true : false}><FileText size={16}/>Documents</button>
          </div>
        </div>
        <div className="tabContent">

          <div className={step===0 ? '' : 'd-none'}>
            <RequestFormsStep0 data={data} action={_submit} isNew={id==='new'} isShortcodeVisible={isShortcodeVisible}/>
          </div>
          
          <div className={step===12 ? '' : 'd-none'}>
            <TimeSheet data={data} updateTimesheetTotal={setTimesheetTotal} />
          </div>

          {id !== 'new' && step===11 &&
          <div>
            <RequestFormsStep11 id={id} rand={rand} auth={auth} data={data} action={_getPdf} validStep={validStep}/>
          </div>
          }

        </div>

        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
