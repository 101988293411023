import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { BarChart2 } from 'react-feather'
import { useDispatch } from 'react-redux'
import DatePicker, { registerLocale } from 'react-datepicker'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveCalendar } from '@nivo/calendar'
import fileDownload from 'js-file-download'
import dayjs from 'dayjs'

// variables
import { SITENAME } from '../../configuration.js';

// components
import Loading from '../../components/Loading';
import PageHead from '../../components/PageHead';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETmulti, _apiGetFile } from '../../controllers/Api';


export default function Dashboard(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const fields = {
    dateFrom: '',
    dateTo: ''
  }


  const [ loading, setLoading ] = React.useState(true);
  const [ request, setRequest ] = React.useState(fields)
  const [ type, setType ] = React.useState('client')
  const [ totals, setTotals ] = React.useState({clients:0, requests:0, timeSheet:{t1:0,t2:0,t3:0,t4:0,t5:0}});
  const [ dataOrdersByDay, setDataOrdersByDay ] = React.useState([]);
  const [ dataOrdersByMonthAndStatus, setDataOrdersByMonthAndStatus ] = React.useState([]);
  const [ rand, setRand ] = React.useState(1)


  //
  //
  const _updateField = (field, data) => {
    setRequest({...request, [field]:data});
  }


  // 
  // 
  const _export = async(randId) => {
    
    const response = await _apiGetFile('/dashboard/export/'+auth.response.clientId, {...request, rand:randId})
    if(response){
      fileDownload(response, 'export_'+dayjs.tz().format('DD_MM_YYYY_HHmmss')+'.csv', 'text/csv;charset=utf-8', '\uFEFF');
      setRand(randId+1)
      // window.location.reload()
    }

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{

      let endpoint = '/dashboard/'+auth.response.clientId
      if(auth.response.role<10){
        endpoint = '/dashboard/'
        setType('admin')
      }

      const res = await _apiGETmulti(endpoint, request)
      if(res){
        setTotals(res.totals)
        setDataOrdersByDay(res.byDay)
        setDataOrdersByMonthAndStatus(res.byStatus)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[request, auth.response.clientId, auth.response.role])



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{ SITENAME }</title>
      </Helmet>

      <PageHead
        title="Dashboard"
        icon={<BarChart2 size={52}/>}
      >
        <div className="row no-gutters cs1Form">
          <div className="col-lg-4">
            <div className="form-group">
              <label>Date from</label>
              <DatePicker
                selected={request.dateFrom}
                onChange={(date) => _updateField('dateFrom',date)}
                selectsStart
                startDate={request.dateFrom}
                endDate={request.dateTo}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Date to</label>
              <DatePicker
                selected={request.dateTo}
                onChange={(date) => _updateField('dateTo',date)}
                selectsEnd
                startDate={request.dateFrom}
                endDate={request.dateTo}
                minDate={request.dateFrom}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-lg-4 pt-1">
            <button
              type="button"
              className="button mt-4 w-100 py-3"
              onClick={()=>_export(rand)}
            >Export CSV</button>
          </div>
        </div>
      </PageHead>
      <div className="w-100">

        <div className="row">

          <div className="col-lg-8">
            <h4>Requests by day</h4>
            <div className="mb-4" style={{height:'150px'}}>
              {loading ? <Loading text="loading..."/> :
                <ResponsiveCalendar
                  data={dataOrdersByDay}
                  from="2021-01-01"
                  to="2021-12-31"
                  emptyColor="#eeeeee"
                  colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                  margin={{ top: 0, right: 30, bottom: 0, left: 30 }}
                  yearSpacing={40}
                  monthBorderColor="#ffffff"
                  dayBorderWidth={2}
                  dayBorderColor="#ffffff"
                  legends={[
                      {
                          anchor: 'bottom-right',
                          direction: 'row',
                          translateY: 36,
                          itemCount: 4,
                          itemWidth: 42,
                          itemHeight: 36,
                          itemsSpacing: 14,
                          itemDirection: 'right-to-left'
                      }
                  ]}
                />
              }
            </div>
          </div>

          <div className="col-lg-4">
            <div className="row">
              {type === 'admin' &&
              <div className="col-lg-6 my-4">
                <div className="card">
                {loading ? <Loading text="loading..."/> :
                  <>
                  <p>CLIENTS</p>
                  <h2>{totals.clients}</h2>
                  <small>{JSON.stringify(request)===JSON.stringify(fields) ? 'last year' : 'by selected range'}</small>
                  </>
                }
                </div>
              </div>
              }
              <div class={`col-lg-${type==='admin' ? '6' : '12'} my-4`}>
                <div className="card">
                {loading ? <Loading text="loading..."/> :
                  <>
                  <p>REQUESTS</p>
                  <h2>{totals.requests}</h2>
                  <small>{JSON.stringify(request)===JSON.stringify(fields) ? 'last year' : 'by selected range'}</small>
                  </>
                }
                </div>
              </div>
            </div>
          </div>

        </div>

      <div className="row">

        <div className="col-lg-8">

          <h4>Requests by status</h4>
          <div className="card p-0 pr-3" style={{height:'400px'}}>
            {loading ? <Loading text="loading..."/> :
            <ResponsiveLine
              data={dataOrdersByMonthAndStatus}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'total requests',
                  legendOffset: -40,
                  legendPosition: 'middle'
              }}
              pointSize={10}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                  {
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                          {
                              on: 'hover',
                              style: {
                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                  itemOpacity: 1
                              }
                          }
                      ]
                  }
              ]}
            />
            }
          </div>
        
        </div>

        <div className="col-lg-4">
          <div className="row mt-2">
              <div className="col-lg-12 my-4">
              <div className="card">
              {loading ? <Loading text="loading..."/> :
                <>
                <p>TIMESHEET</p>
                <hr className='my-1 py-0'/>
                <div className='row py-2' style={{borderBottom:'1px solid #eee'}}>
                  <div className='col-8'>1er Appel</div>
                  <div className='col-4 text-right'><strong>{totals.timeSheet.t1} ({totals.timeSheet.t1a})</strong></div>
                </div>
                <div className='row py-2' style={{borderBottom:'1px solid #eee'}}>
                  <div className='col-8'>Préparation documents</div>
                  <div className='col-4 text-right'><strong>{totals.timeSheet.t2} ({totals.timeSheet.t2a})</strong></div>
                </div>
                <div className='row py-2' style={{borderBottom:'1px solid #eee'}}>
                  <div className='col-8'>Préparation envoi notaire</div>
                  <div className='col-4 text-right'><strong>{totals.timeSheet.t3} ({totals.timeSheet.t3a})</strong></div>
                </div>
                <div className='row py-2' style={{borderBottom:'1px solid #eee'}}>
                  <div className='col-8'>Retour RC</div>
                  <div className='col-4 text-right'><strong>{totals.timeSheet.t4} ({totals.timeSheet.t4a})</strong></div>
                </div>
                <div className='row py-2' style={{borderBottom:'1px solid #eee'}}>
                  <div className='col-8'>Divers</div>
                  <div className='col-4 text-right'><strong>{totals.timeSheet.t5} ({totals.timeSheet.t5a})</strong></div>
                </div>
                <div className='row py-2' style={{borderBottom:'1px solid #eee'}}>
                  <div className='col-8'><strong>TOTAL</strong></div>
                  <div className='col-4 text-right'><strong>{totals.timeSheet.t1+totals.timeSheet.t2+totals.timeSheet.t3+totals.timeSheet.t4+totals.timeSheet.t5} ({Math.round((totals.timeSheet.t1+totals.timeSheet.t2+totals.timeSheet.t3+totals.timeSheet.t4+totals.timeSheet.t5)/totals.timeSheet.tr)})</strong></div>
                </div>
                </>
              }
              </div>
              </div>
          </div>
        </div>

      </div>


      </div>
    </Fragment>
  );

}



// {loading && <Loading status={true} cssClass="full"/>}
// <div key="1">{_box1()}</div>
// <div key="2">{_box2()}</div>
// <div key="3">{_box3()}</div>
// <div key="4">{_box4()}</div>
