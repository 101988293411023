import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import QueueAnim from 'rc-queue-anim';

import { ChevronDown } from 'react-feather';

// controllers
import { navItems} from '../../configuration.js'
import { _checkRole } from '../../controllers/Helpers'


//
//
export default function Sidebar(props) {

  let location = useLocation();
  const [ navicon, setNavicon ] = useState(false);

  const [activeTop, setActiveTop] = useState('');
  const [activeSub, setActiveSub] = useState('');


  //
  //
  useEffect(() => {
    if(location.pathname==='/'){
      setActiveTop('');
    }
    let current = window.location.pathname.split('/');
    if(current[1]){
      setActiveTop(current[1].toString());
      if(!current[2]){
        setActiveSub('');
      }
    }
    if(current[2]){
      setActiveSub(current[2].toString());
    }
  },[location.pathname]);


  //
  //
  const _isActive = (level, current) => {
    if(current){
      const item = current.toString().split('/')
      if(level===1){
        if(item[1].toString()===activeTop){
          return 'active';
        }
      }
      if(level===2){
        console.log(item, activeSub)
        if(item[2] && item[2]===activeSub){
          return 'active';
        }
      }
    }
  }


  //
  //
  const _open = () => {
    props.open(!navicon);
    setNavicon(!navicon);
  }

  //
  //
  const _expand = (current) => {
    const item = current.toString().split('/')
    setActiveTop(item[1] && item[1].toString()===activeTop ? '' : item[1].toString());
  }


  //
  //
  const _children = (data) => {
    return data.map((item,i)=>{
      let isVisible = _checkRole(item.role, props.auth.response.role)
      return(
        isVisible ?
          item.type==='link' &&
            <div key={i} className={`item ${_isActive(2,item.link)}`}>
                <Link to={item.link} onClick={ navicon ? _open : null}>
                  {item.icon && item.icon}<span>{item.title}</span>
                </Link>
            </div>
        : null
      )
    })
  }


  //
  //
  const _items = () =>{
    if(navItems){
      return navItems.map((item,i)=>{
        let isVisible = _checkRole(item.role, props.auth.response.role)
        return(
          isVisible ?
            item.type==='link' ?
                <div key={i} className={_isActive(1,item.link) ? 'item active' : 'item'}>
                  <Link to={item.link}>
                    {item.icon && item.icon}<span>{item.title}</span>
                  </Link>
                  {item.children && <button className="expand" onClick={() => { _expand(item.link) }}><ChevronDown size={16}/></button>}
                  {item.children && <div className="children">{_children(item.children)}</div>}
                </div>
            :
              <div className="item title" key={i}>{item.title}</div>
          : null
        )
      })
    }
  }


  //
  //
  return (
    !props.isLoggedIn ? null :
    <Fragment>
      <div className="sidebar">

        <a href={'/'} className="logo" aria-label="">
          <strong>SAAS</strong>
          <span>ENTREPRENDRE.CH</span>
        </a>

        <div className="inner">
          <QueueAnim type={['right', 'left']} className="nav">
            {_items()}
          </QueueAnim>
        </div>

        <div className="copyright">
          Copyright ©2021
        </div>

      </div>
    </Fragment>
  );
};
