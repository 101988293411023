import React, { Fragment } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather'
import ReactPaginate from 'react-paginate'

// components
import Loading from '../../components/Loading';
import Table from '../../components/Table';

// controllers
import { _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep8(props){

  const fields = {
    limit: 15,
    offset: 0,
    orderBy: 'dateCreated|desc'
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ requestAction, setRequestAction ] = React.useState(fields)
  const [ data, setData ] = React.useState([])
  const [ total, setTotal ] = React.useState(0)


  //
  //
  const _paginateAction = (e) => {
    const offset = e.selected === 0 ? 0 : (e.selected*fields.limit)
    _updateField('offset',offset)
  }


  //
  //
  const _updateField = (field, data) => {
    setRequestAction({...requestAction, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      const res = await _apiGETmulti('/logs/'+props.id, requestAction)
      if(!res){
        setTotal(0)
        setData([])
      }else{
        setTotal(res.total)
        setData(res.data)
        props.getTotal(res.total)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[requestAction, props.rand])


  const _author = (msg, index) => {
    if(msg.user[0] && msg.user[0].firstName){
      return (
        <>
          {msg.user[0].firstName} {msg.user[0].lastName}
        </>
      )
    }
  }


  //
  //
  return (
    <Fragment>

      <div className="relative w-100">

        {total===0 ?
          <div className="text-center py-4">{loading ? 'loading...' : 'No logs available.'}</div>
        :
          <>
          <Table
            action={_updateField}
            perPage={requestAction.limit}
            sortBy={requestAction.orderBy}
            head={
              [
                {name:'Message', width:'auto', sortable:false, key:'message'},
                {name:'Author', width:'15%', sortable:false, key:'_', type:'function', func:_author},
                {name:'Created on', width:'15%', sortable:true, key:'dateCreated', type:'date', format:'DD.MM.YYYY HH:mm'}
              ]
            }
            body={data}
            total={total}
          />
          <ReactPaginate
            previousLabel={<ChevronLeft/>}
            nextLabel={<ChevronRight/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(total/fields.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={_paginateAction}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          </>
        }

        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
