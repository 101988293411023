import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { List, PlusCircle, Edit, XCircle, ChevronLeft, ChevronRight } from 'react-feather'
import { Link, useLocation  } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'

// variables
import { SITENAME, _optCanton } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import TextSearchField from '../../components/TextSearchField';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETmulti, _apiDELETE } from '../../controllers/Api';
import { _itemByValue } from '../../controllers/Helpers';


export default function TemplateRegisters(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());
  const location = useLocation()

  const fields = {
    limit: 15,
    offset: 0,
    orderBy: 'dateCreated|desc',
    search: ''
  }

  const [ reload, setReload ] = React.useState(1);

  const [ loading, setLoading ] = React.useState(true);
  const [ request, setRequest ] = React.useState(location.state ? location.state.request : fields)
  const [ requestAction, setRequestAction ] = React.useState(location.state ? location.state.request : fields)
  const [ total, setTotal ] = React.useState(0)
  const [ data, setData ] = React.useState([])


  //
  //
  const _paginateAction = (e) => {
    const offset = e.selected === 0 ? 0 : (e.selected*fields.limit)
    _updateField('offset',offset)
  }



  //
  //
  const _updateField = (field, data) => {
    setRequest({...request, [field]:data});
  }


  //
  //
  const _delete = async (id) => {

    if (!window.confirm(`Are you sure you want to delete?`)){
      return;
    }else{

      const route = '/list-registers/'

      const del = await _apiDELETE(route+id);
      if(del.statusCode !== 200){
        toast.error(del.message);
      }else{
        toast.success(del.message);
        setReload(reload+1)
      }

    }

  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => request !== '' ? setRequestAction(request) : null, 500 );
    return () => clearTimeout(timeoutId);
  }, [request]);


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const route = '/list-registers/query'

    const getData = async () =>{

      const res = await _apiGETmulti(route, requestAction)
      if(!res){

      }else{
        setTotal(res.total)
        setData(res.data)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[requestAction, reload, auth.response.role])


  //
  //
  const _canton = (data, index) => {
    const value = _itemByValue(data.canton, _optCanton)
    return value.label;
  }


  //
  //
  const _options = (data, index) => {

    return (
      <div className="d-flex justify-content-end align-items-center">
        <Link to={{pathname:'/templates/registers/'+data._id, state:{request:request, pathname:location.pathname}}} className="iconButton"><Edit size={20}/></Link>
        <button className="iconButton" onClick={()=>_delete(data._id)}><XCircle size={20}/></button>
      </div>
    )

  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Templates / Banks | { SITENAME }</title>
      </Helmet>

      <PageHead
        title="Templates / Registers"
        icon={<List size={52}/>}
      >
        <TextSearchField
          type="text"
          name="search"
          value={request.search}
          onChange={(e)=>_updateField('search',e)}
          placeholder="search..."
        />
        <Link to={{pathname:'/templates/registers/new', state:{request:request, pathname:location.pathname}}} className="button accent ml-1"><PlusCircle/>Add new</Link>
      </PageHead>

      <div className="relative w-100">
        {total===0 ?
          <div className="text-center py-4">{loading ? 'loading...' : 'No data vailable.'}</div>
        :
          <>
          <Table
            action={_updateField}
            perPage={request.limit}
            sortBy={request.orderBy}
            head={
              [
                {name:'Canton', width:'auto', sortable:false, key:'_', type:'function', func:_canton},
                {name:'Created on', width:'15%', sortable:true, key:'dateCreated', type:'date'},
                {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
              ]
            }
            body={data}
            total={total}
          />
          <ReactPaginate
            previousLabel={<ChevronLeft/>}
            nextLabel={<ChevronRight/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(total/fields.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={_paginateAction}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          </>
        }
        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
