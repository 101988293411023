import React, { Fragment } from 'react';


//
//
export default function PageHead(props) {


  //
  //
  return (
    <Fragment>
      <div className={`container-fluid pageHead ${props.bottomOff ? 'noBottom' : ''}`}>
        <div className="row no-gutters">
          <div className="col-lg-6 title">
            {props.title &&
              <>
              {props.icon && <div className="icon">{props.icon}</div>}
              <div>
                <h2>{props.title}</h2>
                {props.description && <div className="desc">{props.description}</div>}
              </div>
              </>
            }
          </div>
          {props.children &&
            <div className="col-lg-6 d-flex justify-content-end align-items-center">
            {props.children}
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
};
