// Staging

import {
  BarChart2,
  Briefcase,
  GitPullRequest,
  FileText,
  Copy,
  Users,
  Settings,
  Award
} from 'react-feather';

// export const ApiUrl = 'http://127.0.0.1:7002';
// export const ApiUrl = 'https://entreprendre.ebabic.dev';
export const ApiUrl = process.env.REACT_APP_API_URL;

export const SITENAME = 'Entreprendre App';
export const SITEURL = 'https://localhost:1234';
export const DateFormat = 'DD MMM. YYYY HH:mm';


export const _shortcodeLabelVisible = () => {
  return process.env.REACT_APP_SHORTCODE_LABEL==='true' ? true : false;
}


export const navItems = [
  {
    type:'title',
    title: 'Menu',
    link:null,
    role:null
  },
  {
    type:'link',
    title: 'Dashboard',
    link:'/',
    role:[1,2,10,11],
    icon: <BarChart2/>
  },
  {
    type:'link',
    title: 'Clients',
    link:'/clients/',
    role:[1,2],
    icon: <Briefcase/>
  },
  {
    type:'link',
    title: 'Requests',
    link:'/requests/',
    role:[10,11],
    icon: <GitPullRequest/>,
    children:[
		{
			type:'link',
			title: 'Company creation',
			link:'/requests/created/',
			role:[10,11],
		},
		{
			type:'link',
			title: 'Change address',
			link:'/requests/change-address/',
			role:[10,11],
		},
		{
			type:'link',
			title: 'Change ownership',
			link:'/requests/change-ownership/',
			role:[10,11],
		},
		{
			type:'link',
			title: 'Change goal',
			link:'/requests/change-goal/',
			role:[10,11],
		},
		{
			type:'link',
			title: 'Change name',
			link:'/requests/change-name/',
			role:[10,11],
		},
		{
			type:'link',
			title: 'Parts transfert',
			link:'/requests/parts-transfert/',
			role:[10,11],
		}
    ]
  },
  {
    type:'link',
    title: 'Invoices',
    link:'/invoices/',
    role:[10,11],
    icon: <FileText/>
  },
  {
    type:'link',
    title: 'Templates',
    link:'/templates/documents/',
    role:[1,2,10,11],
    icon: <Copy/>,
    children:[
      {
        type:'link',
        title: 'Documents',
        link:'/templates/documents/',
        role:[1,2,10,11],
      },
      {
        type:'link',
        title: 'Emails',
        link:'/templates/emails/',
        role:[1,2,10,11],
      },
      {
        type:'link',
        title: 'Banks',
        link:'/templates/banks/',
        role:[1,2,10,11],
      },
      {
        type:'link',
        title: 'Registers',
        link:'/templates/registers/',
        role:[1,2],
      }
    ]
  },
  {
    type:'link',
    title: 'Notary',
    link:'/notary/',
    role:[10],
    icon: <Award/>
  },
  {
    type:'link',
    title: 'Users',
    link:'/users/',
    role:[10],
    icon: <Users/>
  },
  // {
  //   type:'link',
  //   title: 'Settings',
  //   link:'/settings/',
  //   role:[10,11],
  //   icon: <Settings/>
  // },
  {
    type:'link',
    title: 'Administrators',
    link:'/administrators/',
    role:[1,2],
    icon: <Users/>
  },
]



export const _companyFields = {
  companyName: '',
  legalStructures: '',
  notaryId: null,
  address: '',
  zipCode: '',
  municipalityAddress: '',
  municipalitySiege: '',
  canton: '',
  goal: '',
  billing: true,
  billingCompanyName: '',
  billingAddress: '',
  billingZipCode: '',
  billingCity: '',
  capital: 20000,
  capitalWritten: '',
  partsNumbers: 1,
  partsWritten: '',
  partsValue: 0,
  partsValueWritten: '',
  capitalLibere: 0,
  bankId: null,
  date1stCloture: null,
  dateStatus: null,
  dateDeposit: null,
  dateInvoice: null,
  dateCompleted: null,
  dateCreation: null
}


export const _companyFieldsChangeOwnership = {
	companyName: '',
	legalStructures: '',
	notaryId: null,
	address: '',
	zipCode: '',
	citySiege: '',
	canton: '',
	action: '',
	firstName: '',
	lastName: '',
	email:''
}


export const _companyFieldsChangeAddress = {
	companyName: '',
	companyId: '',
	legalStructures: '',
	notaryId: null,
	address: '',
	zipCodeOld: '',
	zipCodeNew: '',
	addressOld: '',
	addressNew: '',
	municipalityAddressOld: '',
	municipalitySiegeOld: '',
	municipalityAddressNew: '',
	municipalitySiegeNew: '',
	cantonOld: '',
	cantonNew: '',
	goal: '',
	billing: true,
	billingCompanyName: '',
	billingAddress: '',
	billingZipCode: '',
	billingCity: '',
	capital: 20000,
	capitalWritten: '',
	partsNumbers: 1,
	partsWritten: '',
	partsValue: 0,
	partsValueWritten: '',
	capitalLibere: 0,
	bankId: null,
	date1stCloture: null,
	dateStatus: null,
	dateDeposit: null,
	dateInvoice: null,
	dateCompleted: null,
	dateCreation: null,
	additonalContent: ''
}


export const _companyFieldsChangeName = {
	oldCompanyName: '',
	newCompanyName: '',
	companyId: '',
	legalStructures: '',
	notaryId: null,
	municipalitySiege: '',
	municipality: '',
	canton: '',
	address: '',
	zipCode: '',
	municipalityAddress: '',
	dateStatus: null,
	goal: '',
	capital: 20000,
	capitalWritten: '',
	partsNumbers: 1,
	partsWritten: '',
	partsValue: 0,
	capitalLibere: 0,
	additonalContent: '',
	billing: true,
	billingCompanyName: '',
	billingAddress: '',
	billingZipCode: '',
	billingCity: ''
}


export const _companyFieldsChangeGoal = {
	companyName: '',
	companyId: '',
	legalStructures: '',
	notaryId: null,
	address: '',
	zipCode: '',
	municipalityAddress: '',
	municipalitySiege: '',
	canton: '',
	goalOld: '',
	goalNew: '',
	billing: true,
	billingCompanyName: '',
	billingAddress: '',
	billingZipCode: '',
	billingCity: '',
	capital: 20000,
	capitalWritten: '',
	partsNumbers: 1,
	partsWritten: '',
	partsValue: 0,
	partsValueWritten: '',
	capitalLibere: 0,
	bankId: null,
	date1stCloture: null,
	dateStatus: null,
	dateDeposit: null,
	dateInvoice: null,
	dateCompleted: null,
	dateCreation: null,
	additonalContent: ''
}


export const _founderFields ={
  gender: '',
  firstName: '',
  lastName: '',
  dateBirth: null,
  nationality: '',
  originCanton: '',
  originCity: '',
  address: '',
  zipCode: '',
  country: '',
  city: '',
  canton:'',
  actionsNumber: 0,
  actionsWritten: '',
  totalNumber: 0,
  totalWritten: '',
  percentage: '',
  signature: '',
  role: '',
  isCompany: false,
  companyName: '',
  companyId: '',
  companyCountry: '',
  companyCanton: '',
  companyCity: '',

  hasShares: false,
  isTeamMember: false,

  isRepresentative: false
}


export const _oldFieldsChangeOwnership ={
	firstName: '',
	lastName: '',
	nationality: '',
	originCanton: '',
	address: '',
	zipCode: '',
	country: '',
	canton:'',
	role: '',
	isFounder: false
}

export const _newFieldsChangeOwnership ={
	firstName: '',
	lastName: '',
	nationality: '',
	originCanton: '',
	address: '',
	zipCode: '',
	country: '',
	canton:'',
	role: '',
	isFounder: false,
	signature: ''
  }


export const _founderFieldsChangeOwnership ={
	title: '',
	firstName: '',
	lastName: '',
	totalNumber: 0,
	totalValue: '',
	isPresident: false,
	isSecretary: false
  }


export const _founderCompanyFields ={
  name: '',
  type: '',
  number: '',
  address: '',
  zipCode: '',
  municipality: '',
  country: '',
}


export const _partsTransfer = {
	company:{
		name:'',
		legalStructures: '',
		citySiege:'',
		dateStatus:'',
		number:'',
		email:''
	},
	seller: {
		firstName:'',
		lastName:'',
		gender:'',
		address:'',
		zipCode:'',
		municipality:'',
		numberPartsSold:'',
		pricePartsSold:'',
		hasManagementRole:false,
		role:'',
		signature:'',
		hasChangeRole:false,
		newRole:'',
		newSignature:''
	},
	buyer: {
		firstName:'',
		lastName:'',
		gender:'',
		address:'',
		zipCode:'',
		municipality:'',
		numberPartsSold:'',
		pricePartsSold:'',
		hasManagementRole:false,
		role:'',
		signature:'',
		hasChangeRole:false,
		newRole:'',
		newSignature:''
	},
	manager: {
		firstName:'',
		lastName:'',
		gender:'',
		signature:'',
	}
}
export const _partsTransferShareholders ={
  firstName: '',
  LastName: '',
  gender:'',
  numberParts: '',
  partsValue: '',
  role: ''
}


export const _accountType = [
  { value:'regular', label:'Regular'},
  { value:'saas', label:'SaaS'}
]


export const _registrationType = [
  { value:'personal', label:'Personal'},
  { value:'company', label:'Company'}
]


export const _actionsChangeOwnership = [
  { value:'add', label:'Add'},
  { value:'erase', label:'Erase'},
  { value:'change', label:'Change'}
]


export const _optRequestStatuses = [
  { value:1, label:'New request'},
  { value:2, label:'To be called'},
  { value:3, label:'Waiting infos'},
  { value:4, label:'Infos updated'},
  { value:5, label:'Waiting for documents'},
  { value:6, label:'Documents for notary'},
  { value:7, label:'Sent to notary'},
  { value:8, label:'ZEFIX OK'},
  { value:9, label:'Invoice sent'},
  { value:10, label:'Payment OK'},
  { value:11, label:'Canceled'},
  { value:20, label:'Archived'}
]


export const _optObjectives = [
  { value:1, label:'creation Sàrl/RI'},
  { value:2, label:'creation SA'},
  // { value:3, label:'adding person for Sàrl/RI'},
  // { value:4, label:'adding persons for SA'},
  // { value:5, label:'changing goal for Sàrl/RI '},
  // { value:6, label:'changing goal for SA'}
]


export const _optLegalStructures = [
  { value:1, label:'Sàrl'},
  { value:2, label:'SA'},
  { value:3, label:'GmbH'},
]


export const _optFounderRoles = [
  // SARL
  { value:1, labelMale:'Associé et président des gérants', labelFemale:'Associée et présidente des gérants'},
  { value:2, labelMale:'Associé gérant', labelFemale:'Associée gérante'},
  { value:3, labelMale:'Associé', labelFemale:'Associée'},
  { value:4, labelMale:'Gérant', labelFemale:'Gérante'},
  { value:5, labelMale:'Aucun', labelFemale:'Aucun'},
  { value:6, labelMale:'Directeur', labelFemale:'Directrice'},
  { value:7, labelMale:'Président(e) des gérants', labelFemale:'Président(e) des gérants'},

  // SA
  { value:21, labelMale:'Administrateur président', labelFemale:'Administrateur président'},
  { value:22, labelMale:'Administrateur vice-président', labelFemale:'Administrateur vice-président'},
  { value:23, labelMale:'Administrateur', labelFemale:'Administratrice'},
  { value:24, labelMale:'Organe de révision', labelFemale:'Organe de révision'},
  { value:25, labelMale:'Directeur', labelFemale:'Directrice'},
  { value:26, labelMale:'Sous-directeur', labelFemale:'Sous-directrice'},
  { value:27, labelMale:'Directeur adjointt', labelFemale:'Directrice adjointt'},
  { value:28, labelMale:'Actionnaire', labelFemale:'Actionnaire'},

  // teams
  { value:41, labelMale:'Président des gérants', labelFemale:'Présidente des gérants'},
  { value:42, labelMale:'Gérant', labelFemale:'Gérant'},
  { value:43, labelMale:'Directeur', labelFemale:'Directrice'}
]


export const _optSignatures = [
  // { value:1, label:'right to sign'},
  { value:2, label:'sans signature'},
  { value:3, label:'avec signature individuelle'},
  { value:4, label:'avec signature collective à deux'},
  { value:5, label:'Radiée'}
]


export const _optFoundersType = [
  { value:1, label:'Person'},
  { value:2, label:'Company'},
  { value:3, label:'GmbH'},
]


export const _optGender = [
  { value:'male', label:'Male'},
  { value:'female', label:'Female'}
]


export const _optCanton = [
  { value:'ZH', label:'Zurich'},
  { value:'BE', label:'Berne'},
  { value:'LU', label:'Lucerne'},
  { value:'UR', label:'Uri'},
  { value:'SZ', label:'Schwytz'},
  { value:'OW', label:'Obwald'},
  { value:'NW', label:'Nidwald'},
  { value:'GL', label:'Glaris'},
  { value:'ZG', label:'Zoug'},
  { value:'FR', label:'Fribourg'},
  { value:'SO', label:'Soleure'},
  { value:'BS', label:'Bâle-Ville'},
  { value:'BL', label:'Bâle-Campagne'},
  { value:'SH', label:'Schaffhouse'},
  { value:'AR', label:'Appenzell Rhodes-Extérieures'},
  { value:'AI', label:'Appenzell Rhodes-Intérieures'},
  { value:'SG', label:'Saint-Gall'},
  { value:'GR', label:'Grisons'},
  { value:'AG', label:'Argovie'},
  { value:'TG', label:'Thurgovie'},
  { value:'TI', label:'Tessin'},
  { value:'VD', label:'Vaud'},
  { value:'VS', label:'Valais'},
  { value:'NE', label:'Neuchâtel'},
  { value:'GE', label:'Genève'},
  { value:'JU', label:'Jura'},
  { value:'fr_FR', label:'France'},
  { value:'de_DE', label:'Allemagne'},
  { value:'at_AT', label:'Autriche'},
  { value:'it_IT', label:'Italie'}
]


export const _optCountry = [
	{
		nationality: "Française",
		name: "France",
		code: "FR"
	},
	{
		nationality: "Suisse",
		name: "Suisse",
		code: "CH"
	},
	{
		nationality: "Belge",
		name: "Belgique",
		code: "BE"
	},
	{
		nationality: "Allemande",
		name: "Allemagne",
		code: "DE"
	},
	{
		nationality: "Italienne",
		name: "Italie",
		code: "IT"
	},
	{
		nationality: "Afghane",
		name: "Afghanistan",
		code: "AF"
	},
	{
		nationality: "Albanaise",
		name: "Albanie",
		code: "AL"
	},
	{
		nationality: "Algerienne",
		name: "Algérie",
		code: "DZ"
	},
	{
		nationality: "Americaine",
		name: "États-Unis",
		code: "US"
	},
	{
		nationality: "Andorrane",
		name: "Andorre",
		code: "AD"
	},
	{
		nationality: "Angolaise",
		name: "Angola",
		code: "AO"
	},
	{
		nationality: "Antiguaise et barbudienne",
		name: "Antigua-et-Barbuda",
		code: "AG"
	},
	{
		nationality: "Argentine",
		name: "Argentine",
		code: "AR"
	},
	{
		nationality: "Armenienne",
		name: "Arménie",
		code: "AM"
	},
	{
		nationality: "Australienne",
		name: "Australie",
		code: "AU"
	},
	{
		nationality: "Autrichienne",
		name: "Autriche",
		code: "AT"
	},
	{
		nationality: "Azerbaïdjanaise",
		name: "Azerbaïdjan",
		code: "AZ"
	},
	{
		nationality: "Bahamienne",
		name: "Bahamas",
		code: "BS"
	},
	{
		nationality: "Bahreinienne",
		name: "Bahreïn",
		code: "BH"
	},
	{
		nationality: "Bangladaise",
		name: "Bangladesh",
		code: "BD"
	},
	{
		nationality: "Barbadienne",
		name: "Barbade",
		code: "BB"
	},
	{
		nationality: "Belizienne",
		name: "Belize",
		code: "BZ"
	},
	{
		nationality: "Beninoise",
		name: "Bénin",
		code: "BJ"
	},
	{
		nationality: "Bhoutanaise",
		name: "Bhoutan",
		code: "BT"
	},
	{
		nationality: "Bielorusse",
		name: "Bélarus",
		code: "BY"
	},
	{
		nationality: "Birmane",
		name: "Myanmar",
		code: "MM"
	},
	{
		nationality: "Bissau-Guinéenne",
		name: "Guinée-Bissau",
		code: "GW"
	},
	{
		nationality: "Bolivienne",
		name: "Bolivie",
		code: "BO"
	},
	{
		nationality: "Bosnienne",
		name: "Bosnie-Herzégovine",
		code: "BA"
	},
	{
		nationality: "Botswanaise",
		name: "Botswana",
		code: "BW"
	},
	{
		nationality: "Bresilienne",
		name: "Brésil",
		code: "BR"
	},
	{
		nationality: "Britannique",
		name: "Royaume-Uni",
		code: "GB"
	},
	{
		nationality: "Bruneienne",
		name: "Brunéi Darussalam",
		code: "BN"
	},
	{
		nationality: "Bulgare",
		name: "Bulgarie",
		code: "BG"
	},
	{
		nationality: "Burkinabe",
		name: "Burkina Faso",
		code: "BF"
	},
	{
		nationality: "Burundaise",
		name: "Burundi",
		code: "BI"
	},
	{
		nationality: "Cambodgienne",
		name: "Cambodge",
		code: "KH"
	},
	{
		nationality: "Camerounaise",
		name: "Cameroun",
		code: "CM"
	},
	{
		nationality: "Canadienne",
		name: "Canada",
		code: "CA"
	},
	{
		nationality: "Cap-verdienne",
		name: "Cap-vert",
		code: "CV"
	},
	{
		nationality: "Centrafricaine",
		name: "République Centrafricaine",
		code: "CF"
	},
	{
		nationality: "Chilienne",
		name: "Chili",
		code: "CL"
	},
	{
		nationality: "Chinoise",
		name: "Chine",
		code: "CN"
	},
	{
		nationality: "Chypriote",
		name: "Chypre",
		code: "CY"
	},
	{
		nationality: "Colombienne",
		name: "Colombie",
		code: "CO"
	},
	{
		nationality: "Comorienne",
		name: "Comores",
		code: "KM"
	},
	{
		nationality: "Congolaise",
		name: "République du Congo",
		code: "CG"
	},
	{
		nationality: "Costaricaine",
		name: "Costa Rica",
		code: "CR"
	},
	{
		nationality: "Croate",
		name: "Croatie",
		code: "HR"
	},
	{
		nationality: "Cubaine",
		name: "Cuba",
		code: "CU"
	},
	{
		nationality: "Danoise",
		name: "Danemark",
		code: "DK"
	},
	{
		nationality: "Djiboutienne",
		name: "Djibouti",
		code: "DJ"
	},
	{
		nationality: "Dominicaine",
		name: "République Dominicaine",
		code: "DO"
	},
	{
		nationality: "Dominiquaise",
		name: "Dominique",
		code: "DM"
	},
	{
		nationality: "Egyptienne",
		name: "Égypte",
		code: "EG"
	},
	{
		nationality: "Emirienne",
		name: "Émirats Arabes Unis",
		code: "AE"
	},
	{
		nationality: "Equato-guineenne",
		name: "Guinée Équatoriale",
		code: "GQ"
	},
	{
		nationality: "Equatorienne",
		name: "Équateur",
		code: "EC"
	},
	{
		nationality: "Erythreenne",
		name: "Érythrée",
		code: "ER"
	},
	{
		nationality: "Espagnole",
		name: "Espagne",
		code: "ES"
	},
	{
		nationality: "Est-timoraise",
		name: "Timor-Leste",
		code: "TL"
	},
	{
		nationality: "Estonienne",
		name: "Estonie",
		code: "EE"
	},
	{
		nationality: "Ethiopienne",
		name: "Éthiopie",
		code: "ET"
	},
	{
		nationality: "Fidjienne",
		name: "Fidji",
		code: "FJ"
	},
	{
		nationality: "Finlandaise",
		name: "Finlande",
		code: "FI"
	},
	{
		nationality: "Gabonaise",
		name: "Gabon",
		code: "GA"
	},
	{
		nationality: "Gambienne",
		name: "Gambie",
		code: "GM"
	},
	{
		nationality: "Georgienne",
		name: "Géorgie",
		code: "GE"
	},
	{
		nationality: "Ghaneenne",
		name: "Ghana",
		code: "GH"
	},
	{
		nationality: "Grenadienne",
		name: "Grenade",
		code: "GD"
	},
	{
		nationality: "Guatemalteque",
		name: "Guatemala",
		code: "GT"
	},
	{
		nationality: "Guineenne",
		name: "Guinée",
		code: "GN"
	},
	{
		nationality: "Guyanienne",
		name: "Guyane Française",
		code: "GF"
	},
	{
		nationality: "Haïtienne",
		name: "Haïti",
		code: "HT"
	},
	{
		nationality: "Hellenique",
		name: "Grèce",
		code: "GR"
	},
	{
		nationality: "Hondurienne",
		name: "Honduras",
		code: "HN"
	},
	{
		nationality: "Hongroise",
		name: "Hongrie",
		code: "HU"
	},
	{
		nationality: "Indienne",
		name: "Inde",
		code: "IN"
	},
	{
		nationality: "Indonesienne",
		name: "Indonésie",
		code: "ID"
	},
	{
		nationality: "Irakienne",
		name: "Iraq",
		code: "IQ"
	},
	{
		nationality: "Irlandaise",
		name: "Irlande",
		code: "IE"
	},
	{
		nationality: "Islandaise",
		name: "Islande",
		code: "IS"
	},
	{
		nationality: "Israélienne",
		name: "Israël",
		code: "IL"
	},
	{
		nationality: "Ivoirienne",
		name: "Côte d'Ivoire",
		code: "CI"
	},
	{
		nationality: "Jamaïcaine",
		name: "Jamaïque",
		code: "JM"
	},
	{
		nationality: "Japonaise",
		name: "Japon",
		code: "JP"
	},
	{
		nationality: "Jordanienne",
		name: "Jordanie",
		code: "JO"
	},
	{
		nationality: "Kazakhstanaise",
		name: "Kazakhstan",
		code: "KZ"
	},
	{
		nationality: "Kenyane",
		name: "Kenya",
		code: "KE"
	},
	{
		nationality: "Kirghize",
		name: "Kirghizistan",
		code: "KG"
	},
	{
		nationality: "Kiribatienne",
		name: "Kiribati",
		code: "KI"
	},
	{
		nationality: "Kittitienne-et-nevicienne",
		name: "Saint-Kitts-et-Nevis",
		code: "KN"
	},
	{
		nationality: "Kosovare",
		name: "KOSOVO, REPUBLIQUE DU",
		code: "XK"
	},
	{
		nationality: "Koweitienne",
		name: "Koweït",
		code: "KW"
	},
	{
		nationality: "Laotienne",
		name: "République Démocratique Populaire Lao",
		code: "LA"
	},
	{
		nationality: "Lesothane",
		name: "Lesotho",
		code: "LS"
	},
	{
		nationality: "Lettone",
		name: "Lettonie",
		code: "LV"
	},
	{
		nationality: "Libanaise",
		name: "Liban",
		code: "LB"
	},
	{
		nationality: "Liberienne",
		name: "Libéria",
		code: "LR"
	},
	{
		nationality: "Libyenne",
		name: "Jamahiriya Arabe Libyenne",
		code: "LY"
	},
	{
		nationality: "Liechtensteinoise",
		name: "Liechtenstein",
		code: "LI"
	},
	{
		nationality: "Lituanienne",
		name: "Lituanie",
		code: "LT"
	},
	{
		nationality: "Luxembourgeoise",
		name: "Luxembourg",
		code: "LU"
	},
	{
		nationality: "Macedonienne",
		name: "L'ex-République Yougoslave de Macédoine",
		code: "MK"
	},
	{
		nationality: "Malaisienne",
		name: "Malaisie",
		code: "MY"
	},
	{
		nationality: "Malawienne",
		name: "Malawi",
		code: "MW"
	},
	{
		nationality: "Maldivienne",
		name: "Maldives",
		code: "MV"
	},
	{
		nationality: "Malgache",
		name: "Madagascar",
		code: "MG"
	},
	{
		nationality: "Malienne",
		name: "Mali",
		code: "ML"
	},
	{
		nationality: "Maltaise",
		name: "Malte",
		code: "MT"
	},
	{
		nationality: "Marocaine",
		name: "Maroc",
		code: "MA"
	},
	{
		nationality: "Marshallaise",
		name: "Îles Marshall",
		code: "MH"
	},
	{
		nationality: "Mauricienne",
		name: "Maurice",
		code: "MU"
	},
	{
		nationality: "Mauritanienne",
		name: "Mauritanie",
		code: "MR"
	},
	{
		nationality: "Mexicaine",
		name: "Mexique",
		code: "MX"
	},
	{
		nationality: "Micronesienne",
		name: "États Fédérés de Micronésie",
		code: "FM"
	},
	{
		nationality: "Moldave",
		name: "République de Moldova",
		code: "MD"
	},
	{
		nationality: "Monegasque",
		name: "Monaco",
		code: "MC"
	},
	{
		nationality: "Mongole",
		name: "Mongolie",
		code: "MN"
	},
	{
		nationality: "Montenegrine",
		name: "Monténégro",
		code: "ME"
	},
	{
		nationality: "Mozambicaine",
		name: "Mozambique",
		code: "MZ"
	},
	{
		nationality: "Namibienne",
		name: "Namibie",
		code: "NA"
	},
	{
		nationality: "Nauruane",
		name: "Nauru",
		code: "NR"
	},
	{
		nationality: "Neerlandaise",
		name: "Pays-Bas",
		code: "NL"
	},
	{
		nationality: "Neo-zelandaise",
		name: "Nouvelle-Zélande",
		code: "NZ"
	},
	{
		nationality: "Nepalaise",
		name: "Népal",
		code: "NP"
	},
	{
		nationality: "Nicaraguayenne",
		name: "Nicaragua",
		code: "NI"
	},
	{
		nationality: "Nigeriane",
		name: "Nigéria",
		code: "NG"
	},
	{
		nationality: "Nigerienne",
		name: "Niger",
		code: "NE"
	},
	{
		nationality: "Nord-coréenne",
		name: "République Populaire Démocratique de Corée",
		code: "KP"
	},
	{
		nationality: "Norvegienne",
		name: "Norvège",
		code: "NO"
	},
	{
		nationality: "Omanaise",
		name: "Oman",
		code: "OM"
	},
	{
		nationality: "Ougandaise",
		name: "Ouganda",
		code: "UG"
	},
	{
		nationality: "Ouzbeke",
		name: "Ouzbékistan",
		code: "UZ"
	},
	{
		nationality: "Pakistanaise",
		name: "Pakistan",
		code: "PK"
	},
	{
		nationality: "Palau",
		name: "Palaos",
		code: "PW"
	},
	{
		nationality: "Palestinienne",
		name: "Territoire Palestinien Occupé",
		code: "PS"
	},
	{
		nationality: "Panameenne",
		name: "Panama",
		code: "PA"
	},
	{
		nationality: "Papouane-neoguineenne",
		name: "Papouasie-Nouvelle-Guinée",
		code: "PG"
	},
	{
		nationality: "Paraguayenne",
		name: "Paraguay",
		code: "PY"
	},
	{
		nationality: "Peruvienne",
		name: "Pérou",
		code: "PE"
	},
	{
		nationality: "Philippine",
		name: "Philippines",
		code: "PH"
	},
	{
		nationality: "Polonaise",
		name: "Pologne",
		code: "PL"
	},
	{
		nationality: "Portoricaine",
		name: "Porto Rico",
		code: "PR"
	},
	{
		nationality: "Portugaise",
		name: "Portugal",
		code: "PT"
	},
	{
		nationality: "Qatarienne",
		name: "Qatar",
		code: "QA"
	},
	{
		nationality: "Roumaine",
		name: "Roumanie",
		code: "RO"
	},
	{
		nationality: "Russe",
		name: "Fédération de Russie",
		code: "RU"
	},
	{
		nationality: "Rwandaise",
		name: "Rwanda",
		code: "RW"
	},
	{
		nationality: "Saint-Lucienne",
		name: "Sainte-Lucie",
		code: "LC"
	},
	{
		nationality: "Saint-Marinaise",
		name: "Saint-Marin",
		code: "SM"
	},
	{
		nationality: "Saint-Vincentaise-et-Grenadine",
		name: "Saint-Vincent-et-les Grenadines",
		code: "VC"
	},
	{
		nationality: "Salomonaise",
		name: "Îles Salomon",
		code: "SB"
	},
	{
		nationality: "Salvadorienne",
		name: "El Salvador",
		code: "SV"
	},
	{
		nationality: "Samoane",
		name: "Samoa",
		code: "WS"
	},
	{
		nationality: "Santomeenne",
		name: "Sao Tomé-et-Principe",
		code: "ST"
	},
	{
		nationality: "Saoudienne",
		name: "Arabie Saoudite",
		code: "SA"
	},
	{
		nationality: "Senegalaise",
		name: "Sénégal",
		code: "SN"
	},
	{
		nationality: "Serbe",
		name: "Serbie",
		code: "RS"
	},
	{
		nationality: "Seychelloise",
		name: "Seychelles",
		code: "SC"
	},
	{
		nationality: "Sierra-leonaise",
		name: "Sierra Leone",
		code: "SL"
	},
	{
		nationality: "Singapourienne",
		name: "Singapour",
		code: "SG"
	},
	{
		nationality: "Slovaque",
		name: "Slovaquie",
		code: "SK"
	},
	{
		nationality: "Slovene",
		name: "Slovénie",
		code: "SI"
	},
	{
		nationality: "Somalienne",
		name: "Somalie",
		code: "SO"
	},
	{
		nationality: "Soudanaise",
		name: "Soudan",
		code: "SD"
	},
	{
		nationality: "Sri-lankaise",
		name: "Sri Lanka",
		code: "LK"
	},
	{
		nationality: "Sud-africaine",
		name: "Afrique du Sud",
		code: "ZA"
	},
	{
		nationality: "Sud-coréenne",
		name: "République de Corée",
		code: "KR"
	},
	{
		nationality: "Suedoise",
		name: "Suède",
		code: "SE"
	},
	{
		nationality: "Surinamaise",
		name: "Suriname",
		code: "SR"
	},
	{
		nationality: "Swazie",
		name: "Swaziland",
		code: "SZ"
	},
	{
		nationality: "Syrienne",
		name: "République Arabe Syrienne",
		code: "SY"
	},
	{
		nationality: "Tadjike",
		name: "Tadjikistan",
		code: "TJ"
	},
	{
		nationality: "Taiwanaise",
		name: "Taïwan",
		code: "TW"
	},
	{
		nationality: "Tanzanienne",
		name: "République-Unie de Tanzanie",
		code: "TZ"
	},
	{
		nationality: "Tchadienne",
		name: "Tchad",
		code: "TD"
	},
	{
		nationality: "Tcheque",
		name: "République Tchèque",
		code: "CZ"
	},
	{
		nationality: "Thaïlandaise",
		name: "Thaïlande",
		code: "TH"
	},
	{
		nationality: "Tonguienne",
		name: "Togo",
		code: "TG"
	},
	{
		nationality: "Trinidadienne",
		name: "Trinité-et-Tobago",
		code: "TT"
	},
	{
		nationality: "Tunisienne",
		name: "Tunisie",
		code: "TN"
	},
	{
		nationality: "Turkmene",
		name: "Turkménistan",
		code: "TM"
	},
	{
		nationality: "Turque",
		name: "Turquie",
		code: "TR"
	},
	{
		nationality: "Tuvaluane",
		name: "Tuvalu",
		code: "TV"
	},
	{
		nationality: "Ukrainienne",
		name: "Ukraine",
		code: "UA"
	},
	{
		nationality: "Uruguayenne",
		name: "Uruguay",
		code: "UY"
	},
	{
		nationality: "Vanuatuane",
		name: "Vanuatu",
		code: "VU"
	},
	{
		nationality: "Venezuelienne",
		name: "Venezuela",
		code: "VE"
	},
	{
		nationality: "Vietnamienne",
		name: "Viet Nam",
		code: "VN"
	},
	{
		nationality: "Yemenite",
		name: "Yémen",
		code: "YE"
	},
	{
		nationality: "Zambienne",
		name: "Zambie",
		code: "ZM"
	},
	{
		nationality: "Zimbabweenne",
		name: "Zimbabwe",
		code: "ZW"
	}
]
