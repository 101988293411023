import React, { Fragment } from 'react';
import { Save } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'

// components
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _itemByValue } from '../../controllers/Helpers';
import { _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep2(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const fields = {
    bankId: null,
    date1stCloture: null,
    dateStatus: null,
    dateDeposit: null,
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)
  const [ banks, setBanks ] = React.useState([])

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [ sendEmail, setSendEmail ] = React.useState(false);


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      const res = await _apiGETmulti('/banks/query', {limit:1000})
      if(!res){
        setBanks([])
      }else{
        let newBanksArray = [];
        res.data && res.data.map((item,index)=>{
          newBanksArray.push({value:item._id, label:item.name})
          return null
        })
        setBanks(newBanksArray)

        if(props.data.company){
          setFormData({
            bankId: props.data.company.bankId,
            date1stCloture: props.data.company.date1stCloture,
            dateStatus: props.data.company.dateStatus,
            dateDeposit: props.data.company.dateDeposit,
          })
          setValue('bankId', props.data.company.bankId)
          setValue('date1stCloture', props.data.company.date1stCloture)
          setValue('dateStatus', props.data.company.dateStatus)
          setValue('dateDeposit', props.data.company.dateDeposit)
        }
      }
      setLoading(false)
    }
    if(auth.status){
      getData()
    }

    return (() => [getData])
  },[props.data.company])



  //
  //
  const _submit = async () => {
    // props.action('step3',formData)
    props.action('step2',{company:formData, sendEmail:sendEmail})
  }


  //
  //
  return (
    <Fragment>
      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <div className="relative w-100">

        <div className="row">
          {/* <div className="col-md-6 col-lg-4">

            <div className="form-group">
              <label>Date of 1st cloture {props.isShortcodeVisible && <small>{'{COMPANY_DATE_1ST_CLOTURE}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <DatePicker
                    selected={formData.date1stCloture ? new Date(formData.date1stCloture) : formData.date1stCloture}
                    onChange={(date)=> {field.onChange(date); _updateField('date1stCloture',date)}}
                    name={'date1stCloture'}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    dateFormat="dd.MM.yyyy"
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'date1stCloture'}
              />
              {errors.date1stCloture && <div className="errorField">This field is required</div>}
            </div>

          </div> */}
          <div className="col-md-6 col-lg-4">

            <div className="form-group">
              <label>Date of status {props.isShortcodeVisible && <small>{'{COMPANY_DATE_STATUS}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <DatePicker
                    selected={formData.dateStatus ? new Date(formData.dateStatus) : formData.dateStatus}
                    onChange={(date)=> {field.onChange(date); _updateField('dateStatus',date)}}
                    name={'dateStatus'}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    dateFormat="dd.MM.yyyy"
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'dateStatus'}
              />
              {errors.dateStatus && <div className="errorField">This field is required</div>}
            </div>

          </div>
          {/* <div className="col-12 pt-4">
            <h3>Bank information</h3>
          </div>
          <div className="col-md-6 col-lg-4">

            <div className="form-group">
              <label>Date of bank deposit {props.isShortcodeVisible && <small>{'{COMPANY_DATE_DEPOSIT}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <DatePicker
                    selected={formData.dateDeposit ? new Date(formData.dateDeposit) : formData.dateDeposit}
                    onChange={(date)=> {field.onChange(date); _updateField('dateDeposit',date)}}
                    name={'dateDeposit'}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    dateFormat="dd.MM.yyyy"
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'dateDeposit'}
              />
              {errors.dateDeposit && <div className="errorField">This field is required</div>}
            </div>

          </div> */}
          {/* <div className="col-md-6 col-lg-8">

            <div className="form-group">
              <label>Bank {props.isShortcodeVisible && <small>{'{COMPANY_BANK}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <Select
                    name={'bankId'}
                    value={_itemByValue(formData.bankId, banks)}
                    options={banks}
                    onChange={(e)=> {field.onChange(e.value); _updateField('bankId',e.value)}}
                    className={`form-control p-0 ${errors.bankId && 'error'}`}
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'bankId'}
              />
              {errors.bankId && <div className="errorField">This field is required</div>}
            </div>

          </div> */}
        </div>

        <hr/>

        <div className="d-flex justify-content-between mt-5">
          <div>
            <div className="switch mb-4 mb-md-0">
              <input
                type="checkbox"
                name={'sendEmailStep1'}
                value="1"
                checked={sendEmail}
                onChange={(e)=>setSendEmail(!sendEmail)}
                className={`form-control`}
              />
                <div><i></i></div>
              <label>Send email to client | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
            </div>
          </div>
          <button type="submit" className="button accent lg ml-1" disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>Save step #3</button>
        </div>

        <Loading status={loading}/>
      </div>
      </form>
    </Fragment>
  );

}
