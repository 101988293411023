import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { FileText, Download, ChevronLeft, ChevronRight } from 'react-feather'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'
import ReactPaginate from 'react-paginate'

// variables
import { SITENAME, _optObjectives } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import TextSearchField from '../../components/TextSearchField';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETmulti, _apiGetFile } from '../../controllers/Api';
import { _labelByValue } from '../../controllers/Helpers';


export default function Invoices(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const fields = {
    limit: 50,
    offset: 0,
    orderBy: 'dateCreated|desc',
    search: '',
    clientId: auth.response.clientId
  }

  const [ reload, setReload ] = React.useState(1);

  const [ loading, setLoading ] = React.useState(true);
  const [ request, setRequest ] = React.useState(fields)
  const [ requestAction, setRequestAction ] = React.useState(fields)
  const [ total, setTotal ] = React.useState(0)
  const [ data, setData ] = React.useState([])


  //
  //
  const _paginateAction = (e) => {
    const offset = e.selected === 0 ? 0 : (e.selected*fields.limit)
    _updateField('offset',offset)
  }



  //
  //
  const _updateField = (field, data) => {
    setRequest({...request, [field]:data});
  }


  //
  //
  const _getPdf = async (filename, doc, id) => {

    const response = await _apiGetFile('/documents/pdf/', {
      documentKey:doc,
      requestId:id,
      userId:auth.response._id,
      clientId:auth.response.clientId
    })
    if(response){
      fileDownload(response, filename);
    }

  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => request !== '' ? setRequestAction(request) : null, 500 );
    return () => clearTimeout(timeoutId);
  }, [request]);


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      const res = await _apiGETmulti('/invoices/query', requestAction)
      if(!res){
        setTotal(0)
        setData([])
      }else{
        setTotal(res.total)
        setData(res.data)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[requestAction, reload])


  //
  //
  const _options = (data, index) => {

    return (
      <div className="d-flex justify-content-end align-items-center">
        <button type="button" className="iconButton" onClick={()=>_getPdf('invoice_'+(data.company.companyName).toLowerCase()+'.pdf', 'doc_invoice', data._id)}><Download size={20}/></button>
      </div>
    )

  }


  //
  //
  const _title = (data, index) => {
    return (
      <div className="relative">
        <strong>{data.start.companyName}</strong><br/>
        <small><strong>{_labelByValue(data.start.objective, _optObjectives)}</strong> | {data.start.firstName} {data.start.lastName}</small>
      </div>
    )
  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Invoices | { SITENAME }</title>
      </Helmet>

      <PageHead
        title="Invoices"
        icon={<FileText size={52}/>}
      >
        <TextSearchField
          type="text"
          name="search"
          value={request.search}
          onChange={(e)=>_updateField('search',e)}
          placeholder="search..."
        />
      </PageHead>

      <div className="relative w-100">
        {total===0 ?
          <div className="text-center py-4">{loading ? 'loading...' : 'No data vailable.'}</div>
        :
          <>
          <Table
            action={_updateField}
            perPage={request.limit}
            sortBy={request.orderBy}
            head={
              [
                {name:'Request', width:'auto', sortable:true, type:'function', func:_title},
                {name:'Created on', width:'15%', sortable:true, key:'dateCreated', type:'date'},
                {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
              ]
            }
            body={data}
            total={total}
          />
          <ReactPaginate
            previousLabel={<ChevronLeft/>}
            nextLabel={<ChevronRight/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(total/fields.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={_paginateAction}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          </>
        }
        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
