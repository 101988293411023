import React, { Fragment } from 'react';
import { Save, PlusCircle, XCircle } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'

// variables
import {
  _companyFieldsChangeName,
  _founderFields,
  _optLegalStructures,
  _optCanton,
  _optCountry,
  _optGender,
  _optFounderRoles,
  _optTeamsRoles,
  _optSignatures,
  _founderCompanyFields
} from '../../configuration.js';

// components
import Loading from '../../components/Loading';
import ContentEditorBasic from '../../components/ContentEditor/basic'

// controllers
import _actions from '../../controllers/redux/actions'
import { _labelByValue, _itemByValue } from '../../controllers/Helpers';
import { _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep1(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const founderFields = _founderFields

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(_companyFieldsChangeName)
  // const [ companyData, setCompanyData ] = React.useState(_founderCompanyFields)
  const [ formDataPeoples, setformDataPeoples ] = React.useState([])
  const [ sendEmail, setSendEmail ] = React.useState(false);
  const [ _optLegalStructuresFilterd, setOptLegalStructuresFilterd] = React.useState([])
  const [ notaries, setNotaries ] = React.useState([])

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();

  const [ goalErrors, setGoalError ] = React.useState(false)
  
  // console.log('_optLegalStructuresFilterd',_optLegalStructuresFilterd)


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }

  //
  //
  const _handleEditorChange = (content, editor) => {
    setFormData({...formData, additonalContent:content})
  }


  //
  //
  const _handleEditorChange2 = (content, editor) => {
    setGoalError(null)
    setFormData({...formData, goal:content})
  }


  //
  //
  // const _updateCompanyField = (field, data) => {
  //   setCompanyData({...companyData, [field]:data});
  // }


  //
  //
  const _updateFieldPeoples = (field, data, index) => {
    let newArray = formDataPeoples
    newArray[index] = {
      ...newArray[index],
      [field]: data
    }
    setformDataPeoples([...newArray]);
  }


  //
  //
  const _addRepeaterPeopleItem = () => {
    let newArray = founderFields
    if(props.data.registrationType==='company'){
      newArray.isTeamMember = true
    }
    setformDataPeoples(founders => [...formDataPeoples, newArray])
  }

  //
  //
  const _removeRepeaterPeopleItem = (index) => {
    let newArray = formDataPeoples.filter((x, i)=>{
      return i !== index && x
    });
    setformDataPeoples(founders => newArray)
  }


  //
  //
  const _submit = async () => {
    props.action('step1',{company:formData, peoples:formDataPeoples, sendEmail:sendEmail})
  }


  //
  //
  const _rearangeFounderRoles = () => {

    let array = []
    const _sarl = [1,2,3,4,5,6,7]
    const _sa   = [21,22,23,24,25,26,27,28]
    const _team = [41,42,43]
    _optFounderRoles.map((item,index)=>{
      if(formData.legalStructures===1 && _sarl.includes(item.value)){
        array.push(item)
      }
      if(formData.legalStructures===2 && _sa.includes(item.value)){
        array.push(item)
      }
    })

    console.log('array',array,_optFounderRoles)
    return array;
  }


  //
  //
  React.useEffect(()=>{
    if(formData.capital !== 0 && formData.partsNumbers !== 0){
      const partsValue = (formData.capital/formData.partsNumbers)
      _updateField('partsValue', partsValue.toFixed(2));
      formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
        _updateFieldPeoples('totalNumber',partsValue !== 0 && item.actionsNumber !== 0 ? (parseFloat(partsValue)*parseFloat(item.actionsNumber)).toFixed(2) : 0,index)
      })
    }
  },[formData.capital, formData.partsNumbers])


  //
  //
  React.useEffect(()=>{


    const getData = async () =>{

      const res = await _apiGETmulti('/notary/query', {limit:1000, clientId: auth.response.clientId})
      if(!res){
        setNotaries([])
      }else{
        let newNotaryArray = [];
        res.data && res.data.map((item,index)=>{
          newNotaryArray.push({value:item._id, label:item.info.firstName+' '+item.info.lastName+' ('+item.info.agentFirstName+' '+item.info.agentLastName+')'})
          return null
        })
        setNotaries(newNotaryArray)

        let leaglStructuresList = []
        const _optLegalStructuresFilterd = _optLegalStructures.map((item,index)=>{
          if(index<2){
            leaglStructuresList.push(item)
          }
        })
        
        setOptLegalStructuresFilterd(leaglStructuresList)

        // if(props.data.founderCompany && !props.isNew){
        //   setCompanyData(props.data.founderCompany)
        //   setValue('c_name', props.data.founderCompany.name)
        //   setValue('c_type', props.data.founderCompany.type)
        //   setValue('c_number', props.data.founderCompany.number)
        //   setValue('c_address', props.data.founderCompany.address)
        //   setValue('c_zipCode', props.data.founderCompany.zipCode)
        //   setValue('c_municipality', props.data.founderCompany.municipality)
        //   setValue('c_country', props.data.founderCompany.country)
        // }

        if(props.data.company && !props.isNew){
          setFormData(props.data.company)
            // console.log(props.data)
          setValue('notaryId', props.data.company.notaryId)
          setValue('companyId', props.data.company.companyId)
          setValue('oldCompanyName', props.data.company.oldCompanyName)
          setValue('newCompanyName', props.data.company.newCompanyName)
          setValue('legalStructures', props.data.company.legalStructures)
          setValue('address', props.data.company.address)
          setValue('zipCode', props.data.company.zipCode)
          setValue('municipalityAddress', props.data.company.municipalityAddress)
          setValue('municipalitySiege', props.data.company.municipalitySiege)
          setValue('municipality', props.data.company.municipality)
          setValue('canton', props.data.company.canton)
          
          
          setValue('billingCompanyName', props.data.company.billingCompanyName)
          setValue('billingAddress', props.data.company.billingAddress)
          setValue('billingZipCode', props.data.company.billingZipCode)
          setValue('billingCity', props.data.company.billingCity)
        }else{
          // console.log('objective',props.data.start?.objective, props.data.start?.objective===1 ? 20000 : 100000)
          // setValue('capital', props.data.start?.objective===1 ? 20000 : 100000)
          _updateField('capital', props.data.start?.objective===1 ? 20000 : 100000)
        }

        if(props.data.peoples && !props.isNew){
          if(props.data.peoples.length>0){
            setformDataPeoples(props.data.peoples)
            props.data.peoples.map((item,index)=>{
              setValue('genderF'+index, item.gender)
              setValue('firstNameF'+index, item.firstName)
              setValue('lastNameF'+index, item.lastName)
              setValue('dateBirthF'+index, item.dateBirth)
              setValue('nationalityF'+index, item.nationality)
              if(item.originCanton !== ""){
                setValue('originCantonF'+index, item.originCanton)
              }
              setValue('originCityF'+index, item.originCity)
              setValue('addressF'+index, item.address)
              setValue('zipCodeF'+index, item.zipCode)
              setValue('countryF'+index, item.country)
              setValue('cityF'+index, item.city)
              if(item.canton !== ""){
                setValue('cantonF'+index, item.canton)
              }
              // setValue('actionsNumberF'+index, item.actionsNumber)
              // setValue('actionsWrittenF'+index, item.actionsWritten)
              // setValue('totalNumberF'+index, item.totalNumber)
              // setValue('totalWrittenF'+index, item.totalWritten)
              // setValue('percentageF'+index, item.percentage)
              setValue('signatureF'+index, item.signature)
              setValue('roleF'+index, item.role)
            })
          }
        }
        setLoading(false)

      }
    }

    getData()
  },[props.data, props.isNew])


  //
  //
  const _repeaterFoundersItem = (item, index) => {

    const ___gender = 'genderF'+index
    const ___firstName = 'firstNameF'+index
    const ___lastName = 'lastNameF'+index
    const ___dateBirth = 'dateBirthF'+index
    const ___nationality = 'nationalityF'+index
    const ___originCanton = 'originCantonF'+index
    const ___originCity = 'originCityF'+index
    const ___address = 'addressF'+index
    const ___zipCode = 'zipCodeF'+index
    const ___country = 'countryF'+index
    const ___city = 'cityF'+index
    const ___canton = 'cantonF'+index
    const ___actionsNumber = 'actionsNumberF'+index
    const ___actionsWritten = 'actionsWrittenF'+index
    const ___totalNumber = 'totalNumberF'+index
    const ___totalWritten = 'totalWrittenF'+index
    const ___percentage = 'percentageF'+index
    const ___signature = 'signatureF'+index
    const ___role = 'roleF'+index
    const ___isCompany = 'isCompanyF'+index
    const ___companyName = 'companyNameF'+index
    const ___companyId = 'companyIdF'+index
    const ___companyCountry = 'companyCountryF'+index
    const ___companyCity = 'companyCityF'+index
    const ___companyCanton = 'companyCantonF'+index

    const ___hasShares = 'hasSharesF'+index
    const ___isTeamMember = 'isTeamMemberF'+index

    const ___isRepresentative = 'isRepresentativeF'+index

    return (
      <div className="repeaterItem" key={index}>
      <div className="row">
      

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>First name {props.isShortcodeVisible && <small>{'(firstName)'}</small>}</label>
            <input
              {...register(___firstName, { required: true })}
              type="text"
              name={___firstName}
              value={item.firstName}
              onChange={(e)=>_updateFieldPeoples('firstName',e.target.value, index)}
              className={`form-control ${errors.___firstName && 'error'}`}
            />
            {errors[___firstName] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Last name {props.isShortcodeVisible && <small>{'(lastName)'}</small>}</label>
            <input
              {...register(___lastName, { required: true })}
              type="text"
              name={___lastName}
              value={item.lastName}
              onChange={(e)=>_updateFieldPeoples('lastName',e.target.value, index)}
              className={`form-control ${errors.___lastName && 'error'}`}
            />
            {errors[___lastName] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Gender {props.isShortcodeVisible && <small>{'(gender)'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={___gender}
                  value={_itemByValue(item.gender, _optGender)}
                  options={_optGender}
                  onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('gender',e.value,index)}}
                  className={`form-control p-0 ${errors.___gender && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name={___gender}
            />
            {errors[___gender] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          {/* <div className="form-group">
            <label>Date of birth {props.isShortcodeVisible && <small>{'(dateBirth)'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <DatePicker
                  selected={item.dateBirth ? new Date(item.dateBirth) : item.dateBirth}
                  onChange={(date)=> {field.onChange(date); _updateFieldPeoples('dateBirth',date, index)}}
                  name={___dateBirth}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control"
                  dateFormat="dd.MM.yyyy"
                />
              }
              control={control}
              rules={{ required: true}}
              name={___dateBirth}
            />
            {errors[___dateBirth] && <div className="errorField">This field is required</div>}
          </div> */}
              <div className="form-group">
                <label>Date of birth {props.isShortcodeVisible && <small>{'(dateBirth)'}</small>}</label>
                <input
                  {...register(___dateBirth, { required: true })}
                  type="text"
                  name={___dateBirth}
                  value={item.dateBirth}
                  onChange={(e)=>_updateFieldPeoples('dateBirth',e.target.value,index)}
                  className={`form-control ${errors.dateBirth && 'error'}`}
                />
                {errors[___dateBirth] && <div className="errorField">This field is required</div>}
              </div>
        </div>

        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-md-9">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'(address)'}</small>}</label>
            <input
              {...register(___address, { required: false })}
              type="text"
              name={___address}
              value={item.address}
              onChange={(e)=>_updateFieldPeoples('address',e.target.value,index)}
              className={`form-control ${errors.___address && 'error'}`}
            />
            {errors[___address] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'(zipCode)'}</small>}</label>
            <input
              {...register(___zipCode, { required: false })}
              type="text"
              name={___zipCode}
              value={item.zipCode}
              onChange={(e)=>_updateFieldPeoples('zipCode',e.target.value,index)}
              className={`form-control ${errors.___zipCode && 'error'}`}
            />
            {errors[___zipCode] && <div className="errorField">Required</div>}
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Country of residence {props.isShortcodeVisible && <small>{'(country)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___country}
                      value={_itemByValue(item.country, _optCountry, 'code')}
                      options={_optCountry}
                      onChange={(e)=> {field.onChange(e.code); _updateFieldPeoples('country',e.code,index)}}
                      className={`form-control p-0 ${errors.___country && 'error'}`}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.code}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___country}
                />
                {errors[___country] && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>City of residence {props.isShortcodeVisible && <small>{'(city)'}</small>}</label>
                <input
                  {...register(___city, { required: true })}
                  type="text"
                  name={___city}
                  value={item.city}
                  onChange={(e)=>_updateFieldPeoples('city',e.target.value,index)}
                  className={`form-control ${errors.___city && 'error'}`}
                />
                {errors[___city] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              {item.country === 'CH' &&
              <div className="form-group">
                <label>Canton {props.isShortcodeVisible && <small>{'(canton)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___canton}
                      value={_itemByValue(item.canton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('canton',e.value,index)}}
                      className={`form-control p-0 ${errors.___canton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___canton}
                />
                {errors[___canton] && <div className="errorField">This field is required</div>}
              </div>
              }
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Nationality {props.isShortcodeVisible && <small>{'(nationality)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___nationality}
                      value={_itemByValue(item.nationality, _optCountry, 'code')}
                      options={_optCountry}
                      onChange={(e)=> {field.onChange(e.code); _updateFieldPeoples('nationality',e.code,index)}}
                      className={`form-control p-0 ${errors.___nationality && 'error'}`}
                      getOptionLabel={option => option.nationality}
                      getOptionValue={option => option.code}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___nationality}
                />
                {errors[___nationality] && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-4">
              {item.nationality === 'CH' &&
              <div className="form-group">
                <label>City of origin {props.isShortcodeVisible && <small>{'(originCity)'}</small>}</label>
                <input
                  {...register(___originCity, { required: true })}
                  type="text"
                  name={___originCity}
                  value={item.originCity}
                  onChange={(e)=>_updateFieldPeoples('originCity',e.target.value,index)}
                  className={`form-control ${errors.___city && 'error'}`}
                />
                {errors[___originCity] && <div className="errorField">Required</div>}
              </div>
              }
            </div>
            <div className="col-md-4">
              {item.nationality === 'CH' &&
              <div className="form-group">
                <label>Canton of origin {props.isShortcodeVisible && <small>{'(originCanton)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___originCanton}
                      value={_itemByValue(item.originCanton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('originCanton',e.value,index)}}
                      className={`form-control p-0 ${errors.___originCanton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___originCanton}
                />
                {errors[___originCanton] && <div className="errorField">This field is required</div>}
              </div>
              }
            </div>
          </div>
        </div>

        <>
        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Role {props.isShortcodeVisible && <small>{'(role)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___role}
                      value={_itemByValue(item.role, _optFounderRoles)}
                      options={_rearangeFounderRoles()}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('role',e.value,index)}}
                      className={`form-control p-0 ${errors.___role && 'error'}`}
                      getOptionLabel={option => item.gender === 'female' ? option.labelFemale : option.labelMale}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___role}
                />
                {errors[___role] && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-4">
              {!item.isCompany &&
              <div className="form-group">
                <label>Signature {props.isShortcodeVisible && <small>{'(signature)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___signature}
                      value={_itemByValue(item.signature, _optSignatures)}
                      options={_optSignatures}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('signature',e.value,index)}}
                      className={`form-control p-0 ${errors.___role && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___signature}
                />
                {errors[___signature] && <div className="errorField">This field is required</div>}
              </div>
              }
            </div>
          </div>
        </div>
        </>

        
      </div>
      <div className="options">
        <span>{index+1}</span>
        <button type="button" className="button blank" onClick={()=> _removeRepeaterPeopleItem(index)}><XCircle sizes={24} color={'#ff0000'}/></button>
      </div>
      </div>
    )
  }


  //
  //
  return (
    <Fragment>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">

      <h3>Company info</h3>
      <div className="row">

        <div className="col-md-6">
          <div className="form-group">
            <label>Legal structures {props.isShortcodeVisible && <small>{'{COMPANY_LEGAL_STRUCTURES}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name="legalStructures"
                  value={_itemByValue(formData.legalStructures, _optLegalStructuresFilterd)}
                  options={_optLegalStructuresFilterd}
                  onChange={(e)=> {field.onChange(e.value); _updateField('legalStructures',e.value)}}
                  className={`form-control p-0 ${errors.legalStructures && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name="legalStructures"
            />
            {errors.legalStructures && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Company ID {props.isShortcodeVisible && <small>{'{COMPANY_ID}'}</small>}</label>
            <input
              {...register("companyId", { required: true })}
              type="text"
              name="companyId"
              value={formData.companyId}
              onChange={(e)=>_updateField('companyId',e.target.value)}
              className={`form-control ${errors.companyId && 'error'}`}
            />
            {errors.companyId && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Municipality Siege {props.isShortcodeVisible && <small>{'{COMPANY_MUNICIPALITY_SIEGE}'}</small>}</label>
            <input
              {...register("municipalitySiege", { required: true })}
              type="text"
              name="municipalitySiege"
              value={formData.municipalitySiege}
              onChange={(e)=>_updateField('municipalitySiege',e.target.value)}
              className={`form-control ${errors.municipalitySiege && 'error'}`}
            />
            {errors.municipalitySiege && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'{COMPANY_ADDRESS}'}</small>}</label>
            <input
              {...register("address", { required: true })}
              type="text"
              name="address"
              value={formData.address}
              onChange={(e)=>_updateField('address',e.target.value)}
              className={`form-control ${errors.address && 'error'}`}
            />
            {errors.address && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Municipality {props.isShortcodeVisible && <small>{'{COMPANY_MUNICIPALITY}'}</small>}</label>
            <input
              {...register("municipality", { required: true })}
              type="text"
              name="municipality"
              value={formData.municipality}
              onChange={(e)=>_updateField('municipality',e.target.value)}
              className={`form-control ${errors.municipality && 'error'}`}
            />
            {errors.municipality && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'{COMPANY_ZIPCODE}'}</small>}</label>
            <input
              {...register("zipCode", { required: true })}
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={(e)=>_updateField('zipCode',e.target.value)}
              className={`form-control ${errors.zipCode && 'error'}`}
            />
            {errors.zipCode && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Canton {props.isShortcodeVisible && <small>{'{COMPANY_CANTON}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={'canton'}
                  value={_itemByValue(formData.canton, _optCanton)}
                  options={_optCanton}
                  onChange={(e)=> {field.onChange(e.value); _updateField('canton',e.value)}}
                  className={`form-control p-0 ${errors.canton && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name={'canton'}
            />
            {errors.canton && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6">
            <div className="form-group">
              <label>Notary {props.isShortcodeVisible && <small>{'{COMPANY_NOTARY}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <Select
                    name={'notaryId'}
                    value={_itemByValue(formData.notaryId, notaries)}
                    options={notaries}
                    onChange={(e)=> {field.onChange(e.value); _updateField('notaryId',e.value)}}
                    className={`form-control p-0 ${errors.notaryId && 'error'}`}
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'notaryId'}
              />
              {errors.notaryId && <div className="errorField">This field is required</div>}
            </div>
        </div>

      </div>
      <div className="row">

        <div className="col-md-12">
          <hr/>
        </div>

        
        <div className="col-md-6">
          <div className="form-group">
            <label>Company name OLD {props.isShortcodeVisible && <small>{'{COMPANY_NAME_OLD}'}</small>}</label>
            <input
              {...register("oldCompanyName", { required: true })}
              type="text"
              name="oldCompanyName"
              value={formData.oldCompanyName}
              onChange={(e)=>_updateField('oldCompanyName',e.target.value)}
              className={`form-control ${errors.oldCompanyName && 'error'}`}
            />
            {errors.oldCompanyName && <div className="errorField">This field is required</div>}
          </div>
        </div>
        
        <div className="col-md-6">
          <div className="form-group">
            <label>Company name NEW{props.isShortcodeVisible && <small>{'{COMPANY_NAME_NEW}'}</small>}</label>
            <input
              {...register("newCompanyName", { required: true })}
              type="text"
              name="newCompanyName"
              value={formData.newCompanyName}
              onChange={(e)=>_updateField('newCompanyName',e.target.value)}
              className={`form-control ${errors.newCompanyName && 'error'}`}
            />
            {errors.newCompanyName && <div className="errorField">This field is required</div>}
          </div>
        </div>


        

      </div>

      <hr/>

      <h3>Founders {formDataPeoples.length>0 && '['+formDataPeoples.length+']'}</h3>
      {
        formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
          return _repeaterFoundersItem(item, index)
        })
      }
      <button type="button" onClick={()=> _addRepeaterPeopleItem()} className="button dark outline lg ml-1"><PlusCircle/>Add new</button>

      <hr/>

      <h3>Billing info</h3>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label>Company name {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_NAME}'}</small>}</label>
            <input
              {...register("billingCompanyName", { required: true })}
              type="text"
              name="billingCompanyName"
              value={formData.billingCompanyName}
              onChange={(e)=>_updateField('billingCompanyName',e.target.value)}
              className={`form-control ${errors.billingCompanyName && 'error'}`}
            />
            {errors.billingCompanyName && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_ADDRESS}'}</small>}</label>
            <input
              {...register("billingAddress", { required: true })}
              type="text"
              name="billingAddress"
              value={formData.billingAddress}
              onChange={(e)=>_updateField('billingAddress',e.target.value)}
              className={`form-control ${errors.billingAddress && 'error'}`}
            />
            {errors.billingAddress && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_ZIPCODE}'}</small>}</label>
            <input
              {...register("billingZipCode", { required: true })}
              type="text"
              name="billingZipCode"
              value={formData.billingZipCode}
              onChange={(e)=>_updateField('billingZipCode',e.target.value)}
              className={`form-control ${errors.billingZipCode && 'error'}`}
            />
            {errors.billingZipCode && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>City {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_CITY}'}</small>}</label>
            <input
              {...register("billingCity", { required: true })}
              type="text"
              name="billingCity"
              value={formData.billingCity}
              onChange={(e)=>_updateField('billingCity',e.target.value)}
              className={`form-control ${errors.billingCity && 'error'}`}
            />
            {errors.billingCity && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-12">
          <hr/>
        </div>
      </div>

      {props.alt ?
          <div className="d-flex justify-content-end mt-5">
            <button type="submit" className="button accent lg ml-1"  disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>Save data</button>
          </div>
        :
          <div className="d-flex justify-content-between mt-5">
            <div>
              {/* <div className="switch mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'sendEmailStep1'}
                  value="1"
                  checked={sendEmail}
                  onChange={(e)=>setSendEmail(!sendEmail)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Send email to client with attached documents | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
              </div> */}
            </div>
            <button type="submit" className="button accent lg ml-1"  disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>Save step #1</button>
          </div>
        }

      </form>

      <Loading status={loading}/>

    </Fragment>
  );

}
