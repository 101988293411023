import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { GitPullRequest, ChevronLeft, CheckCircle, MessageSquare, FileText, Activity, Mail, Clock } from 'react-feather'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs';
import fileDownload from 'js-file-download'

// variables
import { SITENAME, _optRequestStatuses, _shortcodeLabelVisible } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';


import RequestFormsStep0 from './step0';
import RequestFormsStep1 from './step1';
import RequestFormsStep2 from './step2';
import RequestFormsStep3 from './step3';
import RequestFormsStep4 from './step4';
import RequestFormsStep5 from './step5';

import RequestFormsStep8 from './step8';
import RequestFormsStep9 from './step9';
import RequestFormsStep10 from './step10';
import RequestFormsStep11 from './step11';

import TimeSheet from './timeSheet'

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPOST, _apiPUT, _apiGetFile } from '../../controllers/Api';
import { _goBack, _itemByValue, _returnMaxStep } from '../../controllers/Helpers';


export default function RequestForms(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const [ loading, setLoading ] = React.useState(true);
  const [ data, setData ] = React.useState({})

  const [ step, setStep ] = React.useState(0)
  const [ validStep, setValidStep ] = React.useState(0)
  const [ status, setStatus ] = React.useState(1)
  const [ rand, setRand ] = React.useState(1)

  const [ logsTotal ,setLogsTotal ] = React.useState(0)
  const [ notesTotal ,setNotesTotal ] = React.useState(0)
  const [ timesheetTotal ,setTimesheetTotal ] = React.useState(0)

  const [ isShortcodeVisible, setIsShortcodeVisible ] = React.useState(false)


  //
  //
  const _title = () =>{
    return data.status ? _optRequestStatuses.find(x => x.value===data.status).label : _optRequestStatuses[0].label
  }


  //
  //
  const _changeStatus = async () => {

    if(status===data.status){
      toast.info('Status must be different from current one.');
      return;
    }
    
    let formData = {
      type: 'status',
      status:status,
      statusName:  _optRequestStatuses.find(x => x.value===status).label,
      userId: auth.response._id,
      clientId: auth.response.clientId
    }

    const update = await _apiPUT('/requests/'+id, formData)
    if(update.statusCode !== 200){
      toast.error(update.message);
    }else{
      toast.success(update.message);
      setRand(rand+1)
    }

  }


  // 
  // 
  const _submitEmailConfig = async(values) => {

    let formData = {
      emailConfig:values,
      userId: auth.response._id,
      clientId: auth.response.clientId
    }

    const update = await _apiPUT('/requests/manual-send/'+id, formData)
    if(update.statusCode !== 200){
      toast.error(update.message);
    }else{
      toast.success(update.message);
      setRand(rand+1)
    }

  }


  //
  //
  const _submit = async (k,v) => {

    if(id==='new'){

      const formData = {
        sendEmail:v.sendEmail,
        registrationType:v.registrationType,
        start: v.start,
        status:1,
        userId: auth.response._id,
        clientId: auth.response.clientId
      }

      const insert = await _apiPOST('/requests/', formData)
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        history.push('/requests/created/'+insert._id)
      }

    }else{

      let formData = {
        invoiceNumber: data.invoiceNumber ? data.invoiceNumber : null
      }

      if(k==='step0'){
        formData = {
          ...formData,
          type: 'step #0',
          registrationType:v.registrationType,
          sendEmail:v.sendEmail,
          start: v.start,
          userId: auth.response._id,
          clientId: auth.response.clientId
        }
      }

      if(k==='step1'){
        formData = {
          ...formData,
          ...v,
          type: 'step #1',
          status:4,
          userId: auth.response._id,
          clientId: auth.response.clientId
        }
      }

      if(k==='step2'){
        formData = {
          ...formData,
          sendEmail:v.sendEmail,
          company:{
            ...data.company,
            ...v.company
          },
          type: 'step #2',
          status:7,
          userId: auth.response._id,
          clientId: auth.response.clientId
        }
      }

      if(k==='step4'){
        formData = {
          ...formData,
          company:{
            ...data.company,
            ...v.company
          },
          sendEmail:v.sendEmail,
          type: 'step #4',
          status:9,
          userId: auth.response._id,
          clientId: auth.response.clientId
        }
      }

      if(k==='step3'){
        formData = {
          ...formData,
          sendEmail:v.sendEmail,
          datePayment2: v.datePayment2,
          company:{
            ...data.company,
            ...v.company
          },
          invoice: v.invoice,
          type: 'step #3',
          status:8,
          userId: auth.response._id,
          clientId: auth.response.clientId
        }
      }

      if(k==='step5'){
        formData = {
          ...formData,
          sendEmail:v.sendEmail,
          type: 'step #5',
          datePayment: v.datePayment,
          status:10,
          userId: auth.response._id,
          clientId: auth.response.clientId
        }
      }

      const update = await _apiPUT('/requests/'+id, formData)
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        setRand(rand+1)
      }

    }

  }


  //
  //
  const _getPdf = async (filename, doc) => {

    const response = await _apiGetFile('/documents/pdf/', {
      documentKey:doc,
      requestId:id,
      userId:auth.response._id,
      clientId:auth.response.clientId
    })
    if(response){
      fileDownload(response, filename);
      setRand(rand+1)
    }

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    let route = '/requests/'

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone(route+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          setData({...res})
          setStatus(res.status)

          const _validStep = _returnMaxStep(res)
          // console.log('_returnMaxStep(res)',_validStep, rand)
          setValidStep(_validStep)
          // if(rand==1){
          //   setStep(_validStep)
          // }
        }
      }
      setLoading(false)
    }
    if(auth.status){
      setIsShortcodeVisible(_shortcodeLabelVisible())
      getData()
    }

    return (() => [getData])
  },[id, auth.status, auth.response.role, rand])


  //
  //
  const _updateRand = () => {
    setRand(rand+1);
    // console.log('rand',rand)
  }


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <PageHead
        title={_title()}
        description={`Last update: ${data.dateUpdated ?  dayjs.tz(data.dateUpdated).format('DD.MM.YYYY') : 'N/A'} | by: ${auth.response.firstName} ${auth.response.lastName}`}
        icon={<GitPullRequest size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
      </PageHead>

      <div className="relative w-100">
        <div className="row d-flex justify-content-end">

          {id==='new' && <div className="col-md-4 col-lg-6"><p className="small m-0 py-3">Changing status is disabled during the creation of a new request.</p></div>}
          <div className="col-md-8 col-lg-6">
            <div className="form-group mb-4 d-flex justify-content-start align-items-center">
              <label>STATUS</label>
              <Select
                name="status"
                value={_itemByValue(status, _optRequestStatuses)}
                options={_optRequestStatuses}
                onChange={(e)=> setStatus(e.value)}
                className={`form-control p-0 mx-3`}
                isDisabled={id==='new' || data.status===10 ? true : false}
              />
              <button className="button lg w-100" disabled={(id==='new' || data.status===10 || data.status===20 )? true : false} onClick={()=>_changeStatus()}>update status</button>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-100 tabs">

       {console.log("current step",step,validStep)}
        <div className="tabNav">
          <div>
            <button onClick={()=> setStep(0)} className={`${id !== 'new' && 'completed'} ${step === 0 && 'active'}`}>{step >= 0 && id !== 'new' && <CheckCircle size={16}/>}New request</button>
            <button onClick={()=> setStep(1)} className={`${validStep > 1 && 'completed'} ${step === 1 && 'active'}`} disabled={validStep < 1 ? true : false}>{validStep > 1 && <CheckCircle size={16}/>}Step #1</button>
            <button onClick={()=> setStep(2)} className={`${validStep > 2 && 'completed'} ${step === 2 && 'active'}`} disabled={validStep < 2 ? true : false}>{validStep > 2 && <CheckCircle size={16}/>}Step #2</button>
            <button onClick={()=> setStep(3)} className={`${validStep > 3 && 'completed'} ${step === 3 && 'active'}`} disabled={validStep < 3 ? true : false}>{validStep > 3 && <CheckCircle size={16}/>}Step #3</button>
            <button onClick={()=> setStep(4)} className={`${validStep > 4 && 'completed'} ${step === 4 && 'active'}`} disabled={validStep < 4 ? true : false}>{validStep > 4 && <CheckCircle size={16}/>}Step #4</button>
            <button onClick={()=> setStep(5)} className={`${validStep > 5 && 'completed'} ${step === 5 && 'active'}`} disabled={validStep < 4 ? true : false}>{validStep > 5 && <CheckCircle size={16}/>}Step #5</button>
          </div>
          <div>
            <button onClick={()=> setStep(12)} className={`${step === 12 && 'active'}`} disabled={id === 'new' ? true : false}><Clock size={16}/>Timesheet{timesheetTotal !== 0 && ' ('+timesheetTotal+')'}</button>
            <button onClick={()=> setStep(8)} className={`${step === 8 && 'active'}`} disabled={id === 'new' ? true : false}><Activity size={16}/>Logs{logsTotal !== 0 && ' ('+logsTotal+')'}</button>
            <button onClick={()=> setStep(9)} className={`${step === 9 && 'active'}`} disabled={id === 'new' ? true : false}><MessageSquare size={16}/>Notes{notesTotal !== 0 && ' ('+notesTotal+')'}</button>
            <button onClick={()=> setStep(10)} className={`${step === 10 && 'active'}`} disabled={id === 'new' ? true : false}><Mail size={16}/>Email</button>
            <button onClick={()=> setStep(11)} className={`${step === 11 && 'active'}`} disabled={id === 'new' || validStep<2 ? true : false}><FileText size={16}/>Documents</button>
          </div>
        </div>
        <div className="tabContent">

          <div className={step===0 ? '' : 'd-none'}>
            <RequestFormsStep0 auth={auth} data={data} action={_submit} isNew={id==='new'} isShortcodeVisible={isShortcodeVisible}/>
          </div>
          <div className={step===1 ? '' : 'd-none'}>
            <RequestFormsStep1 data={data} action={_submit} isShortcodeVisible={isShortcodeVisible}/>
          </div>
          <div className={step===2 ? '' : 'd-none'}>
            <RequestFormsStep2 data={data} action={_submit} isShortcodeVisible={isShortcodeVisible}/>
          </div>
          <div className={step===3 ? '' : 'd-none'}>
            <RequestFormsStep4 data={data} action={_submit} isShortcodeVisible={isShortcodeVisible}/>
          </div>
          <div className={step===4 ? '' : 'd-none'}>
            <RequestFormsStep3 data={data} action={_submit} isShortcodeVisible={isShortcodeVisible}/>
          </div>
          <div className={step===5 ? '' : 'd-none'}>
            <RequestFormsStep5 data={data} action={_submit} isShortcodeVisible={isShortcodeVisible}/>
          </div>

          <div className={step===8 ? '' : 'd-none'}>
            <RequestFormsStep8 id={id} rand={rand} getTotal={setLogsTotal}/>
          </div>
          <div className={step===9 ? '' : 'd-none'}>
            <RequestFormsStep9 data={data} updateNotesTotal={setNotesTotal}/>
          </div>
          <div className={step===10 ? '' : 'd-none'}>
            <RequestFormsStep10 updateRand={_updateRand} data={data}/>
          </div>
          <div className={step===12 ? '' : 'd-none'}>
            <TimeSheet data={data} updateTimesheetTotal={setTimesheetTotal} />
          </div>

          {data.company && data.company.legalStructures && step===11 &&
          <div>
            <RequestFormsStep11 auth={auth} id={id} rand={rand} data={data} isNew={id==='new'} action={_getPdf} sendAction={_submitEmailConfig} validStep={validStep}/>
          </div>
          }

        </div>

        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
