import React, { Fragment } from 'react';
import { UploadCloud, Download, XCircle } from 'react-feather'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import {useDropzone} from 'react-dropzone'
import fileDownload from 'js-file-download'

// components
import Loading from '../../components/Loading';
import Table from '../../components/Table';

// controllers
import _actions from '../../controllers/redux/actions'
import { API, _apiGETmulti, _apiDELETE, _apiGetStaticFile } from '../../controllers/Api';


export default function RequestFormsStep11(props){


  const fields = {
    DocumentASigner: false,
    Requisition: false,
    DeclarationII: false,
    DeclarationOpingOut: false,
    Procuration: false,
    LegalisationDeSignature: false,
    AtTestationBancaire: false,
  }


  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const [ formData, setFormData ] = React.useState(fields)

  const [ loading, setLoading ] = React.useState(false);

  const [ totalUploaded, setTotalUploaded ] = React.useState(0)
  const [ filesUploaded, setFilesUploaded ] = React.useState([])



  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const onDrop = React.useCallback((acceptedFiles) => {

    setLoading(true);

    let formData = new FormData();
    formData.append('clientId', auth.response.clientId);
    formData.append('userId', auth.response._id);
    formData.append('requestId', props.data._id);
    for (var i = 0; i < acceptedFiles.length; i++) {
      formData.append('files[]', acceptedFiles[i]);
      formData.append('names[]', acceptedFiles[i].name);
    }

    API(true, {})
    .post('files/', formData)
    .then(response => {

      setFilesUploaded([...response.data.files, ...filesUploaded])
      setTotalUploaded(totalUploaded + response.data.files.length)
      setLoading(false);
      toast.success(response.data.message);

    }).catch(error => {
      setLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });

  }, [auth]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});


  //
  //
  const _delete = async (id, index) => {

    let route = '/files/'

    const del = await _apiDELETE(route+id, { 'X-Meta': JSON.stringify({clientId: auth.response.clientId}) });
    if(del.error){
      toast.error(del.message);
    }else{
      toast.success(del.message);
      const updateFiles = filesUploaded
      updateFiles.splice(index, 1)
      setFilesUploaded(updateFiles)
      setTotalUploaded(totalUploaded - 1)
    }

  }


  //
  //
  const _download = async (id, filename) => {

    let route = '/files/'

    // const download = await _apiGETone(route+id);
    // if(download.error){
    //   toast.error(download.message);
    // }else{
    //   toast.success('File '+filename+' downloaded');
    //   fileDownload(download, filename);
    // }

    const response = await _apiGetStaticFile(route+id, {})
    if(response){
      toast.success('File '+filename+' downloaded');
      fileDownload(response, filename);
    }

  }


  //
  //
  const _downloadZip = async (type, id, filename) => {

    if(type==='uploads'){
      let route = '/files/zip/'

      const response = await _apiGetStaticFile(route+id, {})
      if(response){
        toast.success('File '+filename+' downloaded');
        fileDownload(response, filename);
      }
    }

    if(type==='group1'){
      let route = '/documents/zip-group1/'

      const response = await _apiGetStaticFile(route+id, {})
      if(response){
        toast.success('File '+filename+' downloaded');
        fileDownload(response, filename);
      }
    }

    if(type==='group2'){
      let route = '/documents/zip-group2/'

      const response = await _apiGetStaticFile(route+id, {})
      if(response){
        toast.success('File '+filename+' downloaded');
        fileDownload(response, filename);
      }
    }

    if(type==='group3'){
      let route = '/documents/zip-group3/'

      const response = await _apiGetStaticFile(route+id, {})
      if(response){
        toast.success('File '+filename+' downloaded');
        fileDownload(response, filename);
      }
    }

  }


  //
  //
  const _downloadMerged = async (type, id, filename) => {

    if(type==='group2'){
      let route = '/documents/merge-group2/'

      const response = await _apiGetStaticFile(route+id, {})
      if(response){
        toast.success('File '+filename+' downloaded');
        fileDownload(response, filename);
      }
    }


    if(type==='group3'){
      let route = '/documents/merge-group3/'

      const response = await _apiGetStaticFile(route+id, {})
      if(response){
        toast.success('File '+filename+' downloaded');
        fileDownload(response, filename);
      }
    }

  }


  //
  //
  const _submit = async () => {
    props.sendAction(formData)
  }


  //
  //
  React.useEffect(()=>{
    if(props.data && props.data.emailConfig && !props.isNew){
      setFormData(props.data.emailConfig)
    }
  },[props.data, props.isNew])


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      const res = await _apiGETmulti('/files/query/'+props.data._id, {})
      if(!res){
        setTotalUploaded(0)
        setFilesUploaded([])
      }else{
        setTotalUploaded(res.total)
        setFilesUploaded(res.data)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[props.rand])


  //
  //
  const _options = (data, index) => {

    return (
      <div className="d-flex justify-content-end align-items-center">
        <button type="button" className="iconButton" onClick={()=>_download(data._id, data.filename)}><Download size={20}/></button>
        <button type="button" className="iconButton" onClick={()=>_delete(data._id, index)}><XCircle size={20}/></button>
      </div>
    )

  }

  // console.log('props.validStep',props.validStep)
  //
  //
  return (
    <Fragment>

      <div className="relative w-100">

        <div className="row">



          <div className="col-md-6 pt-3 pb-4">

            <h3>Documents received from client</h3>
            <div {...getRootProps()} className="dropZone">
              <UploadCloud size={40} />
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drag 'n' drop some files here, or click to select files</p>
              }
            </div>
            {totalUploaded === 0 && <p className="py-4 text-center small">There's no documents uploaded.</p>}
            {totalUploaded > 0 &&
              <>
              <div className="tableWrapper mt-4">
                <div className="body">
                  <Table
                    action={null}
                    perPage={100}
                    sortBy={'dateCreated|desc'}
                    head={
                      [
                        {name:'Filename', width:'auto', sortable:false, key:'filename'},
                        {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
                      ]
                    }
                    body={filesUploaded}
                    total={totalUploaded}
                  />
                </div>
              </div>
              <button type="button" className="button blank py-2 mt-2 fullWidth" onClick={()=>_downloadZip('uploads',props.data._id, 'current-uploaded-'+props.data._id+'.zip')}>Download all as Zip archive</button>
              </>
            }

            <hr className='mb-5'/>

            <div className="my-3">
              <div className="switch small mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'DocumentASigner'}
                  value="1"
                  checked={formData.DocumentASigner}
                  onChange={(e)=>_updateField('DocumentASigner', !formData.DocumentASigner)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Document à signer</label>
              </div>
            </div>

            <div className="ml-5 my-3">
              <div className="switch small mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'Requisition'}
                  value="1"
                  checked={formData.Requisition}
                  onChange={(e)=>_updateField('Requisition', !formData.Requisition)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Requisition</label>
              </div>
            </div>

            <div className="ml-5 my-3">
              <div className="switch small mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'DeclarationII'}
                  value="1"
                  checked={formData.DeclarationII}
                  onChange={(e)=>_updateField('DeclarationII', !formData.DeclarationII)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Déclaration II</label>
              </div>
            </div>

            <div className="ml-5 my-3">
              <div className="switch small mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'DeclarationOpingOut'}
                  value="1"
                  checked={formData.DeclarationOpingOut}
                  onChange={(e)=>_updateField('DeclarationOpingOut', !formData.DeclarationOpingOut)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Déclaration - opting out</label>
              </div>
            </div>

            <div className="ml-5 my-3">
              <div className="switch small mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'Procuration'}
                  value="1"
                  checked={formData.Procuration}
                  onChange={(e)=>_updateField('Procuration', !formData.Procuration)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Procuration</label>
              </div>
            </div>

            <div className="my-3">
              <div className="switch small mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'LegalisationDeSignature'}
                  value="1"
                  checked={formData.LegalisationDeSignature}
                  onChange={(e)=>_updateField('LegalisationDeSignature', !formData.LegalisationDeSignature)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Légalisation de signature</label>
              </div>
            </div>

            <div className="my-3">
              <div className="switch small mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'AtTestationBancaire'}
                  value="1"
                  checked={formData.AtTestationBancaire}
                  onChange={(e)=>_updateField('AtTestationBancaire', !formData.AtTestationBancaire)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Attestation bancaire</label>
              </div>
            </div>

            {/* <div className="mt-5">
            <button className="button accent lg ml-1" onClick={()=>_submit()}>Send email</button>
            </div> */}
            

          </div>


          

          <div className="col-md-6 pt-3 pb-4">
            <h3>Documents group #1</h3>
            {props.validStep < 2 && <p className="pb-4 small">To access to the documents from this group please complete "step #1"</p>}
            {props.validStep >= 2 && props.data.company.legalStructures === 1 &&
              <>
              <div className="tableWrapper">
                <div className="body">
                  <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td width="70%">Procuration</td>
                      <td width="30%">
                        <button onClick={()=> props.action('procuration_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_procuration_sarl')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Requisition</td>
                      <td width="30%">
                        <button onClick={()=> props.action('requisition_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_requisition_sarl')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Déclaration I et II</td>
                      <td width="30%">
                        <button onClick={()=> props.action('declaration_i_ii_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_declaration_i_ii_sarl')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Déclaration de renonciation au contrôle des comptes annuels</td>
                      <td width="30%">
                        <button onClick={()=> props.action('declaration_de_renoncation_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_declaration_de_renoncation_sarl')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    {auth.response.accountType && auth.response.accountType==='saas' && 
                    <tr>
                      <td width="70%">SG - Lex-Koller-Erklärun</td>
                      <td width="30%">
                        <button onClick={()=> props.action('lex_koller_erklarung_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'lex_koller_erklarung')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    }
                  </tbody>
                  </table>
                </div>
              </div>
              <button type="button" className="button blank py-2 mt-2 fullWidth" onClick={()=>_downloadZip('group1',props.data._id, 'group1-'+props.data._id+'.zip')}>Download all as Zip archive</button>
              </>
            }
            {props.validStep >= 2 && props.data.company.legalStructures === 2 &&
              <>
              <div className="tableWrapper">
                <div className="body">
                  <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td width="70%">Procuration</td>
                      <td width="30%">
                        <button onClick={()=> props.action('procuration_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_procuration_sa')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Requisition</td>
                      <td width="30%">
                        <button onClick={()=> props.action('requisition_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_requisition_sa')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Déclaration I et II</td>
                      <td width="30%">
                        <button onClick={()=> props.action('declaration_i_ii_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_declaration_i_ii_sa')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Déclaration de renonciation au contrôle des comptes annuels</td>
                      <td width="30%">
                        <button onClick={()=> props.action('declaration_de_renoncation_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_declaration_de_renoncation_sarl')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    {auth.response.accountType && auth.response.accountType==='saas' && 
                    <tr>
                      <td width="70%">SG - Lex-Koller-Erklärun</td>
                      <td width="30%">
                        <button onClick={()=> props.action('lex_koller_erklarung_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_lex_koller_erklarung')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    }
                  </tbody>
                  </table>
                </div>
              </div>
              <button type="button" className="button blank py-2 mt-2 fullWidth" onClick={()=>_downloadZip('group1',props.data._id, 'group1-'+props.data._id+'.zip')}>Download all as Zip archive</button>
              </>
            }

            <h3 className="mt-5">Documents group #2</h3>
            {props.validStep < 3 && <p className="pb-4 small">To access to the documents from this group please complete "step #2"</p>}
            {props.validStep >= 3 && props.data.company.legalStructures === 1 &&
              <>
              <div className="tableWrapper">
                <div className="body">
                  <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td width="70%">Statuts</td>
                      <td width="30%">
                        <button onClick={()=> props.action('statuts_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_statuts_sarl')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Cover Letter</td>
                      <td width="30%">
                        <button onClick={()=> props.action('cover_letter_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_cover_letter')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Acte constitutif</td>
                      <td width="30%">
                        <button onClick={()=> props.action('acte_constitutif_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_acte_constitutif_sarl')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Merged<br/><small>Statuts + Acte constitutif</small></td>
                      <td width="30%">
                        <button onClick={()=> _downloadMerged('group2',props.data._id, 'merged-'+props.data._id+'.pdf')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
              </div>
              <button type="button" className="button blank py-2 mt-2 fullWidth" onClick={()=>_downloadZip('group2',props.data._id, 'group2-'+props.data._id+'.zip')}>Download all as Zip archive</button>
              </>
            }
            {props.validStep >= 3 && props.data.company.legalStructures === 2 &&
              <>
              <div className="tableWrapper">
                <div className="body">
                  <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td width="70%">Statuts</td>
                      <td width="30%">
                        <button onClick={()=> props.action('statuts_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_statuts_sa')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Cover Letter</td>
                      <td width="30%">
                        <button onClick={()=> props.action('cover_letter_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_cover_letter')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Acte constitutif</td>
                      <td width="30%">
                        <button onClick={()=> props.action('acte_constitutif_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_acte_constitutif_sa')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Merged<br/><small>Statuts + Acte constitutif</small></td>
                      <td width="30%">
                        <button onClick={()=> _downloadMerged('group2',props.data._id, 'merged-'+props.data._id+'.pdf')} className="button fullWidth">Download</button>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
              </div>
              <button type="button" className="button blank py-2 mt-2 fullWidth" onClick={()=>_downloadZip('group2',props.data._id, 'group2-'+props.data._id+'.zip')}>Download all as Zip archive</button>
              </>
            }

            <div>
              <h3 className="mt-5">Documents group #3</h3>
              {props.validStep < 4 && <p className="pb-4 small">To access to the documents from this group please complete "step #3" and "step #4"</p>}
              {props.validStep >= 4 &&
                <>
                <div className="tableWrapper">
                  <div className="body">
                    <table cellPadding="0" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td width="70%">Thank you letter</td>
                        <td width="30%">
                          <button onClick={()=> props.action('thankyou_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_thankyou')} className="button fullWidth">Download</button>
                        </td>
                      </tr>
                      <tr>
                        <td width="70%">Invoice</td>
                        <td width="30%">
                          <button onClick={()=> props.action('invoice_'+(props.data.company.companyName).toLowerCase()+'.pdf', 'doc_invoice')} className="button fullWidth">Download</button>
                        </td>
                      </tr>
                      <tr>
                        <td width="70%">Merged<br/><small>Thank you letter + Invoice</small></td>
                        <td width="30%">
                          <button onClick={()=> _downloadMerged('group3',props.data._id, 'merged-'+props.data._id+'.pdf')} className="button fullWidth">Download</button>
                        </td>
                      </tr>
                    </tbody>
                    </table>
                  </div>
                </div>
                <button type="button" className="button blank py-2 mt-2 fullWidth" onClick={()=>_downloadZip('group3',props.data._id, 'group3-'+props.data._id+'.zip')}>Download all as Zip archive</button>
                </>
              }
            </div>
          </div>



        </div>

        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
