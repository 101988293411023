import { Fragment } from 'react'
import { Editor } from "@tinymce/tinymce-react";

export default function ContentEditorFull(props){

  return(
    <Fragment>
    <Editor
      apiKey="b9hx6jqlko3u8dv5xxkbg5yu2pasga6qhrmsm9acfukwy5bd"
      value={props.initialValue}
      onEditorChange={props.onEditorChange}
      init={{
         height: 500,
         menubar: false,
         statusbar:false,
         imagetools_cors_hosts: ['picsum.photos'],
         image_caption: true,
         image_advtab: true,
         relative_urls: false,
         quickbars_selection_toolbar: 'bold italic underline strikethrough forecolor | quicklink h2 h3 blockquote',
         noneditable_noneditable_class: "mceNonEditable",
         toolbar_mode: 'sliding',
         contextmenu: "link image imagetools | cut copy paste",
         quickbars_insert_toolbar:false,
         plugins: [
           'code autoresize preview paste importcss searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime lists wordcount imagetools textpattern noneditable charmap quickbars emoticons'
         ],
         toolbar:
           'code shortcodes | bold italic underline | fullscreen fontsizeselect formatselect removeformat | alignleft aligncenter alignright alignjustify outdent indent numlist bullist insertfile image media link table charmap emoticons codesample preview'
      }}
    />
    </Fragment>
  )
}
