import React, { Fragment } from 'react';
import { Save, PlusCircle, XCircle } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'

// variables
import {
  _companyFieldsChangeGoal,
  _founderFields,
  _optLegalStructures,
  _optCanton,
  _optCountry,
  _optGender,
  _optFounderRoles,
  _optTeamsRoles,
  _optSignatures,
  _founderCompanyFields
} from '../../configuration.js';

// components
import Loading from '../../components/Loading';
import ContentEditorBasic from '../../components/ContentEditor/basic'

// controllers
import _actions from '../../controllers/redux/actions'
import { _labelByValue, _itemByValue } from '../../controllers/Helpers';
import { _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep1(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const founderFields = _founderFields

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(_companyFieldsChangeGoal)
  const [ companyData, setCompanyData ] = React.useState(_founderCompanyFields)
  const [ formDataPeoples, setformDataPeoples ] = React.useState([])
  const [ sendEmail, setSendEmail ] = React.useState(false);
  const [ _optLegalStructuresFilterd, setOptLegalStructuresFilterd] = React.useState([])
  const [ notaries, setNotaries ] = React.useState([])

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();

  const [ goalErrors1, setGoalError1 ] = React.useState(false)
  const [ goalErrors2, setGoalError2 ] = React.useState(false)
  
  console.log('_optLegalStructuresFilterd',_optLegalStructuresFilterd)


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }

  //
  //
  const _handleEditorChange = (content, editor) => {
    setFormData({...formData, additonalContent:content})
  }

  //
  //
  const _handleEditorChange1 = (content, editor) => {
    setGoalError1(null)
    setFormData({...formData, goalOld:content})
  }
  const _handleEditorChange2 = (content, editor) => {
    setGoalError2(null)
    setFormData({...formData, goalNew:content})
  }


  //
  //
  const _updateCompanyField = (field, data) => {
    setCompanyData({...companyData, [field]:data});
  }


  //
  //
  const _updateFieldPeoples = (field, data, index) => {
    let newArray = formDataPeoples
    newArray[index] = {
      ...newArray[index],
      [field]: data
    }
    setformDataPeoples([...newArray]);
  }


  //
  //
  const _addRepeaterPeopleItem = () => {
    let newArray = founderFields
    if(props.data.registrationType==='company'){
      newArray.isTeamMember = true
    }
    setformDataPeoples(founders => [...formDataPeoples, newArray])
  }

  //
  //
  const _removeRepeaterPeopleItem = (index) => {
    let newArray = formDataPeoples.filter((x, i)=>{
      return i !== index && x
    });
    setformDataPeoples(founders => newArray)
  }


  //
  //
  const _submit = async () => {
    if(formData.goalOld === ''){
      setGoalError1(true);
      return;
    }
    if(formData.goalNew === ''){
      setGoalError2(true);
      return;
    }
    props.action('step1',{company:formData, peoples:formDataPeoples, sendEmail:sendEmail, founderCompany:companyData})
  }


  //
  //
  const _rearangeFounderRoles = () => {

    let array = []
    const _sarl = [1,2,3,4,5,6,7]
    const _sa   = [21,22,23,24,25,26,27,28]
    const _team = [41,42,43]
    _optFounderRoles.map((item,index)=>{
      if(formData.legalStructures===1 && _sarl.includes(item.value)){
        array.push(item)
      }
      if(formData.legalStructures===2 && _sa.includes(item.value)){
        array.push(item)
      }
    })

    console.log('array',array,_optFounderRoles)
    return array;
  }


  //
  //
  React.useEffect(()=>{
    if(formData.capital !== 0 && formData.partsNumbers !== 0){
      const partsValue = (formData.capital/formData.partsNumbers)
      _updateField('partsValue', partsValue.toFixed(2));
      formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
        _updateFieldPeoples('totalNumber',partsValue !== 0 && item.actionsNumber !== 0 ? (parseFloat(partsValue)*parseFloat(item.actionsNumber)).toFixed(2) : 0,index)
      })
    }
  },[formData.capital, formData.partsNumbers])


  //
  //
  React.useEffect(()=>{


    const getData = async () =>{

      const res = await _apiGETmulti('/notary/query', {limit:1000, clientId: auth.response.clientId})
      if(!res){
        setNotaries([])
      }else{
        let newNotaryArray = [];
        res.data && res.data.map((item,index)=>{
          newNotaryArray.push({value:item._id, label:item.info.firstName+' '+item.info.lastName+' ('+item.info.agentFirstName+' '+item.info.agentLastName+')'})
          return null
        })
        setNotaries(newNotaryArray)

        let leaglStructuresList = []
        const _optLegalStructuresFilterd = _optLegalStructures.map((item,index)=>{
          if(index<2){
            leaglStructuresList.push(item)
          }
        })
        
        setOptLegalStructuresFilterd(leaglStructuresList)

        if(props.data.founderCompany && !props.isNew){
          setCompanyData(props.data.founderCompany)
          setValue('c_name', props.data.founderCompany.name)
          setValue('c_type', props.data.founderCompany.type)
          setValue('c_number', props.data.founderCompany.number)
          setValue('c_address', props.data.founderCompany.address)
          setValue('c_zipCode', props.data.founderCompany.zipCode)
          setValue('c_municipality', props.data.founderCompany.municipality)
          setValue('c_country', props.data.founderCompany.country)
        }

        if(props.data.company && !props.isNew){
          setFormData(props.data.company)
            // console.log(props.data)
          setValue('notaryId', props.data.company.notaryId)
          setValue('companyName', props.data.company.companyName)
          setValue('legalStructures', props.data.company.legalStructures)
          setValue('address', props.data.company.address)
          setValue('zipCode', props.data.company.zipCode)
          setValue('municipalityAddress', props.data.company.municipalityAddress)
          setValue('municipalitySiege', props.data.company.municipalitySiege)
          setValue('canton', props.data.company.canton)
          // setValue('goal', props.data.company.goal)
          setValue('capital', props.data.company.capital)
          setValue('capitalWritten', props.data.company.capitalWritten)
          setValue('partsNumbers', props.data.company.partsNumbers)
          setValue('partsWritten', props.data.company.partsWritten)
          setValue('partsValue', props.data.company.partsValue)
          setValue('partsValueWritten', props.data.company.partsValueWritten)
          setValue('capitalLibere', props.data.company.capitalLibere)
        }else{
          // console.log('objective',props.data.start?.objective, props.data.start?.objective===1 ? 20000 : 100000)
          // setValue('capital', props.data.start?.objective===1 ? 20000 : 100000)
          _updateField('capital', props.data.start?.objective===1 ? 20000 : 100000)
        }

        if(props.data.peoples && !props.isNew){
          if(props.data.peoples.length>0){
            setformDataPeoples(props.data.peoples)
            props.data.peoples.map((item,index)=>{
              setValue('genderF'+index, item.gender)
              setValue('firstNameF'+index, item.firstName)
              setValue('lastNameF'+index, item.lastName)
              setValue('dateBirthF'+index, item.dateBirth)
              setValue('nationalityF'+index, item.nationality)
              if(item.originCanton !== ""){
                setValue('originCantonF'+index, item.originCanton)
              }
              setValue('originCityF'+index, item.originCity)
              setValue('addressF'+index, item.address)
              setValue('zipCodeF'+index, item.zipCode)
              setValue('countryF'+index, item.country)
              setValue('cityF'+index, item.city)
              if(item.canton !== ""){
                setValue('cantonF'+index, item.canton)
              }
              setValue('actionsNumberF'+index, item.actionsNumber)
              setValue('actionsWrittenF'+index, item.actionsWritten)
              setValue('totalNumberF'+index, item.totalNumber)
              setValue('totalWrittenF'+index, item.totalWritten)
              setValue('percentageF'+index, item.percentage)
              setValue('signatureF'+index, item.signature)
              setValue('roleF'+index, item.role)
              if(item.isCompany){
                setValue('companyNameF'+index, item.companyName)
                setValue('companyIdF'+index, item.companyId)
                setValue('companyCountryF'+index, item.companyCountry)
                setValue('companyCityF'+index, item.companyCity)
                if(item.companyCanton !== ""){
                  setValue('companyCantonF'+index, item.companyCanton)
                }
              }
            })
          }
        }
        setLoading(false)

      }
    }

    getData()
  },[props.data, props.isNew])


  //
  //
  const _repeaterFoundersItem = (item, index) => {

    const ___gender = 'genderF'+index
    const ___firstName = 'firstNameF'+index
    const ___lastName = 'lastNameF'+index
    const ___dateBirth = 'dateBirthF'+index
    const ___nationality = 'nationalityF'+index
    const ___originCanton = 'originCantonF'+index
    const ___originCity = 'originCityF'+index
    const ___address = 'addressF'+index
    const ___zipCode = 'zipCodeF'+index
    const ___country = 'countryF'+index
    const ___city = 'cityF'+index
    const ___canton = 'cantonF'+index
    const ___actionsNumber = 'actionsNumberF'+index
    const ___actionsWritten = 'actionsWrittenF'+index
    const ___totalNumber = 'totalNumberF'+index
    const ___totalWritten = 'totalWrittenF'+index
    const ___percentage = 'percentageF'+index
    const ___signature = 'signatureF'+index
    const ___role = 'roleF'+index
    const ___isCompany = 'isCompanyF'+index
    const ___companyName = 'companyNameF'+index
    const ___companyId = 'companyIdF'+index
    const ___companyCountry = 'companyCountryF'+index
    const ___companyCity = 'companyCityF'+index
    const ___companyCanton = 'companyCantonF'+index

    const ___hasShares = 'hasSharesF'+index
    const ___isTeamMember = 'isTeamMemberF'+index

    const ___isRepresentative = 'isRepresentativeF'+index

    return (
      <div className="repeaterItem" key={index}>
      <div className="row">


      {props.data.registrationType==='personal' &&
        <>
        <div className="col-12">
          <div className="switch my-4">
            <input
              type="checkbox"
              name={___isCompany}
              value="1"
              checked={item.isCompany}
              onChange={(e)=>_updateFieldPeoples('isCompany', !item.isCompany, index)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Founder as company | <strong>{item.isCompany ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(isCompany)'}</small>}</label>
          </div>
        </div>
        {item.isCompany &&
          <>
          <div className="col-12">
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label>Company name {props.isShortcodeVisible && <small>{'(companyName)'}</small>}</label>
                <input
                  {...register(___companyName, { required: true })}
                  type="text"
                  name={___companyName}
                  value={item.companyName}
                  onChange={(e)=>_updateFieldPeoples('companyName',e.target.value,index)}
                  className={`form-control ${errors.___companyName && 'error'}`}
                />
                {errors[___companyName] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Company ID number {props.isShortcodeVisible && <small>{'(companyId)'}</small>}</label>
                <input
                  {...register(___companyId, { required: true })}
                  type="text"
                  name={___companyId}
                  value={item.companyId}
                  onChange={(e)=>_updateFieldPeoples('companyId',e.target.value,index)}
                  className={`form-control ${errors.___companyId && 'error'}`}
                />
                {errors[___companyId] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Country {props.isShortcodeVisible && <small>{'(companyCountry)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___companyCountry}
                      value={_itemByValue(item.companyCountry, _optCountry, 'code')}
                      options={_optCountry}
                      onChange={(e)=> {field.onChange(e.code); _updateFieldPeoples('companyCountry',e.code,index)}}
                      className={`form-control p-0 ${errors.___companyCountry && 'error'}`}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.code}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___companyCountry}
                />
                {errors[___companyCountry] && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>City {props.isShortcodeVisible && <small>{'(companyCity)'}</small>}</label>
                <input
                  {...register(___companyCity, { required: true })}
                  type="text"
                  name={___companyCity}
                  value={item.companyCity}
                  onChange={(e)=>_updateFieldPeoples('companyCity',e.target.value,index)}
                  className={`form-control ${errors.___companyCity && 'error'}`}
                />
                {errors[___companyCity] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              {item.companyCountry === 'CH' &&
              <div className="form-group">
                <label>Canton {props.isShortcodeVisible && <small>{'(companyCanton)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___companyCanton}
                      value={_itemByValue(item.companyCanton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('companyCanton',e.value,index)}}
                      className={`form-control p-0 ${errors.___companyCanton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___companyCanton}
                />
                {errors[___companyCanton] && <div className="errorField">This field is required</div>}
              </div>
              }
            </div>
          </div>
          </div>
          <div className="col-12"><hr/></div>
          </>
        }
        </>
        }

        {props.data.registrationType==='personal' && item.isCompany && 
        <div className="col-md-12">
          <div className="switch my-4">
            <input
              type="checkbox"
              name={___isRepresentative}
              value="1"
              checked={item.isRepresentative}
              onChange={(e)=>_updateFieldPeoples('isRepresentative', !item.isRepresentative, index)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>THIS PERSON IS REPRESENTATIVE? | <strong>{item.isRepresentative ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(isRepresentative)'}</small>}</label>
          </div>
        </div>
        }

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>First name {props.isShortcodeVisible && <small>{'(firstName)'}</small>}</label>
            <input
              {...register(___firstName, { required: true })}
              type="text"
              name={___firstName}
              value={item.firstName}
              onChange={(e)=>_updateFieldPeoples('firstName',e.target.value, index)}
              className={`form-control ${errors.___firstName && 'error'}`}
            />
            {errors[___firstName] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Last name {props.isShortcodeVisible && <small>{'(lastName)'}</small>}</label>
            <input
              {...register(___lastName, { required: true })}
              type="text"
              name={___lastName}
              value={item.lastName}
              onChange={(e)=>_updateFieldPeoples('lastName',e.target.value, index)}
              className={`form-control ${errors.___lastName && 'error'}`}
            />
            {errors[___lastName] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Gender {props.isShortcodeVisible && <small>{'(gender)'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={___gender}
                  value={_itemByValue(item.gender, _optGender)}
                  options={_optGender}
                  onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('gender',e.value,index)}}
                  className={`form-control p-0 ${errors.___gender && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name={___gender}
            />
            {errors[___gender] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          {/* <div className="form-group">
            <label>Date of birth {props.isShortcodeVisible && <small>{'(dateBirth)'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <DatePicker
                  selected={item.dateBirth ? new Date(item.dateBirth) : item.dateBirth}
                  onChange={(date)=> {field.onChange(date); _updateFieldPeoples('dateBirth',date, index)}}
                  name={___dateBirth}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control"
                  dateFormat="dd.MM.yyyy"
                />
              }
              control={control}
              rules={{ required: true}}
              name={___dateBirth}
            />
            {errors[___dateBirth] && <div className="errorField">This field is required</div>}
          </div> */}
              <div className="form-group">
                <label>Date of birth {props.isShortcodeVisible && <small>{'(dateBirth)'}</small>}</label>
                <input
                  {...register(___dateBirth, { required: true })}
                  type="text"
                  name={___dateBirth}
                  value={item.dateBirth}
                  onChange={(e)=>_updateFieldPeoples('dateBirth',e.target.value,index)}
                  className={`form-control ${errors.dateBirth && 'error'}`}
                />
                {errors[___dateBirth] && <div className="errorField">This field is required</div>}
              </div>
        </div>

        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-md-9">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'(address)'}</small>}</label>
            <input
              {...register(___address, { required: false })}
              type="text"
              name={___address}
              value={item.address}
              onChange={(e)=>_updateFieldPeoples('address',e.target.value,index)}
              className={`form-control ${errors.___address && 'error'}`}
            />
            {errors[___address] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'(zipCode)'}</small>}</label>
            <input
              {...register(___zipCode, { required: false })}
              type="text"
              name={___zipCode}
              value={item.zipCode}
              onChange={(e)=>_updateFieldPeoples('zipCode',e.target.value,index)}
              className={`form-control ${errors.___zipCode && 'error'}`}
            />
            {errors[___zipCode] && <div className="errorField">Required</div>}
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Country of residence {props.isShortcodeVisible && <small>{'(country)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___country}
                      value={_itemByValue(item.country, _optCountry, 'code')}
                      options={_optCountry}
                      onChange={(e)=> {field.onChange(e.code); _updateFieldPeoples('country',e.code,index)}}
                      className={`form-control p-0 ${errors.___country && 'error'}`}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.code}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___country}
                />
                {errors[___country] && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>City of residence {props.isShortcodeVisible && <small>{'(city)'}</small>}</label>
                <input
                  {...register(___city, { required: true })}
                  type="text"
                  name={___city}
                  value={item.city}
                  onChange={(e)=>_updateFieldPeoples('city',e.target.value,index)}
                  className={`form-control ${errors.___city && 'error'}`}
                />
                {errors[___city] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-4">
              {item.country === 'CH' &&
              <div className="form-group">
                <label>Canton {props.isShortcodeVisible && <small>{'(canton)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___canton}
                      value={_itemByValue(item.canton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('canton',e.value,index)}}
                      className={`form-control p-0 ${errors.___canton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___canton}
                />
                {errors[___canton] && <div className="errorField">This field is required</div>}
              </div>
              }
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Nationality {props.isShortcodeVisible && <small>{'(nationality)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___nationality}
                      value={_itemByValue(item.nationality, _optCountry, 'code')}
                      options={_optCountry}
                      onChange={(e)=> {field.onChange(e.code); _updateFieldPeoples('nationality',e.code,index)}}
                      className={`form-control p-0 ${errors.___nationality && 'error'}`}
                      getOptionLabel={option => option.nationality}
                      getOptionValue={option => option.code}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___nationality}
                />
                {errors[___nationality] && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-4">
              {item.nationality === 'CH' &&
              <div className="form-group">
                <label>City of origin {props.isShortcodeVisible && <small>{'(originCity)'}</small>}</label>
                <input
                  {...register(___originCity, { required: true })}
                  type="text"
                  name={___originCity}
                  value={item.originCity}
                  onChange={(e)=>_updateFieldPeoples('originCity',e.target.value,index)}
                  className={`form-control ${errors.___city && 'error'}`}
                />
                {errors[___originCity] && <div className="errorField">Required</div>}
              </div>
              }
            </div>
            <div className="col-md-4">
              {item.nationality === 'CH' &&
              <div className="form-group">
                <label>Canton of origin {props.isShortcodeVisible && <small>{'(originCanton)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___originCanton}
                      value={_itemByValue(item.originCanton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('originCanton',e.value,index)}}
                      className={`form-control p-0 ${errors.___originCanton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___originCanton}
                />
                {errors[___originCanton] && <div className="errorField">This field is required</div>}
              </div>
              }
            </div>
          </div>
        </div>

        {props.data.registrationType==='personal' &&
        <>
        <div className="col-md-12">
          <hr/>
        </div>
        <div className="col-md-12">
          <div className="switch my-4">
            <input
              type="checkbox"
              name={___hasShares}
              value="1"
              checked={item.hasShares}
              onChange={(e)=>{
                _updateFieldPeoples('hasShares', !item.hasShares, index)
                let defaultRole = 1
                if(!item.hasShares && formData.legalStructures===1){
                  defaultRole = 3 // Associée from SARL
                }else if(!item.hasShares && formData.legalStructures===2){
                  defaultRole = 28 // Associée from SA
                }
                _updateFieldPeoples('role',defaultRole, index)
              }}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>SHARES IN THE COMPANY? | <strong>{item.hasShares ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(hasShares)'}</small>}</label>
          </div>
        </div>

        {item.hasShares &&
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label>Number of actions {props.isShortcodeVisible && <small>{'(actionsNumber)'}</small>}</label>
                <input
                  {...register(___actionsNumber, { required: true })}
                  type="number"
                  name={___actionsNumber}
                  value={item.actionsNumber}
                  onChange={(e)=>{
                    const val = e.target.value
                    _updateFieldPeoples('actionsNumber',val,index)
                    _updateFieldPeoples('totalNumber',formData.partsValue !== 0 && val !== 0 ? (parseFloat(formData.partsValue)*parseFloat(val)).toFixed(2) : 0,index)
                  }}
                  className={`form-control ${errors.___actionsNumber && 'error'}`}
                />
                {errors[___actionsNumber] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <label>Number of actions in written {props.isShortcodeVisible && <small>{'(actionsWritten)'}</small>}</label>
                <input
                  {...register(___actionsWritten, { required: true })}
                  type="text"
                  name={___actionsWritten}
                  value={item.actionsWritten}
                  onChange={(e)=>_updateFieldPeoples('actionsWritten',e.target.value,index) }
                  className={`form-control ${errors.___actionsWritten && 'error'}`}
                />
                {errors[___actionsWritten] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Total value {props.isShortcodeVisible && <small>{'(totalNumber)'}</small>}</label>
                <input
                  type="number"
                  name={___totalNumber}
                  value={item.totalNumber}
                  onChange={(e)=>_updateFieldPeoples('totalNumber',e.target.value,index)}
                  className={`form-control`}
                  readOnly={true}
                />
                {errors[___totalNumber] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <label>Total value in written {props.isShortcodeVisible && <small>{'(totalWritten)'}</small>}</label>
                <input
                  {...register(___totalWritten, { required: true })}
                  type="text"
                  name={___totalWritten}
                  value={item.totalWritten}
                  onChange={(e)=>_updateFieldPeoples('totalWritten',e.target.value,index)}
                  className={`form-control ${errors.___totalWritten && 'error'}`}
                />
                {errors[___totalWritten] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Percentage of ownership {props.isShortcodeVisible && <small>{'(percentage)'}</small>}</label>
                <input
                  {...register(___percentage, { required: true })}
                  type="text"
                  name={___percentage}
                  value={item.percentage}
                  onChange={(e)=>_updateFieldPeoples('percentage',e.target.value,index)}
                  className={`form-control ${errors.___percentage && 'error'}`}
                />
                {errors[___percentage] && <div className="errorField">Required</div>}
              </div>
            </div>
          </div>
        </div>
        }
        </>
        }

      {!item.isCompany &&
        <>
        <div className="col-md-12">
          <hr/>
        </div>
        {props.data.registrationType==='personal' &&
        <div className="col-md-12">
          <div className="switch my-4">
            <input
              type="checkbox"
              name={___isTeamMember}
              value="1"
              checked={item.isTeamMember}
              onChange={(e)=>_updateFieldPeoples('isTeamMember', !item.isTeamMember, index)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>DOES THIS PERSON HAS SOME MANAGEMENT ROLE IN THE COMPANY? | <strong>{item.isTeamMember ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(isTeamMember)'}</small>}</label>
          </div>
        </div>
        }

        {item.isTeamMember &&
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Role {props.isShortcodeVisible && <small>{'(role)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___role}
                      value={_itemByValue(item.role, _optFounderRoles)}
                      options={_rearangeFounderRoles()}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('role',e.value,index)}}
                      className={`form-control p-0 ${errors.___role && 'error'}`}
                      getOptionLabel={option => item.gender === 'female' ? option.labelFemale : option.labelMale}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___role}
                />
                {errors[___role] && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-4">
              {!item.isCompany &&
              <div className="form-group">
                <label>Signature {props.isShortcodeVisible && <small>{'(signature)'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={___signature}
                      value={_itemByValue(item.signature, _optSignatures)}
                      options={_optSignatures}
                      onChange={(e)=> {field.onChange(e.value); _updateFieldPeoples('signature',e.value,index)}}
                      className={`form-control p-0 ${errors.___role && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={___signature}
                />
                {errors[___signature] && <div className="errorField">This field is required</div>}
              </div>
              }
            </div>
          </div>
        </div>
        }
        </>
      }

        
      </div>
      <div className="options">
        <span>{index+1}</span>
        <button type="button" className="button blank" onClick={()=> _removeRepeaterPeopleItem(index)}><XCircle sizes={24} color={'#ff0000'}/></button>
      </div>
      </div>
    )
  }


  //
  //
  return (
    <Fragment>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">

      <h3>Company info</h3>
      <div className="row">

        <div className="col-md-6">
          <div className="form-group">
            <label>Legal structures {props.isShortcodeVisible && <small>{'{COMPANY_LEGAL_STRUCTURES}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name="legalStructures"
                  value={_itemByValue(formData.legalStructures, _optLegalStructuresFilterd)}
                  options={_optLegalStructuresFilterd}
                  onChange={(e)=> {field.onChange(e.value); _updateField('legalStructures',e.value)}}
                  className={`form-control p-0 ${errors.legalStructures && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name="legalStructures"
            />
            {errors.legalStructures && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Company name {props.isShortcodeVisible && <small>{'{COMPANY_NAME}'}</small>}</label>
            <input
              {...register("companyName", { required: true })}
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={(e)=>_updateField('companyName',e.target.value)}
              className={`form-control ${errors.companyName && 'error'}`}
            />
            {errors.companyName && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Company ID {props.isShortcodeVisible && <small>{'{COMPANY_ID}'}</small>}</label>
            <input
              {...register("companyId", { required: true })}
              type="text"
              name="companyId"
              value={formData.companyId}
              onChange={(e)=>_updateField('companyId',e.target.value)}
              className={`form-control ${errors.companyId && 'error'}`}
            />
            {errors.companyId && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6">
            <div className="form-group">
              <label>Notary {props.isShortcodeVisible && <small>{'{COMPANY_NOTARY}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <Select
                    name={'notaryId'}
                    value={_itemByValue(formData.notaryId, notaries)}
                    options={notaries}
                    onChange={(e)=> {field.onChange(e.value); _updateField('notaryId',e.value)}}
                    className={`form-control p-0 ${errors.notaryId && 'error'}`}
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'notaryId'}
              />
              {errors.notaryId && <div className="errorField">This field is required</div>}
            </div>
        </div>

      </div>
      <div className="row">

        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Municipality Siege {props.isShortcodeVisible && <small>{'{COMPANY_MUNICIPALITY_SIEGE}'}</small>}</label>
            <input
              {...register("municipalitySiege", { required: true })}
              type="text"
              name="municipalitySiege"
              value={formData.municipalitySiege}
              onChange={(e)=>_updateField('municipalitySiege',e.target.value)}
              className={`form-control ${errors.municipalitySiege && 'error'}`}
            />
            {errors.municipalitySiege && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'{COMPANY_ADDRESS}'}</small>}</label>
            <input
              {...register("address", { required: true })}
              type="text"
              name="address"
              value={formData.address}
              onChange={(e)=>_updateField('address',e.target.value)}
              className={`form-control ${errors.address && 'error'}`}
            />
            {errors.address && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Municipality address {props.isShortcodeVisible && <small>{'{COMPANY_MUNICIPALITY_ADDRESS}'}</small>}</label>
            <input
              {...register("municipalityAddress", { required: true })}
              type="text"
              name="municipalityAddress"
              value={formData.municipalityAddress}
              onChange={(e)=>_updateField('municipalityAddress',e.target.value)}
              className={`form-control ${errors.municipalityAddress && 'error'}`}
            />
            {errors.municipalityAddress && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'{COMPANY_ZIPCODE}'}</small>}</label>
            <input
              {...register("zipCode", { required: true })}
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={(e)=>_updateField('zipCode',e.target.value)}
              className={`form-control ${errors.zipCode && 'error'}`}
            />
            {errors.zipCode && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Canton {props.isShortcodeVisible && <small>{'{COMPANY_CANTON}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={'canton'}
                  value={_itemByValue(formData.canton, _optCanton)}
                  options={_optCanton}
                  onChange={(e)=> {field.onChange(e.value); _updateField('canton',e.value)}}
                  className={`form-control p-0 ${errors.canton && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name={'canton'}
            />
            {errors.canton && <div className="errorField">This field is required</div>}
          </div>
        </div>


        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-12 col-lg-6">
          <div className="form-group">
              <label>Goal OLD {props.isShortcodeVisible && <small>{'{COMPANY_GOAL_OLD}'}</small>}</label>
              <ContentEditorBasic
                value={formData.goalOld}
                initialValue={formData.goalOld}
                onEditorChange={_handleEditorChange1}
              />
              {goalErrors1 && <div className="errorField">This field is required</div>}
            </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
              <label>Goal NEW {props.isShortcodeVisible && <small>{'{COMPANY_GOAL_NEW}'}</small>}</label>
              <ContentEditorBasic
                value={formData.goalNew}
                initialValue={formData.goalNew}
                onEditorChange={_handleEditorChange2}
              />
              {goalErrors2 && <div className="errorField">This field is required</div>}
            </div>
        </div>


      <div className="col-md-12">
        <hr/>
      </div>

        <div className="col-3">
          <div className="form-group">
            <label>Capital {props.isShortcodeVisible && <small>{'{COMPANY_CAPITAL}'}</small>}</label>
            <input
              {...register("capital", { required: true, minvalue:20000 })}
              type="number"
              name="capital"
              value={formData.capital}
              onChange={(e)=>_updateField('capital',e.target.value)}
              className={`form-control ${errors.capital && 'error'}`}
              min="20000"
            />
            {errors.capital && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-9">
          <div className="form-group">
            <label>Capital in written {props.isShortcodeVisible && <small>{'{COMPANY_CAPITAL_WRITTEN}'}</small>}</label>
            <input
              {...register("capitalWritten", { required: true })}
              type="text"
              name="capitalWritten"
              value={formData.capitalWritten}
              onChange={(e)=>_updateField('capitalWritten',e.target.value)}
              className={`form-control ${errors.capitalWritten && 'error'}`}
            />
            {errors.capitalWritten && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Number of parts in numbers {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_NUMBERS}'}</small>}</label>
            <input
              {...register("partsNumbers", { required: true, min:1 })}
              type="number"
              name="partsNumbers"
              value={formData.partsNumbers}
              onChange={(e)=> _updateField('partsNumbers',e.target.value) }
              className={`form-control ${errors.partsNumbers && 'error'}`}
              min="1"
            />
            {errors.partsNumbers && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-9">
          <div className="form-group">
            <label>Number of parts in written {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_WRITTEN}'}</small>}</label>
            <input
              {...register("partsWritten", { required: true })}
              type="text"
              name="partsWritten"
              value={formData.partsWritten}
              onChange={(e)=>_updateField('partsWritten',e.target.value)}
              className={`form-control ${errors.partsWritten && 'error'}`}
            />
            {errors.partsWritten && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Parts value {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_VALUE}'}</small>}</label>
            <input
              type="number"
              name="partsValue"
              value={formData.capital !== 0 && formData.partsNumbers !== 0 ? (formData.capital/formData.partsNumbers).toFixed(2) : 0}
              onChange={(e)=>_updateField('partsValue',e.target.value)}
              className={`form-control`}
              readOnly={true}
            />
          </div>
        </div>
        <div className="col-9">
          <div className="form-group">
            <label>Parts value in written {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_VALUE_WRITTEN}'}</small>}</label>
            <input
              {...register("partsValueWritten", { required: true })}
              type="text"
              name="partsValueWritten"
              value={formData.partsValueWritten}
              onChange={(e)=>_updateField('partsValueWritten',e.target.value)}
              className={`form-control ${errors.partsValueWritten && 'error'}`}
            />
            {errors.partsValueWritten && <div className="errorField">This field is required</div>}
          </div>
        </div>
        {formData.legalStructures === 2 &&
          <div className="col-3">
            <div className="form-group">
              <label>Capital libéré {props.isShortcodeVisible && <small>{'{COMPANY_LIBERE}'}</small>}</label>
              <input
                {...register("capitalLibere", { required: true, min:0, step:0.01 })}
                type="number"
                name="capitalLibere"
                value={formData.capitalLibere}
                onChange={(e)=> _updateField('capitalLibere',e.target.value) }
                className={`form-control ${errors.capitalLibere && 'error'}`}
                min="0"
                step="0.01"
              />
              {errors.capitalLibere && <div className="errorField">This field is required</div>}
            </div>
          </div>
        }

      </div>

      <hr/>
      
      <ContentEditorBasic
        value={formData.additonalContent}
        initialValue={formData.additonalContent}
        onEditorChange={_handleEditorChange}
      />

      <hr/>

      <h3>{props.data.registrationType==='company' ? 'Teams' : 'Founders'} {formDataPeoples.length>0 && '['+formDataPeoples.length+']'}</h3>
      {
        formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
          return _repeaterFoundersItem(item, index)
        })
      }
      <button type="button" onClick={()=> _addRepeaterPeopleItem()} className="button dark outline lg ml-1"><PlusCircle/>Add new</button>

      <hr/>

      <div className="row">
      <div className="col-md-4">
        <div className="switch">
          <input
            type="checkbox"
            name={'billing'}
            value="1"
            checked={formData.billing}
            onChange={(e)=>_updateField('billing', !formData.billing)}
            className={`form-control`}
          />
            <div><i></i></div>
          <label>Is same address for billing | <strong>{formData.billing ? 'YES' : 'NO'}</strong></label>
        </div>
      </div>
      <div className="col-md-12">
        <hr/>
      </div>
      {!formData.billing &&
        <>
        <div className="col-md-3">
          <div className="form-group">
            <label>Company name {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_NAME}'}</small>}</label>
            <input
              {...register("billingCompanyName", { required: true })}
              type="text"
              name="billingCompanyName"
              value={formData.billingCompanyName}
              onChange={(e)=>_updateField('billingCompanyName',e.target.value)}
              className={`form-control ${errors.billingCompanyName && 'error'}`}
            />
            {errors.billingCompanyName && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_ADDRESS}'}</small>}</label>
            <input
              {...register("billingAddress", { required: true })}
              type="text"
              name="billingAddress"
              value={formData.billingAddress}
              onChange={(e)=>_updateField('billingAddress',e.target.value)}
              className={`form-control ${errors.billingAddress && 'error'}`}
            />
            {errors.billingAddress && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_ZIPCODE}'}</small>}</label>
            <input
              {...register("billingZipCode", { required: true })}
              type="text"
              name="billingZipCode"
              value={formData.billingZipCode}
              onChange={(e)=>_updateField('billingZipCode',e.target.value)}
              className={`form-control ${errors.billingZipCode && 'error'}`}
            />
            {errors.billingZipCode && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>City {props.isShortcodeVisible && <small>{'{COMPANY_BILLING_CITY}'}</small>}</label>
            <input
              {...register("billingCity", { required: true })}
              type="text"
              name="billingCity"
              value={formData.billingCity}
              onChange={(e)=>_updateField('billingCity',e.target.value)}
              className={`form-control ${errors.billingCity && 'error'}`}
            />
            {errors.billingCity && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-12">
          <hr/>
        </div>
        </>
      }
      </div>

      {props.alt ?
          <div className="d-flex justify-content-end mt-5">
            <button type="submit" className="button accent lg ml-1"  disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>Save data</button>
          </div>
        :
          <div className="d-flex justify-content-between mt-5">
            <div>
              {/* <div className="switch mb-4 mb-md-0">
                <input
                  type="checkbox"
                  name={'sendEmailStep1'}
                  value="1"
                  checked={sendEmail}
                  onChange={(e)=>setSendEmail(!sendEmail)}
                  className={`form-control`}
                />
                  <div><i></i></div>
                <label>Send email to client with attached documents | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
              </div> */}
            </div>
            <button type="submit" className="button accent lg ml-1"  disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>Save step #1</button>
          </div>
        }

      </form>

      <Loading status={loading}/>

    </Fragment>
  );

}
