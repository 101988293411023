import React, { Fragment } from 'react';
import { Save, PlusCircle, XCircle } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import dayjs from 'dayjs';

// variables
import {
  _partsTransfer,
  _partsTransferShareholders,
  _optLegalStructures,
  _optCanton,
  _optCountry,
  _optGender,
  _optFounderRoles,
  _optTeamsRoles,
  _optSignatures,
} from '../../configuration.js';

// components
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _labelByValue, _itemByValue } from '../../controllers/Helpers';
import { _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep0(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const shareHolderFields = _partsTransferShareholders

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(_partsTransfer)
  const [ formDataShareHolders, setformDataShareHolders ] = React.useState([])
  const [ notaries, setNotaries ] = React.useState([])
  const [ sendEmail, setSendEmail ] = React.useState(false);

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    let s = field.split('.')
    console.log([s[0]])
    setFormData({...formData, [s[0]]:{...formData[s[0]], [s[1]]:data}});
  }


  //
  //
  const _updateFieldShareHolders = (field, data, index) => {
    let newArray = formDataShareHolders
    newArray[index] = {
      ...newArray[index],
      [field]: data
    }
    setformDataShareHolders([...newArray]);
  }


  //
  //
  const _addRepeaterShareHoldersItem = () => {
    let newArray = shareHolderFields
    if(props.data.registrationType==='company'){
      newArray.isTeamMember = true
    }
    setformDataShareHolders(founders => [...formDataShareHolders, newArray])
  }

  //
  //
  const _removeRepeaterShareHoldersItem = (index) => {
    let newArray = formDataShareHolders.filter((x, i)=>{
      return i !== index && x
    });
    setformDataShareHolders(founders => newArray)
  }


  //
  //
  const _submit = async () => {
    props.action('step1',{...formData, shareholders:formDataShareHolders, sendEmail:sendEmail})
  }


  //
  //
  const _rearangeRoles = (_roles = [41,42,5]) => {

    let array = []
    _optFounderRoles.map((item,index)=>{
      if(_roles.includes(item.value)){
        array.push(item)
      }
    })
    return array;
  }



  //
  //
  const _rearangeSignatures = (_items = [3,4,5]) => {

    let array = []
    _optSignatures.map((item,index)=>{
      if(_items.includes(item.value)){
        array.push(item)
      }
    })
    return array;
  }


  //
  //
  // React.useEffect(()=>{
  //   if(formData.capital !== 0 && formData.partsNumbers !== 0){
  //     const partsValue = (formData.capital/formData.partsNumbers)
  //     _updateField('partsValue', partsValue.toFixed(2));
  //     formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
  //       _updateFieldPeoples('totalNumber',partsValue !== 0 && item.actionsNumber !== 0 ? (parseFloat(partsValue)*parseFloat(item.actionsNumber)).toFixed(2) : 0,index)
  //     })
  //   }
  // },[formData.capital, formData.partsNumbers])


  //
  //
  React.useEffect(()=>{


    const getData = async () =>{


        if(props.data.seller && !props.isNew){

          setFormData(props.data)

          setValue('company.name', props.data.company.name)
          setValue('company.legalStructures', props.data.company.legalStructures)
          setValue('company.number', props.data.company.number)
          setValue('company.citySiege', props.data.company.citySiege)
          setValue('company.dateStatus', props.data.company.dateStatus)
          setValue('company.email', props.data.company.email)

          setValue('seller.firstName', props.data.seller.firstName)
          setValue('seller.lastName', props.data.seller.lastName)
          setValue('seller.gender', props.data.seller.gender)
          setValue('seller.address', props.data.seller.address)
          setValue('seller.zipCode', props.data.seller.zipCode)
          setValue('seller.municipality', props.data.seller.municipality)
          setValue('seller.numberPartsSold', props.data.seller.numberPartsSold)
          setValue('seller.pricePartsSold', props.data.seller.pricePartsSold)
          setValue('seller.hasManagementRole', props.data.seller.hasManagementRole)
          setValue('seller.role', props.data.seller.role)
          setValue('seller.signature', props.data.seller.signature)
          setValue('seller.hasChangeRole', props.data.seller.hasChangeRole)
          setValue('seller.newRole', props.data.seller.newRole)
          setValue('seller.newSignature', props.data.seller.newSignature)
          
          setValue('buyer.firstName', props.data.buyer.firstName)
          setValue('buyer.lastName', props.data.buyer.lastName)
          setValue('buyer.gender', props.data.buyer.gender)
          setValue('buyer.address', props.data.buyer.address)
          setValue('buyer.zipCode', props.data.buyer.zipCode)
          setValue('buyer.municipality', props.data.buyer.municipality)
          setValue('buyer.numberPartsSold', props.data.buyer.numberPartsSold)
          setValue('buyer.pricePartsSold', props.data.buyer.pricePartsSold)
          setValue('buyer.hasManagementRole', props.data.buyer.hasManagementRole)
          setValue('buyer.role', props.data.buyer.role)
          setValue('buyer.signature', props.data.buyer.signature)
          setValue('buyer.hasChangeRole', props.data.buyer.hasChangeRole)
          setValue('buyer.newRole', props.data.buyer.newRole)
          setValue('buyer.newSignature', props.data.buyer.newSignature)
          
          setValue('manager.firstName', props.data.manager.firstName)
          setValue('manager.lastName', props.data.manager.lastName)
          setValue('manager.gender', props.data.manager.gender)
          setValue('manager.address', props.data.manager.address)
          setValue('manager.zipCode', props.data.manager.zipCode)
          setValue('manager.municipality', props.data.manager.municipality)
          setValue('manager.numberPartsSold', props.data.manager.numberPartsSold)
          setValue('manager.pricePartsSold', props.data.manager.pricePartsSold)
          setValue('manager.hasManagementRole', props.data.manager.hasManagementRole)
          setValue('manager.role', props.data.manager.role)
          setValue('manager.signature', props.data.manager.signature)
          setValue('manager.hasChangeRole', props.data.manager.hasChangeRole)
          setValue('manager.newRole', props.data.manager.newRole)
          setValue('manager.newSignature', props.data.manager.newSignature)

        }

        if(props.data.shareholders && !props.isNew){
          if(props.data.shareholders.length>0){
            setformDataShareHolders(props.data.shareholders)
            props.data.shareholders.map((item,index)=>{
              setValue('firstNameF'+index, item.firstName)
              setValue('lastNameF'+index, item.lastName)
              setValue('genderF'+index, item.gender)
              setValue('numberParts'+index, item.numberParts)
              setValue('partsValueF'+index, item.partsValue)
              setValue('roleF'+index, item.role)
            })
          }
        }

        setLoading(false)

      }

    getData()
  },[props.data, props.isNew])


  //
  //
  const _repeaterShareHolderItem = (item, index) => {

    const ___firstName = 'firstNameF'+index
    const ___lastName = 'lastNameF'+index
    const ___gender = 'genderF'+index
    const ___numberParts = 'numberParts'+index
    const ___partsValue = 'partsValueF'+index
    const ___role = 'roleF'+index

    return (
      <div className="repeaterItem" key={index}>
      <div className="row">

        <div className="col-md-6 col-lg-4">
          <div className="form-group">
            <label>First name {props.isShortcodeVisible && <small>{'(firstName)'}</small>}</label>
            <input
              {...register(___firstName, { required: true })}
              type="text"
              name={___firstName}
              value={item.firstName}
              onChange={(e)=>_updateFieldShareHolders('firstName',e.target.value, index)}
              className={`form-control ${errors.___firstName && 'error'}`}
            />
            {errors[___firstName] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="form-group">
            <label>Last name {props.isShortcodeVisible && <small>{'(lastName)'}</small>}</label>
            <input
              {...register(___lastName, { required: true })}
              type="text"
              name={___lastName}
              value={item.lastName}
              onChange={(e)=>_updateFieldShareHolders('lastName',e.target.value, index)}
              className={`form-control ${errors.___lastName && 'error'}`}
            />
            {errors[___lastName] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="form-group">
            <label>Gender {props.isShortcodeVisible && <small>{'(gender)'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={___gender}
                  value={_itemByValue(item.gender, _optGender)}
                  options={_optGender}
                  onChange={(e)=> {field.onChange(e.value); _updateFieldShareHolders('gender',e.value,index)}}
                  className={`form-control p-0 ${errors.___gender && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name={___gender}
            />
            {errors[___gender] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-12">
          <hr/>
        </div>
        
        <div className="col-md-4">
          <div className="form-group">
            <label>Number Parts {props.isShortcodeVisible && <small>{'(numberParts)'}</small>}</label>
            <input
              type="number"
              name={___numberParts}
              value={item.numberParts}
              onChange={(e)=>_updateFieldShareHolders('numberParts',e.target.value,index)}
              className={`form-control`}
            />
            {errors[___numberParts] && <div className="errorField">Required</div>}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Parts Value {props.isShortcodeVisible && <small>{'(partsValue)'}</small>}</label>
            <input
              {...register(___partsValue, { required: true })}
              type="text"
              name={___partsValue}
              value={item.partsValue}
              onChange={(e)=>_updateFieldShareHolders('partsValue',e.target.value,index)}
              className={`form-control ${errors.___partsValue && 'error'}`}
            />
            {errors[___partsValue] && <div className="errorField">Required</div>}
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>Role {props.isShortcodeVisible && <small>{'(role)'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={___role}
                  value={_itemByValue(item.role, _optFounderRoles)}
                  options={_rearangeRoles()}
                  onChange={(e)=> {field.onChange(e.value); _updateFieldShareHolders('role',e.value,index)}}
                  className={`form-control p-0 ${errors.___role && 'error'}`}
                  getOptionLabel={option => option.labelMale}
                />
              }
              control={control}
              rules={{ required: true}}
              name={___role}
            />
            {errors[___role] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        
      </div>
      <div className="options">
        <span>{index+1}</span>
        <button type="button" className="button blank" onClick={()=> _removeRepeaterShareHoldersItem(index)}><XCircle sizes={24} color={'#ff0000'}/></button>
      </div>
      </div>
    )
  }


  React.useEffect(()=>{
    console.log(formData)
  },[formData])


  //
  //
  return (
    <Fragment>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">

      <h3>Company</h3>
      <div className="row">

        <div className="col-md-3">
          <div className="form-group">
            <label>Company name {props.isShortcodeVisible && <small>{'{COMPANY_NAME}'}</small>}</label>
            <input
              {...register("company.name", { required: true })}
              type="text"
              name="company.name"
              value={formData.company.name}
              onChange={(e)=>_updateField('company.name',e.target.value)}
              className={`form-control ${errors['company.name'] && 'error'}`}
            />
            {errors['company.name'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Legal structures {props.isShortcodeVisible && <small>{'{COMPANY_LEGAL_STRUCTURES}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name="company.legalStructures"
                  value={_itemByValue(formData.company.legalStructures, _optLegalStructures)}
                  options={_optLegalStructures}
                  onChange={(e)=> {field.onChange(e.value); _updateField('company.legalStructures',e.value)}}
                  className={`form-control p-0 ${errors['company.legalStructures'] && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name="company.legalStructures"
            />
            {errors['company.legalStructures'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Company No. {props.isShortcodeVisible && <small>{'{COMPANY_NUMBER}'}</small>}</label>
            <input
              {...register("company.number", { required: true })}
              type="text"
              name="company.number"
              value={formData.company.number}
              onChange={(e)=>_updateField('company.number',e.target.value)}
              className={`form-control ${errors['company.number'] && 'error'}`}
            />
            {errors['company.number'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Municipality Siege {props.isShortcodeVisible && <small>{'{COMPANY_CITY_SIEGE}'}</small>}</label>
            <input
              {...register("company.citySiege", { required: true })}
              type="text"
              name="company.citySiege"
              value={formData.company.citySiege}
              onChange={(e)=>_updateField('company.citySiege',e.target.value)}
              className={`form-control ${errors['company.citySiege'] && 'error'}`}
            />
            {errors['company.citySiege'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-3">

            <div className="form-group">
              <label>Date of status {props.isShortcodeVisible && <small>{'{COMPANY_DATE_STATUS}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <DatePicker
                    selected={formData.company.dateStatus ? dayjs.tz(formData.company.dateStatus).toDate() : formData.company.dateStatus}
                    onChange={(date)=> {field.onChange(date); _updateField('company.dateStatus',date)}}
                    name={'dateStatus'}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    dateFormat="dd.MM.yyyy"
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'company.dateStatus'}
              />
              {errors['company.dateStatus'] && <div className="errorField">This field is required</div>}
            </div>

        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Email {props.isShortcodeVisible && <small>{'{COMPANY_EMAIL}'}</small>}</label>
            <input
              {...register("company.email", { required: true })}
              type="text"
              name="company.email"
              value={formData.company.email}
              onChange={(e)=>_updateField('company.email',e.target.value)}
              className={`form-control ${errors['company.email'] && 'error'}`}
            />
            {errors['company.email'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-12">
          <hr/>
        </div>

      </div>


      <h3>Seller</h3>
      <div className="row">

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>First name {props.isShortcodeVisible && <small>{'{SELLER_SURNAME}'}</small>}</label>
            <input
              {...register("seller.firstName", { required: true })}
              type="text"
              name="seller.firstName"
              value={formData.seller.firstName}
              onChange={(e)=>_updateField('seller.firstName',e.target.value)}
              className={`form-control ${errors['seller.firstName'] && 'error'}`}
            />
            {errors['seller.firstName'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Last name {props.isShortcodeVisible && <small>{'{SELLER_LASTNAME}'}</small>}</label>
            <input
              {...register("seller.lastName", { required: true })}
              type="text"
              name="seller.lastName"
              value={formData.seller.lastName}
              onChange={(e)=>_updateField('seller.lastName',e.target.value)}
              className={`form-control ${errors['seller.lastName'] && 'error'}`}
            />
            {errors['seller.lastName'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Gender {props.isShortcodeVisible && <small>{'{SELLER_GENDER}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name="seller.gender"
                  value={_itemByValue(formData.seller.gender, _optGender)}
                  options={_optGender}
                  onChange={(e)=> {field.onChange(e.value); _updateField('seller.gender',e.value)}}
                  className={`form-control p-0 ${errors['seller.gender'] && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name="seller.gender"
            />
            {errors['seller.gender'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'{SELLER_ADDRESS}'}</small>}</label>
            <input
              {...register("seller.address", { required: true })}
              type="text"
              name="seller.address"
              value={formData.seller.address}
              onChange={(e)=>_updateField('seller.address',e.target.value)}
              className={`form-control ${errors['seller.address'] && 'error'}`}
            />
            {errors['seller.address'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'{SELLER_ZIPCODE}'}</small>}</label>
            <input
              {...register("seller.zipCode", { required: true })}
              type="text"
              name="seller.zipCode"
              value={formData.seller.zipCode}
              onChange={(e)=>_updateField('seller.zipCode',e.target.value)}
              className={`form-control ${errors['seller.zipCode'] && 'error'}`}
            />
            {errors['seller.zipCode'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Municipality {props.isShortcodeVisible && <small>{'{SELLER_MUNICIPALITY}'}</small>}</label>
            <input
              {...register("seller.municipality", { required: true })}
              type="text"
              name="seller.municipality"
              value={formData.seller.municipality}
              onChange={(e)=>_updateField('seller.municipality',e.target.value)}
              className={`form-control ${errors['seller.municipality'] && 'error'}`}
            />
            {errors['seller.municipality'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Number Parts Sold {props.isShortcodeVisible && <small>{'{SELLER_NUMBER_PARTS_SOLD}'}</small>}</label>
            <input
              {...register("seller.numberPartsSold", { required: true })}
              type="text"
              name="seller.numberPartsSold"
              value={formData.seller.numberPartsSold}
              onChange={(e)=>_updateField('seller.numberPartsSold',e.target.value)}
              className={`form-control ${errors['seller.numberPartsSold'] && 'error'}`}
            />
            {errors['seller.numberPartsSold'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Price Parts Sold {props.isShortcodeVisible && <small>{'{SELLER_PRICE_PARTS_SOLD}'}</small>}</label>
            <input
              {...register("seller.pricePartsSold", { required: true })}
              type="text"
              name="seller.pricePartsSold"
              value={formData.seller.pricePartsSold}
              onChange={(e)=>_updateField('seller.pricePartsSold',e.target.value)}
              className={`form-control ${errors['seller.pricePartsSold'] && 'error'}`}
            />
            {errors['seller.pricePartsSold'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 pt-1">
          <div className="switch my-4">
            <input
              type="checkbox"
              name="seller.hasManagementRole"
              value="1"
              checked={formData.seller.hasManagementRole}
              onChange={(e)=>_updateField('seller.hasManagementRole', !formData.seller.hasManagementRole)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Does the seller had a management role? | <strong>{formData.seller.hasManagementRole ? 'YES' : 'NO'}</strong></label>
          </div>
        </div>
        {formData.seller.hasManagementRole && 
          <>

            <div className="col-md-3">
              <div className="form-group">
                <label>Role {props.isShortcodeVisible && <small>{'{SELLER_ROLE}'}</small>}</label>
                <Controller
                render={
                    ({ field }) =>
                    <Select
                    name={'seller.role'}
                    value={_itemByValue(formData.seller.role, _optFounderRoles)}
                    options={_rearangeRoles()}
                    onChange={(e)=> {field.onChange(e.value); _updateField('seller.role',e.value)}}
                    className={`form-control p-0 ${errors['seller.role'] && 'error'}`}
                    getOptionLabel={option => option.labelMale}
                    />
                }
                control={control}
                rules={{ required: true}}
                name={'seller.role'}
                />
                {errors['seller.role'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>Signature {props.isShortcodeVisible && <small>{'{SELLER_SIGNATURE}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={'seller.signature'}
                      value={_itemByValue(formData.seller.signature, _optSignatures)}
                      options={_rearangeSignatures()}
                      onChange={(e)=> {field.onChange(e.value); _updateField('seller.signature',e.value)}}
                      className={`form-control p-0 ${errors['seller.signature'] && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={'seller.signature'}
                />
                {errors['seller.signature'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

          </>
        }

        <div className="col-12"></div>

        <div className="col-md-6 pt-1">
          <div className="switch my-4">
            <input
              type="checkbox"
              name="seller.hasChangeRole"
              value="1"
              checked={formData.seller.hasChangeRole}
              onChange={(e)=>_updateField('seller.hasChangeRole', !formData.seller.hasChangeRole)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Does the seller change role? | <strong>{formData.seller.hasChangeRole ? 'YES' : 'NO'}</strong></label>
          </div>
        </div>
        {formData.seller.hasChangeRole && 
          <>

            <div className="col-md-3">
              <div className="form-group">
                <label>New Role {props.isShortcodeVisible && <small>{'{SELLER_NEW_ROLE}'}</small>}</label>
                <Controller
                render={
                    ({ field }) =>
                    <Select
                    name={'seller.newRole'}
                    value={_itemByValue(formData.seller.newRole, _optFounderRoles)}
                    options={_rearangeRoles()}
                    onChange={(e)=> {field.onChange(e.value); _updateField('seller.newRole',e.value)}}
                    className={`form-control p-0 ${errors['seller.newRole'] && 'error'}`}
                    getOptionLabel={option => option.labelMale}
                    />
                }
                control={control}
                rules={{ required: true}}
                name={'seller.newRole'}
                />
                {errors['seller.newRole'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>New Signature {props.isShortcodeVisible && <small>{'{SELLER_NEW_SIGNATURE}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={'seller.newSignature'}
                      value={_itemByValue(formData.seller.newSignature, _optSignatures)}
                      options={_rearangeSignatures()}
                      onChange={(e)=> {field.onChange(e.value); _updateField('seller.newSignature',e.value)}}
                      className={`form-control p-0 ${errors['seller.newSignature'] && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={'seller.newSignature'}
                />
                {errors['seller.newSignature'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

          </>
        }

        
        
        <div className="col-md-12">
          <hr/>
        </div>

      </div>


      <h3>Buyer</h3>
      <div className="row">

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>First name {props.isShortcodeVisible && <small>{'{BUYER_SURNAME}'}</small>}</label>
            <input
              {...register("buyer.firstName", { required: true })}
              type="text"
              name="buyer.firstName"
              value={formData.buyer.firstName}
              onChange={(e)=>_updateField('buyer.firstName',e.target.value)}
              className={`form-control ${errors['buyer.firstName'] && 'error'}`}
            />
            {errors['buyer.firstName'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Last name {props.isShortcodeVisible && <small>{'{BUYER_LASTNAME}'}</small>}</label>
            <input
              {...register("buyer.lastName", { required: true })}
              type="text"
              name="buyer.lastName"
              value={formData.buyer.lastName}
              onChange={(e)=>_updateField('buyer.lastName',e.target.value)}
              className={`form-control ${errors['buyer.lastName'] && 'error'}`}
            />
            {errors['buyer.lastName'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Gender {props.isShortcodeVisible && <small>{'{BUYER_GENDER}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name="buyer.gender"
                  value={_itemByValue(formData.buyer.gender, _optGender)}
                  options={_optGender}
                  onChange={(e)=> {field.onChange(e.value); _updateField('buyer.gender',e.value)}}
                  className={`form-control p-0 ${errors['buyer.gender'] && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name="buyer.gender"
            />
            {errors['buyer.gender'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'{BUYER_ADDRESS}'}</small>}</label>
            <input
              {...register("buyer.address", { required: true })}
              type="text"
              name="buyer.address"
              value={formData.buyer.address}
              onChange={(e)=>_updateField('buyer.address',e.target.value)}
              className={`form-control ${errors['buyer.address'] && 'error'}`}
            />
            {errors['buyer.address'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'{BUYER_ZIPCODE}'}</small>}</label>
            <input
              {...register("buyer.zipCode", { required: true })}
              type="text"
              name="buyer.zipCode"
              value={formData.buyer.zipCode}
              onChange={(e)=>_updateField('buyer.zipCode',e.target.value)}
              className={`form-control ${errors['buyer.zipCode'] && 'error'}`}
            />
            {errors['buyer.zipCode'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Municipality {props.isShortcodeVisible && <small>{'{BUYER_MUNICIPALITY}'}</small>}</label>
            <input
              {...register("buyer.municipality", { required: true })}
              type="text"
              name="buyer.municipality"
              value={formData.buyer.municipality}
              onChange={(e)=>_updateField('buyer.municipality',e.target.value)}
              className={`form-control ${errors['buyer.municipality'] && 'error'}`}
            />
            {errors['buyer.municipality'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Number Parts Sold {props.isShortcodeVisible && <small>{'{BUYER_NUMBER_PARTS_SOLD}'}</small>}</label>
            <input
              {...register("buyer.numberPartsSold", { required: true })}
              type="text"
              name="buyer.numberPartsSold"
              value={formData.buyer.numberPartsSold}
              onChange={(e)=>_updateField('buyer.numberPartsSold',e.target.value)}
              className={`form-control ${errors['buyer.numberPartsSold'] && 'error'}`}
            />
            {errors['buyer.numberPartsSold'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Price Parts Sold {props.isShortcodeVisible && <small>{'{BUYER_PRICE_PARTS_SOLD}'}</small>}</label>
            <input
              {...register("buyer.pricePartsSold", { required: true })}
              type="text"
              name="buyer.pricePartsSold"
              value={formData.buyer.pricePartsSold}
              onChange={(e)=>_updateField('buyer.pricePartsSold',e.target.value)}
              className={`form-control ${errors['buyer.pricePartsSold'] && 'error'}`}
            />
            {errors['buyer.pricePartsSold'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 pt-1">
          <div className="switch my-4">
            <input
              type="checkbox"
              name="buyer.hasManagementRole"
              value="1"
              checked={formData.buyer.hasManagementRole}
              onChange={(e)=>_updateField('buyer.hasManagementRole', !formData.buyer.hasManagementRole)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Does the buyer had a management role? | <strong>{formData.buyer.hasManagementRole ? 'YES' : 'NO'}</strong></label>
          </div>
        </div>
        {formData.buyer.hasManagementRole && 
          <>

            <div className="col-md-3">
              <div className="form-group">
                <label>Role {props.isShortcodeVisible && <small>{'{BUYER_ROLE}'}</small>}</label>
                <Controller
                render={
                    ({ field }) =>
                    <Select
                    name={'buyer.role'}
                    value={_itemByValue(formData.buyer.role, _optFounderRoles)}
                    options={_rearangeRoles()}
                    onChange={(e)=> {field.onChange(e.value); _updateField('buyer.role',e.value)}}
                    className={`form-control p-0 ${errors['buyer.role'] && 'error'}`}
                    getOptionLabel={option => option.labelMale}
                    />
                }
                control={control}
                rules={{ required: true}}
                name={'buyer.role'}
                />
                {errors['buyer.role'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>Signature {props.isShortcodeVisible && <small>{'BUYER_SIGNATURE}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={'buyer.signature'}
                      value={_itemByValue(formData.buyer.signature, _optSignatures)}
                      options={_rearangeSignatures()}
                      onChange={(e)=> {field.onChange(e.value); _updateField('buyer.signature',e.value)}}
                      className={`form-control p-0 ${errors['buyer.signature'] && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={'buyer.signature'}
                />
                {errors['buyer.signature'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

          </>
        }

        <div className="col-12"></div>

        <div className="col-md-6 pt-1">
          <div className="switch my-4">
            <input
              type="checkbox"
              name="buyer.hasChangeRole"
              value="1"
              checked={formData.buyer.hasChangeRole}
              onChange={(e)=>_updateField('buyer.hasChangeRole', !formData.buyer.hasChangeRole)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Does the buyer change role? | <strong>{formData.buyer.hasChangeRole ? 'YES' : 'NO'}</strong></label>
          </div>
        </div>
        {formData.buyer.hasChangeRole && 
          <>

            <div className="col-md-3">
              <div className="form-group">
                <label>New Role {props.isShortcodeVisible && <small>{'{BUYER_NEW_ROLE}'}</small>}</label>
                <Controller
                render={
                    ({ field }) =>
                    <Select
                    name={'buyer.newRole'}
                    value={_itemByValue(formData.buyer.newRole, _optFounderRoles)}
                    options={_rearangeRoles()}
                    onChange={(e)=> {field.onChange(e.value); _updateField('buyer.newRole',e.value)}}
                    className={`form-control p-0 ${errors['buyer.newRole'] && 'error'}`}
                    getOptionLabel={option => option.labelMale}
                    />
                }
                control={control}
                rules={{ required: true}}
                name={'buyer.newRole'}
                />
                {errors['buyer.newRole'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label>New Signature {props.isShortcodeVisible && <small>{'{BUYER_NEW_SIGNATURE}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={'buyer.newSignature'}
                      value={_itemByValue(formData.buyer.newSignature, _optSignatures)}
                      options={_rearangeSignatures()}
                      onChange={(e)=> {field.onChange(e.value); _updateField('buyer.newSignature',e.value)}}
                      className={`form-control p-0 ${errors['buyer.newSignature'] && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={'buyer.newSignature'}
                />
                {errors['buyer.newSignature'] && <div className="errorField">This field is required</div>}
              </div>
            </div>

          </>
        }
        
        <div className="col-md-12">
          <hr/>
        </div>

      </div>


      <h3>Manager</h3>
      <div className="row">

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>First name {props.isShortcodeVisible && <small>{'{MANAGER_SURNAME}'}</small>}</label>
            <input
              {...register("manager.firstName", { required: true })}
              type="text"
              name="manager.firstName"
              value={formData.manager.firstName}
              onChange={(e)=>_updateField('manager.firstName',e.target.value)}
              className={`form-control ${errors['manager.firstName'] && 'error'}`}
            />
            {errors['manager.firstName'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Last name {props.isShortcodeVisible && <small>{'{MANAGER_LASTNAME}'}</small>}</label>
            <input
              {...register("manager.lastName", { required: true })}
              type="text"
              name="manager.lastName"
              value={formData.manager.lastName}
              onChange={(e)=>_updateField('manager.lastName',e.target.value)}
              className={`form-control ${errors['manager.lastName'] && 'error'}`}
            />
            {errors['manager.lastName'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            <label>Gender {props.isShortcodeVisible && <small>{'{MANAGER_GENDER}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name="manager.gender"
                  value={_itemByValue(formData.manager.gender, _optGender)}
                  options={_optGender}
                  onChange={(e)=> {field.onChange(e.value); _updateField('manager.gender',e.value)}}
                  className={`form-control p-0 ${errors['manager.gender'] && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name="manager.gender"
            />
            {errors['manager.gender'] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>Signature {props.isShortcodeVisible && <small>{'{MANAGER_SIGNATURE}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name={'manager.signature'}
                      value={_itemByValue(formData.manager.signature, _optSignatures)}
                      options={_rearangeSignatures()}
                      onChange={(e)=> {field.onChange(e.value); _updateField('manager.signature',e.value)}}
                      className={`form-control p-0 ${errors['manager.signature'] && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name={'manager.signature'}
                />
                {errors['manager.signature'] && <div className="errorField">This field is required</div>}
              </div>
            </div>
        
        <div className="col-md-12">
          <hr/>
        </div>

      </div>




      <h3>Shareholders {formDataShareHolders.length>0 && '['+formDataShareHolders.length+']'}</h3>
      {
        formDataShareHolders.length>0 && formDataShareHolders.map((item,index)=>{
          return _repeaterShareHolderItem(item, index)
        })
      }
      <button type="button" onClick={()=> _addRepeaterShareHoldersItem()} className="button dark outline lg ml-1"><PlusCircle/>Add new</button>

        <hr/>

        <div className="d-flex justify-content-between mt-5">
          <div>
            <div className="switch mb-4 mb-md-0">
              <input
                type="checkbox"
                name={'sendEmailStep1'}
                value="1"
                checked={sendEmail}
                onChange={(e)=>setSendEmail(!sendEmail)}
                className={`form-control`}
              />
                <div><i></i></div>
              <label>Send email to client | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
            </div>
          </div>
          <button type="submit" className="button accent lg ml-1"><Save/>Save step</button>
        </div>

      </form>

      <Loading status={loading}/>

    </Fragment>
  );

}
