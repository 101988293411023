import React, { Fragment } from 'react';
import { Save, PlusCircle, XCircle } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'

// variables
import {
    _companyFieldsChangeOwnership,
    _founderFieldsChangeOwnership,
    _oldFieldsChangeOwnership,
    _newFieldsChangeOwnership,
    _actionsChangeOwnership,
  _optLegalStructures,
  _optCanton,
  _optCountry,
  _optGender,
  _optFounderRoles,
  _optTeamsRoles,
  _optSignatures,
} from '../../configuration.js';

// components
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _labelByValue, _itemByValue } from '../../controllers/Helpers';
import { _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep0(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const founderFields = _founderFieldsChangeOwnership

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(_companyFieldsChangeOwnership)
  const [ formOld, setFormOld ] = React.useState(_oldFieldsChangeOwnership)
  const [ formNew, setFormNew ] = React.useState(_newFieldsChangeOwnership)
  const [ formDataPeoples, setformDataPeoples ] = React.useState([])
  const [ notaries, setNotaries ] = React.useState([])
  const [ sendEmail, setSendEmail ] = React.useState(false);

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _updateFieldOld = (field, data) => {
    setFormOld({...formOld, [field]:data});
  }


  //
  //
  const _updateFieldNew = (field, data) => {
    setFormNew({...formNew, [field]:data});
  }


  //
  //
  const _updateFieldPeoples = (field, data, index) => {
    let newArray = formDataPeoples
    newArray[index] = {
      ...newArray[index],
      [field]: data
    }
    setformDataPeoples([...newArray]);
  }


  //
  //
  const _addRepeaterPeopleItem = () => {
    let newArray = founderFields
    if(props.data.registrationType==='company'){
      newArray.isTeamMember = true
    }
    setformDataPeoples(founders => [...formDataPeoples, newArray])
  }

  //
  //
  const _removeRepeaterPeopleItem = (index) => {
    let newArray = formDataPeoples.filter((x, i)=>{
      return i !== index && x
    });
    setformDataPeoples(founders => newArray)
  }


  //
  //
  const _submit = async () => {
    props.action('step1',{organisation:formData, founders:formDataPeoples, oldPerson: formOld, newPerson:formNew, dateStatus:formData.dateStatus, sendEmail:sendEmail})
  }


  //
  //
  const _rearangeFounderRoles = () => {

    let array = []
    const _sarl = [1,2,3,4,5,6]
    const _sa   = [21,22,23,24,25,26,27,28]
    const _team = [41,42,43]
    _optFounderRoles.map((item,index)=>{
      if(formData.legalStructures===1 && _sarl.includes(item.value)){
        array.push(item)
      }else if(formData.legalStructures===2 && _sa.includes(item.value)){
        array.push(item)
      }else{
        array.push(item)  
      }
    })

    // console.log('array',array)
    return array;
  }


  //
  //
  React.useEffect(()=>{
    if(formData.capital !== 0 && formData.partsNumbers !== 0){
      const partsValue = (formData.capital/formData.partsNumbers)
      _updateField('partsValue', partsValue.toFixed(2));
      formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
        _updateFieldPeoples('totalNumber',partsValue !== 0 && item.actionsNumber !== 0 ? (parseFloat(partsValue)*parseFloat(item.actionsNumber)).toFixed(2) : 0,index)
      })
    }
  },[formData.capital, formData.partsNumbers])


  //
  //
  React.useEffect(()=>{


    const getData = async () =>{

      const res = await _apiGETmulti('/notary/query', {limit:1000, clientId: auth.response.clientId})
      if(!res){
        setNotaries([])
      }else{
        let newNotaryArray = [];
        res.data && res.data.map((item,index)=>{
          newNotaryArray.push({value:item._id, label:item.info.firstName+' '+item.info.lastName+' ('+item.info.agentFirstName+' '+item.info.agentLastName+')'})
          return null
        })
        setNotaries(newNotaryArray)


        if(props.data.organisation && !props.isNew){
          setFormData(props.data.organisation)
            // console.log(props.data)
          setValue('firstName', props.data.organisation.firstName)
          setValue('lastName', props.data.organisation.lastName)
          setValue('email', props.data.organisation.email)
          setValue('notaryId', props.data.organisation.notaryId)
          setValue('companyName', props.data.organisation.companyName)
          setValue('legalStructures', props.data.organisation.legalStructures)
          setValue('address', props.data.organisation.address)
          setValue('zipCode', props.data.organisation.zipCode)
          setValue('citySiege', props.data.organisation.citySiege)
          setValue('canton', props.data.organisation.canton)
          setValue('action', props.data.organisation.action)
          setValue('capital', props.data.organisation.capital)
          setValue('dateStatus', props.data.dateStatus)
        }

        if(props.data.oldPerson && !props.isNew){
          setFormOld(props.data.oldPerson)
            // console.log(props.data)
          setValue('oldFirstName', props.data.oldPerson.firstName)
          setValue('oldLastName', props.data.oldPerson.lastName)
          setValue('oldNationality', props.data.oldPerson.nationality)
          setValue('oldOriginCanton', props.data.oldPerson.originCanton)
          setValue('oldAddress', props.data.oldPerson.address)
          setValue('oldZipCode', props.data.oldPerson.zipCode)
          setValue('oldCountry', props.data.oldPerson.country)
          setValue('oldCanton', props.data.oldPerson.canton)
          setValue('oldRole', props.data.oldPerson.role)
          setValue('oldIsFounder', props.data.oldPerson.isFounder)
        }

        if(props.data.newPerson && !props.isNew){
          setFormNew(props.data.newPerson)
            // console.log(props.data)
          setValue('newFirstName', props.data.newPerson.firstName)
          setValue('newLastName', props.data.newPerson.lastName)
          setValue('newNationality', props.data.newPerson.nationality)
          setValue('newOriginCanton', props.data.newPerson.originCanton)
          setValue('newAddress', props.data.newPerson.address)
          setValue('newZipCode', props.data.newPerson.zipCode)
          setValue('newCountry', props.data.newPerson.country)
          setValue('newCanton', props.data.newPerson.canton)
          setValue('newRole', props.data.newPerson.role)
          setValue('newIsFounder', props.data.newPerson.isFounder)
          setValue('newSignature', props.data.newPerson.signature)
        }

        if(props.data.founders && !props.isNew){
          if(props.data.founders.length>0){
            setformDataPeoples(props.data.founders)
            props.data.founders.map((item,index)=>{
              setValue('titleF'+index, item.title)
              setValue('firstNameF'+index, item.firstName)
              setValue('lastNameF'+index, item.lastName)
              setValue('totalNumberF'+index, item.totalNumber)
              setValue('totalValueF'+index, item.totalValue)
            })
          }
        }
        setLoading(false)

      }
    }

    getData()
  },[props.data, props.isNew])


  //
  //
  const _repeaterFoundersItem = (item, index) => {

    const ___title = 'titleF'+index
    const ___firstName = 'firstNameF'+index
    const ___lastName = 'lastNameF'+index
    const ___totalNumber = 'totalNumberF'+index
    const ___totalValue = 'totalValue'+index

    const ___isPresident = 'isPresidentF'+index
    const ___isSecretary = 'isSecretaryF'+index

    return (
      <div className="repeaterItem" key={index}>
      <div className="row">

      <div className="col-md-6 col-lg-4">
          <div className="form-group">
            <label>Title {props.isShortcodeVisible && <small>{'(title)'}</small>}</label>
            <input
              {...register(___title, { required: true })}
              type="text"
              name={___title}
              value={item.title}
              onChange={(e)=>_updateFieldPeoples('title',e.target.value, index)}
              className={`form-control ${errors.___title && 'error'}`}
            />
            {errors[___title] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="form-group">
            <label>First name {props.isShortcodeVisible && <small>{'(firstName)'}</small>}</label>
            <input
              {...register(___firstName, { required: true })}
              type="text"
              name={___firstName}
              value={item.firstName}
              onChange={(e)=>_updateFieldPeoples('firstName',e.target.value, index)}
              className={`form-control ${errors.___firstName && 'error'}`}
            />
            {errors[___firstName] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="form-group">
            <label>Last name {props.isShortcodeVisible && <small>{'(lastName)'}</small>}</label>
            <input
              {...register(___lastName, { required: true })}
              type="text"
              name={___lastName}
              value={item.lastName}
              onChange={(e)=>_updateFieldPeoples('lastName',e.target.value, index)}
              className={`form-control ${errors.___lastName && 'error'}`}
            />
            {errors[___lastName] && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-12">
          <hr/>
        </div>
        
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Total of parts {props.isShortcodeVisible && <small>{'(totalNumber)'}</small>}</label>
                <input
                  type="number"
                  name={___totalNumber}
                  value={item.totalNumber}
                  onChange={(e)=>_updateFieldPeoples('totalNumber',e.target.value,index)}
                  className={`form-control`}
                />
                {errors[___totalNumber] && <div className="errorField">Required</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Value part {props.isShortcodeVisible && <small>{'(totalValue)'}</small>}</label>
                <input
                  {...register(___totalValue, { required: true })}
                  type="text"
                  name={___totalValue}
                  value={item.totalValue}
                  onChange={(e)=>_updateFieldPeoples('totalValue',e.target.value,index)}
                  className={`form-control ${errors.___totalValue && 'error'}`}
                />
                {errors[___totalValue] && <div className="errorField">Required</div>}
              </div>
            </div>
          </div>
        </div>

        
        <div className="col-md-3 pt-3">
          <div className="switch my-4">
            <input
              type="checkbox"
              name={___isPresident}
              value="1"
              checked={item.isPresident}
              onChange={(e)=>_updateFieldPeoples('isPresident', !item.isPresident, index)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Is President? | <strong>{item.isPresident ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(isPresident)'}</small>}</label>
          </div>
        </div>

        <div className="col-md-3 pt-3">
          <div className="switch my-4">
            <input
              type="checkbox"
              name={___isSecretary}
              value="1"
              checked={item.isSecretary}
              onChange={(e)=>_updateFieldPeoples('isSecretary', !item.isSecretary, index)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Is Secretary? | <strong>{item.isSecretary ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(isPresident)'}</small>}</label>
          </div>
        </div>
        
      </div>
      <div className="options">
        <span>{index+1}</span>
        <button type="button" className="button blank" onClick={()=> _removeRepeaterPeopleItem(index)}><XCircle sizes={24} color={'#ff0000'}/></button>
      </div>
      </div>
    )
  }


  //
  //
  return (
    <Fragment>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">

      <h3>Company info</h3>
      <div className="row">

      <div className="col-md-4">
          <div className="form-group">
            <label>First name</label>
            <input
              {...register("firstName", { required: true })}
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={(e)=>_updateField('firstName',e.target.value)}
              className={`form-control ${errors.firstName && 'error'}`}
            />
            {errors.firstName && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>Last name</label>
            <input
              {...register("lastName", { required: true })}
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={(e)=>_updateField('lastName',e.target.value)}
              className={`form-control ${errors.lastName && 'error'}`}
            />
            {errors.lastName && <div className="errorField">This field is required</div>}
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>Email</label>
            <input
              {...register("email", { required: true })}
              type="text"
              name="email"
              value={formData.email}
              onChange={(e)=>_updateField('email',e.target.value)}
              className={`form-control ${errors.email && 'error'}`}
            />
            {errors.email && <div className="errorField">This field is required</div>}
          </div>
        </div>
        
        <div className="col-md-12">
          <hr/>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Legal structures {props.isShortcodeVisible && <small>{'{COMPANY_LEGAL_STRUCTURES}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name="legalStructures"
                  value={_itemByValue(formData.legalStructures, _optLegalStructures)}
                  options={_optLegalStructures}
                  onChange={(e)=> {field.onChange(e.value); _updateField('legalStructures',e.value)}}
                  className={`form-control p-0 ${errors.legalStructures && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name="legalStructures"
            />
            {errors.legalStructures && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Company name {props.isShortcodeVisible && <small>{'{COMPANY_NAME}'}</small>}</label>
            <input
              {...register("companyName", { required: true })}
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={(e)=>_updateField('companyName',e.target.value)}
              className={`form-control ${errors.companyName && 'error'}`}
            />
            {errors.companyName && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-8">
          <div className="form-group">
            <label>Address {props.isShortcodeVisible && <small>{'{COMPANY_ADDRESS}'}</small>}</label>
            <input
              {...register("address", { required: true })}
              type="text"
              name="address"
              value={formData.address}
              onChange={(e)=>_updateField('address',e.target.value)}
              className={`form-control ${errors.address && 'error'}`}
            />
            {errors.address && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Municipality Siege {props.isShortcodeVisible && <small>{'{COMPANY_CITY_SIEGE}'}</small>}</label>
            <input
              {...register("citySiege", { required: true })}
              type="text"
              name="citySiege"
              value={formData.citySiege}
              onChange={(e)=>_updateField('citySiege',e.target.value)}
              className={`form-control ${errors.citySiege && 'error'}`}
            />
            {errors.citySiege && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Zip code {props.isShortcodeVisible && <small>{'{COMPANY_ZIPCODE}'}</small>}</label>
            <input
              {...register("zipCode", { required: true })}
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={(e)=>_updateField('zipCode',e.target.value)}
              className={`form-control ${errors.zipCode && 'error'}`}
            />
            {errors.zipCode && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Canton {props.isShortcodeVisible && <small>{'{COMPANY_CANTON}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={'canton'}
                  value={_itemByValue(formData.canton, _optCanton)}
                  options={_optCanton}
                  onChange={(e)=> {field.onChange(e.value); _updateField('canton',e.value)}}
                  className={`form-control p-0 ${errors.canton && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name={'canton'}
            />
            {errors.canton && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Action {props.isShortcodeVisible && <small>{'{COMPANY_ACTION}'}</small>}</label>
            <Controller
              render={
                ({ field }) =>
                <Select
                  name={'action'}
                  value={_itemByValue(formData.action, _actionsChangeOwnership)}
                  options={_actionsChangeOwnership}
                  onChange={(e)=> {field.onChange(e.value); _updateField('action',e.value)}}
                  className={`form-control p-0 ${errors.action && 'error'}`}
                />
              }
              control={control}
              rules={{ required: true}}
              name={'action'}
            />
            {errors.action && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-3">

            <div className="form-group">
              <label>Date of status {props.isShortcodeVisible && <small>{'{COMPANY_DATE_STatus}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <DatePicker
                    selected={formData.dateStatus ? new Date(formData.dateStatus) : formData.dateStatus}
                    onChange={(date)=> {field.onChange(date); _updateField('dateStatus',date)}}
                    name={'dateStatus'}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    dateFormat="dd.MM.yyyy"
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'dateStatus'}
              />
              {errors.dateStatus && <div className="errorField">This field is required</div>}
            </div>

          </div>
        <div className="col-md-12">
          <hr/>
        </div>

        

        <div className="col-md-12">
            <div className="form-group">
              <label>Notary {props.isShortcodeVisible && <small>{'{COMPANY_NOTARY}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <Select
                    name={'notaryId'}
                    value={_itemByValue(formData.notaryId, notaries)}
                    options={notaries}
                    onChange={(e)=> {field.onChange(e.value); _updateField('notaryId',e.value)}}
                    className={`form-control p-0 ${errors.notaryId && 'error'}`}
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'notaryId'}
              />
              {errors.notaryId && <div className="errorField">This field is required</div>}
            </div>
        </div>
        <div className="col-md-12">
          <hr/>
        </div>

      </div>
    
      <div className="row">
            
            <div className="col-lg-6">
                <h3>Old person</h3>
                <div className="row">

                    <div className="col-md-6">
                    <div className="form-group">
                        <label>First name {props.isShortcodeVisible && <small>{'(firstName)'}</small>}</label>
                        <input
                        {...register('oldFirstName', { required: true })}
                        type="text"
                        name={'oldFirstName'}
                        value={formOld.firstName}
                        onChange={(e)=>_updateFieldOld('firstName',e.target.value)}
                        className={`form-control ${errors.newFisrtName && 'error'}`}
                        />
                        {errors.oldFirstName && <div className="errorField">This field is required</div>}
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label>Last name {props.isShortcodeVisible && <small>{'(lastName)'}</small>}</label>
                        <input
                        {...register('oldLastName', { required: true })}
                        type="text"
                        name={'oldLastName'}
                        value={formOld.lastName}
                        onChange={(e)=>_updateFieldOld('lastName',e.target.value)}
                        className={`form-control ${errors.oldLastName && 'error'}`}
                        />
                        {errors.oldLastName && <div className="errorField">This field is required</div>}
                    </div>
                    </div>

                    <div className="col-md-12">
                    <hr/>
                    </div>

                    <div className="col-md-9">
                    <div className="form-group">
                        <label>Address {props.isShortcodeVisible && <small>{'(address)'}</small>}</label>
                        <input
                        {...register('oldAddress', { required: true })}
                        type="text"
                        name={'oldAddress'}
                        value={formOld.address}
                        onChange={(e)=>_updateFieldOld('address',e.target.value)}
                        className={`form-control ${errors.oldAddress && 'error'}`}
                        />
                        {errors.oldAddress && <div className="errorField">This field is required</div>}
                    </div>
                    </div>
                    <div className="col-md-3">
                    <div className="form-group">
                        <label>Zip code {props.isShortcodeVisible && <small>{'(zipCode)'}</small>}</label>
                        <input
                        {...register('oldZipCode', { required: true })}
                        type="text"
                        name={'oldZipCode'}
                        value={formOld.zipCode}
                        onChange={(e)=>_updateFieldOld('zipCode',e.target.value)}
                        className={`form-control ${errors.oldZipCode && 'error'}`}
                        />
                        {errors.oldZipCode && <div className="errorField">Required</div>}
                    </div>
                    </div>

                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Country of residence {props.isShortcodeVisible && <small>{'(country)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'oldCountry'}
                                value={_itemByValue(formOld.country, _optCountry, 'code')}
                                options={_optCountry}
                                onChange={(e)=> {field.onChange(e.code); _updateFieldOld('country',e.code)}}
                                className={`form-control p-0 ${errors.oldCountry && 'error'}`}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.code}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'oldCountry'}
                            />
                            {errors.oldCountry && <div className="errorField">This field is required</div>}
                        </div>
                        </div>
                        <div className="col-md-6">
                        {formOld.country === 'CH' &&
                        <div className="form-group">
                            <label>Departement {props.isShortcodeVisible && <small>{'(canton)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'oldCanton'}
                                value={_itemByValue(formOld.canton, _optCanton)}
                                options={_optCanton}
                                onChange={(e)=> {field.onChange(e.value); _updateFieldOld('canton',e.value)}}
                                className={`form-control p-0 ${errors.oldCanton && 'error'}`}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'oldCanton'}
                            />
                            {errors.oldCanton && <div className="errorField">This field is required</div>}
                        </div>
                        }
                        </div>
                    </div>
                    </div>

                    <div className="col-md-12">
                    <hr/>
                    </div>

                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Nationality {props.isShortcodeVisible && <small>{'(nationality)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'oldNationality'}
                                value={_itemByValue(formOld.nationality, _optCountry, 'code')}
                                options={_optCountry}
                                onChange={(e)=> {field.onChange(e.code); _updateFieldOld('nationality',e.code)}}
                                className={`form-control p-0 ${errors.oldNationality && 'error'}`}
                                getOptionLabel={option => option.nationality}
                                getOptionValue={option => option.code}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'oldNationality'}
                            />
                            {errors.oldNationality && <div className="errorField">This field is required</div>}
                        </div>
                        </div>
                        <div className="col-md-6">
                        {formOld.nationality === 'CH' &&
                        <div className="form-group">
                            <label>Canton of origin {props.isShortcodeVisible && <small>{'(originCanton)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'oldOriginCanton'}
                                value={_itemByValue(formOld.originCanton, _optCanton)}
                                options={_optCanton}
                                onChange={(e)=> {field.onChange(e.value); _updateFieldOld('originCanton',e.value)}}
                                className={`form-control p-0 ${errors.oldOriginCanton && 'error'}`}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'oldOriginCanton'}
                            />
                            {errors.oldOriginCanton && <div className="errorField">This field is required</div>}
                        </div>
                        }
                        </div>
                    </div>
                    </div>

                    <div className="col-md-12">
                    <hr/>
                    </div>

                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Role {props.isShortcodeVisible && <small>{'(role)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'oldRole'}
                                value={_itemByValue(formOld.role, _optFounderRoles)}
                                options={_rearangeFounderRoles()}
                                onChange={(e)=> {field.onChange(e.value); _updateFieldOld('role',e.value)}}
                                className={`form-control p-0 ${errors.oldRole && 'error'}`}
                                getOptionLabel={option => option.labelMale}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'oldRole'}
                            />
                            {errors.oldRole && <div className="errorField">This field is required</div>}
                        </div>
                        </div>

                    <div className="col-md-6 pt-3">
                    <div className="switch my-4">
                        <input
                        type="checkbox"
                        name={'oldIsFounder'}
                        value="1"
                        checked={formOld.isFounder}
                        onChange={(e)=>_updateFieldOld('isFounder', !formOld.isFounder)}
                        className={`form-control`}
                        />
                        <div><i></i></div>
                        <label>Is Founder | <strong>{formOld.isFounder ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(isFounder)'}</small>}</label>
                    </div>
                    </div>
                </div>

            </div>

            <div className="col-lg-6">
                <h3>New person</h3>
                <div className="row">

                    <div className="col-md-6">
                    <div className="form-group">
                        <label>First name {props.isShortcodeVisible && <small>{'(firstName)'}</small>}</label>
                        <input
                        {...register('newFirstName', { required: true })}
                        type="text"
                        name={'newFirstName'}
                        value={formNew.firstName}
                        onChange={(e)=>_updateFieldNew('firstName',e.target.value)}
                        className={`form-control ${errors.newFisrtName && 'error'}`}
                        />
                        {errors.newFirstName && <div className="errorField">This field is required</div>}
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label>Last name {props.isShortcodeVisible && <small>{'(lastName)'}</small>}</label>
                        <input
                        {...register('newLastName', { required: true })}
                        type="text"
                        name={'newLastName'}
                        value={formNew.lastName}
                        onChange={(e)=>_updateFieldNew('lastName',e.target.value)}
                        className={`form-control ${errors.newLastName && 'error'}`}
                        />
                        {errors.newLastName && <div className="errorField">This field is required</div>}
                    </div>
                    </div>

                    <div className="col-md-12">
                    <hr/>
                    </div>

                    <div className="col-md-9">
                    <div className="form-group">
                        <label>Address {props.isShortcodeVisible && <small>{'(address)'}</small>}</label>
                        <input
                        {...register('newAddress', { required: true })}
                        type="text"
                        name={'newAddress'}
                        value={formNew.address}
                        onChange={(e)=>_updateFieldNew('address',e.target.value)}
                        className={`form-control ${errors.newAddress && 'error'}`}
                        />
                        {errors.newAddress && <div className="errorField">This field is required</div>}
                    </div>
                    </div>
                    <div className="col-md-3">
                    <div className="form-group">
                        <label>Zip code {props.isShortcodeVisible && <small>{'(zipCode)'}</small>}</label>
                        <input
                        {...register('newZipCode', { required: true })}
                        type="text"
                        name={'newZipCode'}
                        value={formNew.zipCode}
                        onChange={(e)=>_updateFieldNew('zipCode',e.target.value)}
                        className={`form-control ${errors.newZipCode && 'error'}`}
                        />
                        {errors.newZipCode && <div className="errorField">Required</div>}
                    </div>
                    </div>

                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Country of residence {props.isShortcodeVisible && <small>{'(country)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'newCountry'}
                                value={_itemByValue(formNew.country, _optCountry, 'code')}
                                options={_optCountry}
                                onChange={(e)=> {field.onChange(e.code); _updateFieldNew('country',e.code)}}
                                className={`form-control p-0 ${errors.newCountry && 'error'}`}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.code}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'newCountry'}
                            />
                            {errors.newCountry && <div className="errorField">This field is required</div>}
                        </div>
                        </div>
                        <div className="col-md-6">
                        {formNew.country === 'CH' &&
                        <div className="form-group">
                            <label>Departement {props.isShortcodeVisible && <small>{'(canton)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'newCanton'}
                                value={_itemByValue(formNew.canton, _optCanton)}
                                options={_optCanton}
                                onChange={(e)=> {field.onChange(e.value); _updateFieldNew('canton',e.value)}}
                                className={`form-control p-0 ${errors.newCanton && 'error'}`}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'newCanton'}
                            />
                            {errors.newCanton && <div className="errorField">This field is required</div>}
                        </div>
                        }
                        </div>
                    </div>
                    </div>

                    <div className="col-md-12">
                    <hr/>
                    </div>

                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Nationality {props.isShortcodeVisible && <small>{'(nationality)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'newNationality'}
                                value={_itemByValue(formNew.nationality, _optCountry, 'code')}
                                options={_optCountry}
                                onChange={(e)=> {field.onChange(e.code); _updateFieldNew('nationality',e.code)}}
                                className={`form-control p-0 ${errors.newNationality && 'error'}`}
                                getOptionLabel={option => option.nationality}
                                getOptionValue={option => option.code}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'newNationality'}
                            />
                            {errors.newNationality && <div className="errorField">This field is required</div>}
                        </div>
                        </div>
                        <div className="col-md-6">
                        {formNew.nationality === 'CH' &&
                        <div className="form-group">
                            <label>Canton of origin {props.isShortcodeVisible && <small>{'(originCanton)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'newOriginCanton'}
                                value={_itemByValue(formNew.originCanton, _optCanton)}
                                options={_optCanton}
                                onChange={(e)=> {field.onChange(e.value); _updateFieldNew('originCanton',e.value)}}
                                className={`form-control p-0 ${errors.newOriginCanton && 'error'}`}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'newOriginCanton'}
                            />
                            {errors.newOriginCanton && <div className="errorField">This field is required</div>}
                        </div>
                        }
                        </div>
                    </div>
                    </div>

                    <div className="col-md-12">
                    <hr/>
                    </div>

                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Role {props.isShortcodeVisible && <small>{'(role)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'newRole'}
                                value={_itemByValue(formNew.role, _optFounderRoles)}
                                options={_rearangeFounderRoles()}
                                onChange={(e)=> {field.onChange(e.value); _updateFieldNew('role',e.value)}}
                                className={`form-control p-0 ${errors.newRole && 'error'}`}
                                getOptionLabel={option => option.labelMale}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'newRole'}
                            />
                            {errors.newRole && <div className="errorField">This field is required</div>}
                        </div>
                        </div>

                    <div className="col-md-6 pt-3">
                    <div className="switch my-4">
                        <input
                        type="checkbox"
                        name={'newIsFounder'}
                        value="1"
                        checked={formNew.isFounder}
                        onChange={(e)=>_updateFieldNew('isFounder', !formNew.isFounder)}
                        className={`form-control`}
                        />
                        <div><i></i></div>
                        <label>Is Founder | <strong>{formNew.isFounder ? 'YES' : 'NO'}</strong>  {props.isShortcodeVisible && <small>{'(isFounder)'}</small>}</label>
                    </div>
                    </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Signature {props.isShortcodeVisible && <small>{'(signature)'}</small>}</label>
                            <Controller
                            render={
                                ({ field }) =>
                                <Select
                                name={'newSignature'}
                                value={_itemByValue(formNew.signature, _optSignatures)}
                                options={_optSignatures}
                                onChange={(e)=> {field.onChange(e.value); _updateFieldNew('signature',e.value)}}
                                className={`form-control p-0 ${errors.newSignature && 'error'}`}
                                />
                            }
                            control={control}
                            rules={{ required: true}}
                            name={'newSignature'}
                            />
                            {errors.newSignature && <div className="errorField">This field is required</div>}
                        </div>
                        </div>
                </div>
            </div>

      </div>

<hr/>

      <h3>Founders {formDataPeoples.length>0 && '['+formDataPeoples.length+']'}</h3>
      {
        formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
          return _repeaterFoundersItem(item, index)
        })
      }
      <button type="button" onClick={()=> _addRepeaterPeopleItem()} className="button dark outline lg ml-1"><PlusCircle/>Add new</button>

        <hr/>

        <div className="d-flex justify-content-between mt-5">
          <div>
            <div className="switch mb-4 mb-md-0">
              <input
                type="checkbox"
                name={'sendEmailStep1'}
                value="1"
                checked={sendEmail}
                onChange={(e)=>setSendEmail(!sendEmail)}
                className={`form-control`}
              />
                <div><i></i></div>
              <label>Send email to client | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
            </div>
          </div>
          <button type="submit" className="button accent lg ml-1"><Save/>Save step #3</button>
        </div>

      </form>

      <Loading status={loading}/>

    </Fragment>
  );

}
