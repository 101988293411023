import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify';


// controllers
import _actions from './redux/actions'
import { _apiLoginStatus } from './Api';
import { inArray } from './Helpers';
import ScrollToTop from './ScrollToTop';


// components
import Loading from '../components/Loading';
import Layout from '../components/Layout';

// pages
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

import Users from '../pages/Users';
import UsersForm from '../pages/Users/form';

import Administrators from '../pages/Administrators';
import AdministratorsForm from '../pages/Administrators/form';

import TemplateDocuments from '../pages/Templates/documents';
import TemplateFormDocument from '../pages/Templates/formDocuments';
import TemplateEmail from '../pages/Templates/emails';
import TemplateFormEmail from '../pages/Templates/formEmails';
import TemplateBanks from '../pages/Templates/banks';
import TemplateFormBanks from '../pages/Templates/formBanks';
import TemplateRegisters from '../pages/Templates/registers';
import TemplateFormRegisters from '../pages/Templates/formRegisters';

import Clients from '../pages/Clients';
import ClientForm from '../pages/Clients/form';

import Invoices from '../pages/Invoices';

import Notary from '../pages/Notary';
import NotaryForm from '../pages/Notary/forms';

import Request from '../pages/Request'; // public form for client protected vwith token
import Requests from '../pages/Requests';
import RequestForms from '../pages/Requests/forms';


import ChangeOwnership from '../pages/ChangeOwnership';
import ChangeOwnershipForms from '../pages/ChangeOwnership/forms';

import ChangeAddress from '../pages/ChangeAddress';
import ChangeAddressForms from '../pages/ChangeAddress/forms';

import ChangeName from '../pages/ChangeName';
import ChangeNameForms from '../pages/ChangeName/forms';

import ChangeGoal from '../pages/ChangeGoal';
import ChangeGoalForms from '../pages/ChangeGoal/forms';

import ChangePartsTransfert from '../pages/PartsTransfert';
import ChangePartsTransfertForms from '../pages/PartsTransfert/forms';

import Page404 from '../pages/404';
import Page403 from '../pages/403';


function PrivateRoute({ children, ...rest }) {

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const permissionLevel = rest.role && auth.status ? inArray(auth.response.role, rest.role) : true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.status ? (
          permissionLevel ?
          children :
          <Redirect
            to={{
              pathname: "/403",
              state: { from: location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


function Router() {

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const [ isLoggedIn, setIsLoggedIn ] = React.useState(auth.status);


  //
  //
  React.useEffect(()=>{

    const getStatus = async () =>{
      const status = await _apiLoginStatus()
      if(!status){
        setIsLoggedIn(false)
        dispatch(_actions.auth.authLogout())
      }else{
        dispatch(_actions.auth.authUpdate({...status}))
        setIsLoggedIn(true)
      }
    }
    if(auth.status){
      getStatus()
    }

    return (() => [getStatus])
  },[auth.status, dispatch])


  //
  //
  return (
    <BrowserRouter basename={'/'}>
      <ScrollToTop />
      <Suspense fallback={<Loading size="large" type="full"/>}>
        <Layout isLoggedIn={isLoggedIn} auth={auth}>

            <Switch>

              {!isLoggedIn &&
                <>
                <Route exact path="/">
                  <Redirect
                    to={{
                      pathname: "/login"
                    }}
                  />
                </Route>
                <Route exact path="/login/">
                  <Login/>
                </Route>
                <Route exact path="/request/:token">
                  <Request/>
                </Route>
                </>
              }


              <PrivateRoute
                exact
                path="/"
                status={isLoggedIn}
              >
                <Dashboard/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/administrators/"
                status={isLoggedIn}
                role={[1,2]}
              >
                <Administrators/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/administrators/:id"
                status={isLoggedIn}
                role={[1,2]}
              >
                <AdministratorsForm/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/clients/"
                status={isLoggedIn}
                role={[1,2]}
              >
                <Clients/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/clients/:id"
                status={isLoggedIn}
                role={[1,2]}
              >
                <ClientForm/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/templates/documents/"
                status={isLoggedIn}
              >
                <TemplateDocuments/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/templates/documents/:id"
                status={isLoggedIn}
              >
                <TemplateFormDocument/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/templates/emails/"
                status={isLoggedIn}
              >
                <TemplateEmail/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/templates/emails/:id"
                status={isLoggedIn}
              >
                <TemplateFormEmail/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/templates/banks/"
                status={isLoggedIn}
                role={[1,2,10,11]}
              >
                <TemplateBanks/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/templates/banks/:id"
                status={isLoggedIn}
                role={[1,2,10,11]}
              >
                <TemplateFormBanks/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/templates/registers/"
                status={isLoggedIn}
                role={[1,2]}
              >
                <TemplateRegisters/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/templates/registers/:id"
                status={isLoggedIn}
                role={[1,2]}
              >
                <TemplateFormRegisters/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/users/"
                status={isLoggedIn}
                role={[10]}
              >
                <Users/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/users/:id"
                status={isLoggedIn}
                role={[10]}
              >
                <UsersForm/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/invoices/"
                status={isLoggedIn}
                role={[10,11]}
              >
                <Invoices/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/notary/"
                status={isLoggedIn}
                role={[10]}
              >
                <Notary/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/notary/:id"
                status={isLoggedIn}
                role={[10,11]}
              >
                <NotaryForm/>
              </PrivateRoute>


              <Route exact path="/requests/">
                  <Redirect
                    to={{
                      pathname: "/requests/created/"
                    }}
                  />
                </Route>


              <PrivateRoute
                exact
                path="/requests/created/"
                status={isLoggedIn}
                role={[10,11]}
              >
                <Requests/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/requests/created/:id"
                status={isLoggedIn}
                role={[10,11]}
              >
                <RequestForms/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/requests/change-ownership/"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeOwnership/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/requests/change-ownership/:id"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeOwnershipForms/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/requests/change-address/"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeAddress/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/requests/change-address/:id"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeAddressForms/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/requests/change-name/"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeName/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/requests/change-name/:id"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeNameForms/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/requests/change-goal/"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeGoal/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/requests/change-goal/:id"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangeGoalForms/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/requests/parts-transfert/"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangePartsTransfert/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/requests/parts-transfert/:id"
                status={isLoggedIn}
                role={[10,11]}
              >
                <ChangePartsTransfertForms/>
              </PrivateRoute>


              <Route exact path="/404">
                <Page404/>
              </Route>
              <Route exact path="/403">
                <Page403/>
              </Route>


              <Route path="*">
                <Redirect
                  to={{
                    pathname: "/404"
                  }}
                />
              </Route>

            </Switch>

          <ToastContainer
            position="top-right"
          />
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;

// <Route exact path="/:permalink">
//   <PageGeneric sidebar={_sidebar}/>
// </Route>
