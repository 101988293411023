import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { GitMerge, PlusCircle, Edit, XCircle, ChevronLeft, ChevronRight } from 'react-feather'
import { Link, useLocation  } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import ReactPaginate from 'react-paginate'

// variables
import { SITENAME, _optRequestStatuses, _optLegalStructures } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import Table from '../../components/Table';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETmulti, _apiDELETE, _apiAvailability } from '../../controllers/Api';
import { _itemByValue, _returnMaxStep, _labelByValue } from '../../controllers/Helpers';


export default function Requests(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());
  const location = useLocation()

  const listStatuses = [{ value:0, label:'All'}, ..._optRequestStatuses]
  const listObjectives = [{ value:0, label:'All'}, ..._optLegalStructures]

  const fields = {
    limit: 50,
    offset: 0,
    orderBy: 'dateCreated|desc',
    search: '',
    dateFrom: '',
    dateTo: '',
    clientId: auth.response.clientId
  }

  const [ reload, setReload ] = React.useState(1);

  const [ loading, setLoading ] = React.useState(true);
  const [ request, setRequest ] = React.useState(location.state ? location.state.request : fields)
  const [ requestAction, setRequestAction ] = React.useState(location.state ? location.state.request : fields)
  const [ total, setTotal ] = React.useState(0)
  const [ data, setData ] = React.useState([])

  const [ isAvailable, setIsAvailable ] = React.useState(false)


  //
  //
  const _paginateAction = (e) => {
    const offset = e.selected === 0 ? 0 : (e.selected*fields.limit)
    _updateField('offset',offset)
  }



  //
  //
  const _updateField = (field, data) => {
    setRequest({...request, [field]:data});
  }


  //
  //
  const _delete = async (id) => {

    if (!window.confirm(`Are you sure you want to delete?`)){
      return;
    }else{

      let route = '/change-parts-transfert/'

      const del = await _apiDELETE(route+id);
      if(del.statusCode !== 200){
        toast.error(del.message);
      }else{
        toast.success(del.message);
        setReload(reload+1)
      }

    }

  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => request !== '' ? setRequestAction(request) : null, 500 );
    return () => clearTimeout(timeoutId);
  }, [request]);


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      const isAvailable = await _apiAvailability('requests', {clientId:requestAction.clientId})
      if(isAvailable){
        setIsAvailable(true)
        const res = await _apiGETmulti('/change-parts-transfert/query', requestAction)
        if(!res){
          setTotal(0)
          setData([])
        }else{
          setTotal(res.total)
          setData(res.data)
        }
      }else{
        setIsAvailable(false)
      }

      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[requestAction, reload])


  //
  //
  const _options = (data, index) => {

    return (
      <div className="d-flex justify-content-end align-items-center">
        <Link to={{pathname:'/requests/parts-transfert/'+data._id, state:{request:request, pathname:location.pathname}}} className="iconButton"><Edit size={20}/></Link>
        <button className="iconButton" onClick={()=>_delete(data._id)}><XCircle size={20}/></button>
      </div>
    )

  }


  //
  //
  const _title0 = (data, index) => {

    const legal = _itemByValue(data.company.legalStructures, _optLegalStructures)

    return (
      <div className="relative">
        <strong>{data.company.name} {legal.label}</strong>
      </div>
    )
  }


  //
  //
  const _title1 = (data, index) => {

    return (
      <div className="relative">
        <strong>{data.seller.firstName} {data.seller.lastName}</strong>
      </div>
    )
  }


  //
  //
  const _title2 = (data, index) => {

    return (
      <div className="relative">
        <strong>{data.buyer.firstName} {data.buyer.lastName}</strong>
      </div>
    )
  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Parts Transfert | { SITENAME }</title>
      </Helmet>

      <PageHead
        title="Parts Transfert"
        icon={<GitMerge size={52}/>}
        bottomOff={true}
      >
        {isAvailable && <Link to={{pathname:'/requests/parts-transfert/new', state:{request:request, pathname:location.pathname}}} className="button accent ml-1"><PlusCircle/>Add new</Link>}
      </PageHead>

      {isAvailable &&
      <div className="card w-100 mb-4 py-2">
        <div className="row">
          <div className="col-lg-3 col-xl-6">
            <div className="row">
              <div className="col-lg-12 col-xl-12">
                <div className="form-group">
                  <label>Text search</label>
                  <input
                    type="text"
                    name="firstName"
                    value={request.search}
                    onChange={(e)=>_updateField('search',e.target.value)}
                    className={`form-control`}
                    placeholder="Company name or client name or email..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xl-3">
            <div className="form-group">
              <label>Date from</label>
              <DatePicker
                selected={request.dateFrom}
                onChange={(date) => _updateField('dateFrom',date)}
                selectsStart
                startDate={request.dateFrom}
                endDate={request.dateTo}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-lg-3 col-xl-3">
            <div className="form-group">
              <label>Date to</label>
              <DatePicker
                selected={request.dateTo}
                onChange={(date) => _updateField('dateTo',date)}
                selectsEnd
                startDate={request.dateFrom}
                endDate={request.dateTo}
                minDate={request.dateFrom}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      }

      <div className="relative w-100 mt-2">
        {!isAvailable && <div className="text-center py-4 text-danger">You need first enter banks and registers to enable "Requests" function</div>}
        {total===0 ?
          <div className="text-center py-4">{loading ? 'loading...' : 'No data available.'}</div>
        :
          <>
          <Table
            action={_updateField}
            perPage={request.limit}
            sortBy={request.orderBy}
            head={
              [
                {name:'Company', width:'30%', sortable:true, type:'function', func:_title0},
                {name:'Seller', width:'20%', sortable:true, type:'function', func:_title1},
                {name:'Buyer', width:'20%', sortable:true, type:'function', func:_title2},
                {name:'Created on', width:'20%', sortable:true, key:'dateCreated', type:'date'},
                {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
              ]
            }
            body={data}
            total={total}
          />
          <ReactPaginate
            previousLabel={<ChevronLeft/>}
            nextLabel={<ChevronRight/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(total/fields.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={_paginateAction}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          </>
        }
        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
