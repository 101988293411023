import React, { Fragment } from 'react';
import { Save } from 'react-feather'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs';

// components
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiPOST, _apiGETone } from '../../controllers/Api';


export default function TimeSheet(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const fields = {
    t1:null,
    t2:null,
    t3:null,
    t4:null,
    t5:null,
    clientId:auth.response.clientId,
    requestId: props.data._id
  }

  const [ loading, setLoading ] = React.useState(false);
  const [ formData, setFormData ] = React.useState(fields)

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [total, setTotal] = React.useState(0);

  const [ rand, setRand ] = React.useState(1);


  //
  //
  const _updateField = (field, data) => {
    const _data = {...formData, [field]:data}
      setFormData(_data);
      _updateTotal(_data)
  }


//   
// 
const _updateTotal = (res) => {
    console.log(res, parseInt(null))
    let _total = 0
    if(res.t1){
        _total = _total+parseInt(res.t1)
    }
    if(res.t2){
        _total = _total+parseInt(res.t2)
    }
    if(res.t3){
        _total = _total+parseInt(res.t3)
    }
    if(res.t4){
        _total = _total+parseInt(res.t4)
    }
    if(res.t5){
        _total = _total+parseInt(res.t5)
    }
    setTotal(_total)
    props.updateTimesheetTotal(_total)
}



  //
  //
  const _submit = async () => {

    setLoading(true);

    const insert = await _apiPOST('/timesheet/', formData)
    if(insert.statusCode !== 200){

      toast.error(insert.message);
      setLoading(false);

    }else{

      // props.updateRand()
      setFormData({
        ...formData,
        message: ''
      })
      toast.success(insert.message);
      setLoading(false);
      setRand(rand+1)

    }

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(props.data._id){
        
        const res = await _apiGETone('/timesheet/'+props.data._id)
        if(res){
          setFormData({
            ...formData,
            requestId: props.data._id,
            ...res
          })
          _updateTotal(res)
        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[props.data, rand])


  //
  //
  return (
    <Fragment>
      <div className="relative w-100">

        <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
        <div className="row">

        <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
                <label>1er Appel</label>
                <input
                    {...register("t1", { required: false })}
                    type="text"
                    name="t1"
                    value={formData.t1}
                    onChange={(e)=>_updateField('t1',e.target.value)}
                    className={`form-control ${errors.t1 && 'error'}`}
                />
                {errors.t1 && <div className="errorField">This field is required</div>}
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
                <label>Préparation documents</label>
                <input
                    {...register("t2", { required: false })}
                    type="text"
                    name="t2"
                    value={formData.t2}
                    onChange={(e)=>_updateField('t2',e.target.value)}
                    className={`form-control ${errors.t2 && 'error'}`}
                />
                {errors.t2 && <div className="errorField">This field is required</div>}
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
                <label>Préparation envoi notaire</label>
                <input
                    {...register("t3", { required: false })}
                    type="text"
                    name="t3"
                    value={formData.t3}
                    onChange={(e)=>_updateField('t3',e.target.value)}
                    className={`form-control ${errors.t3 && 'error'}`}
                />
                {errors.t3 && <div className="errorField">This field is required</div>}
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
                <label>Retour RC</label>
                <input
                    {...register("t4", { required: false })}
                    type="text"
                    name="t4"
                    value={formData.t4}
                    onChange={(e)=>_updateField('t4',e.target.value)}
                    className={`form-control ${errors.t4 && 'error'}`}
                />
                {errors.t4 && <div className="errorField">This field is required</div>}
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
                <label>Divers (communication/modification docs)</label>
                <input
                    {...register("t5", { required: false })}
                    type="text"
                    name="t5"
                    value={formData.t5}
                    onChange={(e)=>_updateField('t5',e.target.value)}
                    className={`form-control ${errors.t5 && 'error'}`}
                />
                {errors.t5 && <div className="errorField">This field is required</div>}
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 text-right pt-5">
            <h3><span className=''>Total:</span> <strong>{total}</strong></h3>
        </div>
        </div>
        <hr/>
        <div className='row'>
        <div className="col-12 col-md-6 col-lg-4 offset-lg-4 text-right text-xl pt-2">
        </div>
        <div className="col-12 col-md-6 col-lg-4">
            <button type="submit" className="button accent fullWidth lg"><Save/>Save</button>
        </div>
        </div>
        </form>

        <hr/>

        <Loading status={loading}/>
      </div>
    </Fragment>
  );

}
