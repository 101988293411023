import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { Users as UserIcon, Save, ChevronLeft, Eye, EyeOff } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';

// variables
import { SITENAME } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';

// controllers
import { _apiGETone, _apiPOST, _apiPUT, _apiAvailability } from '../../controllers/Api';
import { _goBack } from '../../controllers/Helpers';


export default function AdministratorsForm(props){

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const roles = [
    {name:'Super admin', id:1},
    {name:'Admin', id:2},
    {name:'Moderator', id:3}
  ]

  const fields = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    role: 2,
    clientId: null,
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();

  const [ showPass1, setShowPass1 ] = React.useState(false)
  const [ showPass2, setShowPass2 ] = React.useState(false)
  const [ available1, setAvailable1 ] = React.useState(true)
  const [ available2, setAvailable2 ] = React.useState(true)


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone('/users/'+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          setFormData({...res})
          setValue('firstName', res.firstName)
          setValue('lastName', res.lastName)
          setValue('username', res.username)
          setValue('email', res.email)
          setValue('phone', res.phone)
          setValue('role', res.role)
        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[id, setValue])


  //
  //
  const _title = () =>{
    return `Administrators - ${id==='new' ? 'New entry' : 'Update'}`
  }


  //
  //
  const _submit = async () => {

    if(id==='new'){

      delete formData.passwordConfirm

      const insert = await _apiPOST('/users/', formData)
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        history.push('/administrators/')
      }

    }else{

      delete formData.dateCreated
      delete formData.dateUpdated
      delete formData._id

      const update = await _apiPUT('/users/'+id, formData)
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        history.push('/administrators/')
      }

    }

  }



  //
  //
  const _checkAvailability = async(type, value) => {

    const isAvailable = await _apiAvailability(type, value)
    if(isAvailable){
      type==='email' ? setAvailable1(true) : setAvailable2(true)
    }else{
      type==='email' ? setAvailable1(false) : setAvailable2(false)
    }

  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => formData.username !== '' ? _checkAvailability('username',{username:formData.username, id:formData._id}) : null, 1000 );
    return () => clearTimeout(timeoutId);
  }, [formData.username, formData._id]);


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => formData.email !== '' ? _checkAvailability('email',{email:formData.email, id:formData._id}) : null, 1000 );
    return () => clearTimeout(timeoutId);
  }, [formData.email, formData._id]);



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <PageHead
        title={_title()}
        icon={<UserIcon size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
        <button className="button accent lg ml-1" disabled={!available1 && !available2 && formData.username !== '' && formData.email !== '' ? true : false}><Save/>save</button>
      </PageHead>

      <div className="relative w-100">

        <div className="row">
          <div className="col-md-10 col-lg-8">
            <div className="card mt-2 mb-4">
              <div className="row">

                <div className="col-md-6">
                <div className="form-group">
                  <label>First name</label>
                  <input
                    {...register("firstName", { required: true })}
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e)=>_updateField('firstName',e.target.value)}
                    className={`form-control ${errors.firstName && 'error'}`}
                  />
                  {errors.firstName && <div className="errorField">This field is required</div>}
                </div>
                </div>

                <div className="col-md-6">
                <div className="form-group">
                  <label>Last name</label>
                  <input
                    {...register("lastName", { required: true })}
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e)=>_updateField('lastName',e.target.value)}
                    className={`form-control ${errors.lastName && 'error'}`}
                  />
                  {errors.lastName && <div className="errorField">This field is required</div>}
                </div>
                </div>

                <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <div className="relative">
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e)=>_updateField('email',e.target.value)}
                    className={`form-control ${errors.email && 'error'}`}
                  />
                  {!available1 && formData.email !== '' && <div className="errorCheck">Taken</div>}
                  {available1 && formData.email !== '' && <div className="successCheck">Available</div>}
                  </div>
                  {errors.email && <div className="errorField">This field is required</div>}
                </div>
                </div>

                <div className="col-md-6">
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    {...register("phone", { required: true })}
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={(e)=>_updateField('phone',e.target.value)}
                    className={`form-control ${errors.phone && 'error'}`}
                  />
                  {errors.phone && <div className="errorField">This field is required</div>}
                </div>
                </div>

                <div className="col-md-6">
                <div className="form-group">
                  <label>Username</label>
                  <div className="relative">
                  <input
                    {...register("username", { required: true })}
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={(e)=>_updateField('username',e.target.value)}
                    className={`form-control ${errors.username && 'error'}`}
                  />
                  {!available2 && formData.username !== '' && <div className="errorCheck">Taken</div>}
                  {available2 && formData.username !== '' && <div className="successCheck">Available</div>}
                  </div>
                  {errors.username && <div className="errorField">This field is required</div>}
                </div>
                </div>

                <div className="col-md-6">
                <div className="form-group">
                  <label>Role</label>
                  <select
                    {...register("role", { required: true })}
                    name="role"
                    value={formData.role}
                    onChange={(e)=>_updateField('role',e.target.value)}
                    className={`form-control ${errors.role && 'error'}`}
                  >
                    <option value="">Select...</option>
                    {roles.map((item,i)=>{
                      return <option value={item.id} key={i}>{item.name}</option>
                    })}
                  </select>
                  {errors.role && <div className="errorField">This field is required</div>}
                </div>
                </div>

                <div className="col-12"><hr/></div>

                {id !== 'new' && <div className="col-12"><p className="small">If you do't want change password, please leave fields blank.</p></div>}

                <div className="col-md-6">
                <div className="form-group">
                  <label>Password</label>
                  <div className="relative">
                  <input
                    {...register("password", { required: id==='new' ? true : false, minLength: { value: 8, message: 'Password must have at least 8 characters' } })}
                    type={showPass1 ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={(e)=>_updateField('password',e.target.value)}
                    className={`form-control ${errors.password && 'error'}`}
                  />
                  {!showPass1 ? <Eye onClick={()=>{ setShowPass1(true) }} className="appendIcon"/> : <EyeOff onClick={()=>{ setShowPass1(false) }} className="appendIcon"/>}
                  </div>
                  {errors.password && <div className="errorField">This field is required</div>}
                </div>
                </div>

                <div className="col-md-6">
                <div className="form-group">
                  <label>Confirm password</label>
                  <div className="relative">
                  <input
                    {...register("passwordConfirm", { validate: value => value === getValues('password') })}
                    type={showPass2 ? 'text' : 'password'}
                    name="passwordConfirm"
                    value={formData.passwordConfirm}
                    onChange={(e)=>_updateField('passwordConfirm',e.target.value)}
                    className={`form-control ${errors.passwordConfirm && 'error'}`}
                  />
                  {!showPass2 ? <Eye onClick={()=>{ setShowPass2(true) }} className="appendIcon"/> : <EyeOff onClick={()=>{ setShowPass2(false) }} className="appendIcon"/>}
                  </div>
                  {errors.passwordConfirm && <div className="errorField">The passwords do not match</div>}
                </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <Loading status={loading}/>
      </div>
      </form>

    </Fragment>
  );

}
