//
//
const authStatus = () => {
  let auth = localStorage.getItem('Auth');
  if(auth){
    let jsonData = JSON.parse(auth);
    return {
      type: "AUTHSTATUS",
      status: true,
      response: jsonData
    }
  }else{
    return {
      type: "AUTHSTATUS",
      status: false,
      response: null
    }
  }
}


//
//
const authUpdate = (data) => {
  localStorage.setItem('Auth',JSON.stringify(data));
  return {
    type: "AUTHUPDATE",
    status: true,
    response: data
  }
}


//
//
const authLogout = () => {
  localStorage.removeItem('Auth');
  return {
    type: "AUTHLOGOUT",
    status: false,
    response: null
  }
}


const auth = {
  authStatus,
  authUpdate,
  authLogout
};

//
//
export default auth
