import React from 'react';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// services
import {store, persistor} from './controllers/redux/Store'
import Router from './controllers/Router';

// compnents
// import Preloader from './components/Preloader';


function App() {

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault("Europe/Zurich")

  return (
    <Provider store={store}>
      <PersistGate loading={'Loading...'} persistor={persistor}>
        <Router/>
      </PersistGate>
    </Provider>
  );
}

export default App;
