import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { List, Save, ChevronLeft } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import Select from 'react-select'

// variables
import { SITENAME, _optCanton } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import ContentEditorBasic from '../../components/ContentEditor/basic';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPOST, _apiPUT } from '../../controllers/Api';
import { _goBack, _itemByValue } from '../../controllers/Helpers';


export default function TemplateFormRegisters(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const fields = {
    value: '',
    canton: ''
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _handleEditorChange = (content, editor) => {
    setFormData({...formData, value:content})
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    let route = '/list-registers/'

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone(route+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          setFormData({...res})
          setValue('value', res.value)
          setValue('canton', res.canton)
        }
      }
      setLoading(false)
    }
    if(auth.status){
      getData()
    }

    return (() => [getData])
  },[id, auth.status, setValue, auth.response.role])


  //
  //
  const _title = () =>{
    return `Templates / Registers - ${id==='new' ? 'New entry' : 'Update'}`
  }


  //
  //
  const _submit = async () => {

    let route = '/list-registers/'

    if(id==='new'){

      delete formData.passwordConfirm

      const insert = await _apiPOST(route, formData)
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        history.push('/templates/registers/')
      }

    }else{

      delete formData.dateCreated
      delete formData.dateUpdated
      delete formData._id

      const update = await _apiPUT(route+id, formData)
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        history.push('/templates/registers/')
      }

    }

  }


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <PageHead
        title={_title()}
        icon={<List size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
        <button className="button accent lg ml-1"><Save/>save</button>
      </PageHead>

      <div className="relative w-100">

        <div className="row">
          <div className="col-12">

            <div className="form-group">
              <label>Template content</label>
              <ContentEditorBasic
                value={formData.value}
                initialValue={formData.value}
                onEditorChange={_handleEditorChange}
              />
            </div>

            <div className="form-group">
              <label>Assign to canton</label>
              <Controller
                render={
                  ({ field }) =>
                  <Select
                    name={'canton'}
                    value={_itemByValue(formData.canton, _optCanton)}
                    options={_optCanton}
                    onChange={(e)=> {field.onChange(e.value); _updateField('canton',e.value)}}
                    className={`form-control p-0 ${errors.canton && 'error'}`}
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'canton'}
              />
              {errors.canton && <div className="errorField">This field is required</div>}
            </div>

          </div>
        </div>

        <Loading status={loading}/>
      </div>
      </form>

    </Fragment>
  );

}
