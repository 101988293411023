import React, { Fragment } from 'react';
import { Save } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import Select from 'react-select'

// variables
import { _optObjectives, _optCanton, _optCountry, _registrationType } from '../../configuration.js';

// components
import Loading from '../../components/Loading';

// controllers
import { _labelByValue, _itemByValue } from '../../controllers/Helpers';


export default function RequestFormsStep1(props){

  const fields = {
    registrationType: 'personal',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    canton: '',
    objective: ''
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)
  const [ sendEmail, setSendEmail ] = React.useState(false);

  const [ registrationType, setRegistrationType ] = React.useState('personal');

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{
    if(props.data && props.data.start && !props.isNew){
      setFormData(props.data.start)
      setRegistrationType(props.data.registrationType)
      setValue('companyName', props.data.start.companyName)
      setValue('firstName', props.data.start.firstName)
      setValue('lastName', props.data.start.lastName)
      setValue('email', props.data.start.email)
      setValue('phone', props.data.start.phone)
      setValue('address', props.data.start.address)
      setValue('zipCode', props.data.start.zipCode)
      setValue('country', props.data.start.country)
      setValue('canton', props.data.start.canton)
      setValue('objective', props.data.start.objective)
    }
    setLoading(false)
  },[props.data, props.isNew])


  //
  //
  const _submit = async () => {
    props.action('step0',{start:formData, sendEmail:sendEmail, registrationType:registrationType})
  }


  //
  //
  return (
    <Fragment>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <div className="row">
        <div className="col-12">

          {/* <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Registration type</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="registrationType"
                      value={_itemByValue(registrationType, _registrationType)}
                      options={_registrationType}
                      onChange={(e)=> {field.onChange(e.value); setRegistrationType(e.value)}}
                      className={`form-control p-0 ${errors.registrationType && 'error'}`}
                      // isDisabled={props.isNew ? 'true' : 'false'}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="registrationType"
                />
                {errors.registrationType && <div className="errorField">This field is required</div>}
              </div>
            </div>
          </div>

          <hr/> */}

          {/* <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Objective {props.isShortcodeVisible && <small>{'{START_OBJECTIVE}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="objective"
                      value={_itemByValue(formData.objective, _optObjectives)}
                      options={_optObjectives}
                      onChange={(e)=> {field.onChange(e.value); _updateField('objective',e.value)}}
                      className={`form-control p-0 ${errors.objective && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="objective"
                />
                {errors.objective && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Company name {props.isShortcodeVisible && <small>{'{START_COMPANY_NAME}'}</small>}</label>
                <input
                  {...register("companyName", { required: true })}
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={(e)=>_updateField('companyName',e.target.value)}
                  className={`form-control ${errors.companyName && 'error'}`}
                />
                {errors.companyName && <div className="errorField">This field is required</div>}
              </div>
            </div>
          </div> */}

          <hr/>

        </div>
        <div className="col-md-6">

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>{props.auth.response.accountType && props.auth.response.accountType==='saas' && 'Contact'} First name {props.isShortcodeVisible && <small>{'{START_FIRST_NAME}'}</small>}</label>
                <input
                  {...register("firstName", { required: true })}
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={(e)=>_updateField('firstName',e.target.value)}
                  className={`form-control ${errors.firstName && 'error'}`}
                />
                {errors.firstName && <div className="errorField">This field is required</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>{props.auth.response.accountType && props.auth.response.accountType==='saas' && 'Contact'} Last name {props.isShortcodeVisible && <small>{'{START_LAST_NAME}'}</small>}</label>
                <input
                  {...register("lastName", { required: true })}
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e)=>_updateField('lastName',e.target.value)}
                  className={`form-control ${errors.lastName && 'error'}`}
                />
                {errors.lastName && <div className="errorField">This field is required</div>}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>{props.auth.response.accountType && props.auth.response.accountType==='saas' && 'Contact'} Email {props.isShortcodeVisible && <small>{'{START_EMAIL}'}</small>}</label>
            <input
              {...register("email", { required: true })}
              type="text"
              name="email"
              value={formData.email}
              onChange={(e)=>_updateField('email',e.target.value)}
              className={`form-control ${errors.email && 'error'}`}
            />
            {errors.email && <div className="errorField">This field is required</div>}
          </div>

          {/* <div className="form-group">
            <label>{props.auth.response.accountType && props.auth.response.accountType==='saas' && 'Contact'} Email {props.isShortcodeVisible && <small>{'{START_EMAIL}'}</small>}</label>
            <input
              {...register("email", { required: true })}
              type="text"
              name="email"
              value={formData.email}
              onChange={(e)=>_updateField('email',e.target.value)}
              className={`form-control ${errors.email && 'error'}`}
            />
            {errors.email && <div className="errorField">This field is required</div>}
          </div>
          <div className="form-group">
            <label>{props.auth.response.accountType && props.auth.response.accountType==='saas' && 'Contact'} Phone {props.isShortcodeVisible && <small>{'{START_PHONE}'}</small>}</label>
            <input
              {...register("phone", { required: true })}
              type="text"
              name="phone"
              value={formData.phone}
              onChange={(e)=>_updateField('phone',e.target.value)}
              className={`form-control ${errors.phone && 'error'}`}
            />
            {errors.phone && <div className="errorField">This field is required</div>}
          </div> */}

        </div>
        <div className="col-md-6">

        <div className="form-group">
            <label>Company name {props.isShortcodeVisible && <small>{'{START_COMPANY_NAME}'}</small>}</label>
            <input
              {...register("companyName", { required: true })}
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={(e)=>_updateField('companyName',e.target.value)}
              className={`form-control ${errors.companyName && 'error'}`}
            />
            {errors.companyName && <div className="errorField">This field is required</div>}
          </div>

          <div className="form-group">
            <label>Phone {props.isShortcodeVisible && <small>{'{START_PHONE}'}</small>}</label>
            <input
              {...register("phone", { required: false })}
              type="text"
              name="phone"
              value={formData.phone}
              onChange={(e)=>_updateField('phone',e.target.value)}
              className={`form-control ${errors.phone && 'error'}`}
            />
            {errors.phone && <div className="errorField">This field is required</div>}
          </div>

        </div>
        {/* <div className="col-md-6">

            <div className="col-md-12">
              <div className="form-group">
                <label>Canton {props.isShortcodeVisible && <small>{'{START_CANTON}'}</small>}</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="canton"
                      value={_itemByValue(formData.canton, _optCanton)}
                      options={_optCanton}
                      onChange={(e)=> {field.onChange(e.value); _updateField('canton',e.value)}}
                      className={`form-control p-0 ${errors.canton && 'error'}`}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="canton"
                  defaultValue={_labelByValue(formData.canton, _optCanton)}
                />
                {errors.canton && <div className="errorField">This field is required</div>}
              </div>
            </div>

        </div> */}
      </div>

      <hr/>


      <div className="d-flex justify-content-between mt-5">
        <div>
          {/* <div className="switch mb-4 mb-md-0">
            <input
              type="checkbox"
              name={'sendEmailStep1'}
              value="1"
              checked={sendEmail}
              onChange={(e)=>setSendEmail(!sendEmail)}
              className={`form-control`}
            />
              <div><i></i></div>
            <label>Send link via email to client | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
          </div> */}
        </div>
        <button className="button accent lg ml-1" disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>{props.isNew ? 'create new request' : 'update request'}</button>
      </div>

      </form>

      <Loading status={loading}/>

    </Fragment>
  );

}
