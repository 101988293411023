import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { Briefcase, Save, ChevronLeft, Eye, EyeOff } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm, Controller  } from "react-hook-form"
import { toast } from 'react-toastify';
import Select from 'react-select'

// variables
import { SITENAME, _accountType } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';

// controllers
import { _apiGETone, _apiPOST, _apiPUT, _apiAvailability } from '../../controllers/Api';
import { _goBack, _itemByValue } from '../../controllers/Helpers';


export default function ClientForm(props){

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const fields = {
    client:{
      company: '',
      accountType: '',
      maxEmployees: 5,
      status:true
    },
    user:{
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      role: 10,
      clientId: null,
    }
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { control, register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();

  const [ showPass1, setShowPass1 ] = React.useState(false)
  const [ showPass2, setShowPass2 ] = React.useState(false)
  const [ available1, setAvailable1 ] = React.useState(true)
  const [ available2, setAvailable2 ] = React.useState(true)

  const [ accountType, setAccountType ] = React.useState('');


  //
  //
  const _updateField = (group, field, data) => {
    setFormData({...formData, [group]:{...formData[group], [field]:data}});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone('/clients/'+id)
          console.log(res)
        if(!res.client){
          toast.error(res.message);
        }else{
          setFormData({...res})
          setValue('firstName', res.user.firstName)
          setValue('lastName', res.user.lastName)
          setValue('username', res.user.username)
          setValue('email', res.user.email)
          setValue('phone', res.user.phone)
          setValue('company', res.client.company)
          setValue('maxEmployees', res.client.maxEmployees)
          setValue('clientId', res.client._id)
        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[id, setValue])


  //
  //
  const _title = () =>{
    return `Clients - ${id==='new' ? 'New entry' : 'Update'}`
  }


  //
  //
  const _submit = async () => {

    if(id==='new'){

      delete formData.passwordConfirm

      console.log('_submit',formData)

      const insert = await _apiPOST('/clients/', formData)
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        history.push('/clients/')
      }

    }else{

      delete formData.dateCreated
      delete formData.dateUpdated
      delete formData._id

      const update = await _apiPUT('/clients/'+id, formData)
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        history.push('/clients/')
      }

    }

  }



  //
  //
  const _checkAvailability = async(type, value) => {

    const isAvailable = await _apiAvailability(type, value)
    if(isAvailable){
      type==='email' ? setAvailable1(true) : setAvailable2(true)
    }else{
      type==='email' ? setAvailable1(false) : setAvailable2(false)
    }

  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => formData.user.username !== '' ? _checkAvailability('username',{username:formData.user.username, id:formData.user._id}) : null, 1000 );
    return () => clearTimeout(timeoutId);
  }, [formData.user.username, formData.user._id]);


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => formData.user.email !== '' ? _checkAvailability('email',{email:formData.user.email, id:formData.user._id}) : null, 1000 );
    return () => clearTimeout(timeoutId);
  }, [formData.user.email, formData.user._id]);


  console.log('errors',errors)
  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <PageHead
        title={_title()}
        icon={<Briefcase size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
        <button className="button accent lg ml-1" disabled={!available1 && !available2 && formData.username !== '' && formData.email !== '' ? true : false}><Save/>save</button>
      </PageHead>

      <div className="relative w-100">

        <div className="row">
          <div className="col-md-7 col-lg-8 pt-3">

            <div className="pb-1"><h4>Company info</h4></div>

            <div className="row pt-2 pb-5">

              <div className="col-6 mt-3">
              <div className="form-group">
                <div className="switch">
                  <input
                    type="checkbox"
                    name="status"
                    value="1"
                    checked={formData.client.status}
                    onChange={(e)=>_updateField('client','status', !formData.client.status)}
                    className={`form-control`}
                  />
                    <div><i></i></div>
                  <label>STATUS | <strong>{formData.client.status ? 'active' : 'inactive'}</strong></label>
                </div>
              </div>
              </div>

              <div className="col-md-6">
              <div className="form-group">
                <label>Account type</label>
                <Controller
                  render={
                    ({ field }) =>
                    <Select
                      name="accountType"
                      value={_itemByValue(formData.client.accountType, _accountType)}
                      options={_accountType}
                      onChange={(e)=> {field.onChange(e.value); _updateField('client','accountType', e.value)}}
                      className={`form-control p-0 ${errors.accountType && 'error'}`}
                      // isDisabled={props.isNew ? 'true' : 'false'}
                    />
                  }
                  control={control}
                  rules={{ required: true}}
                  name="registrationType"
                />
                {errors.accountType && <div className="errorField">This field is required</div>}
              </div>
              </div>

              <div className="col-md-12">
              <div className="form-group">
                <label>Company name</label>
                <input
                  {...register("company", { required: true })}
                  type="text"
                  name="company"
                  value={formData.client.company}
                  onChange={(e)=>_updateField('client','company',e.target.value)}
                  className={`form-control ${errors.company && 'error'}`}
                />
                {errors.company && <div className="errorField">This field is required</div>}
              </div>
              </div>

              <div className="col-md-6">
              <div className="form-group">
                <label>First name</label>
                <input
                  {...register("firstName", { required: true })}
                  type="text"
                  name="firstName"
                  value={formData.user.firstName}
                  onChange={(e)=>_updateField('user','firstName',e.target.value)}
                  className={`form-control ${errors.firstName && 'error'}`}
                />
                {errors.firstName && <div className="errorField">This field is required</div>}
              </div>
              </div>

              <div className="col-md-6">
              <div className="form-group">
                <label>Last name</label>
                <input
                  {...register("lastName", { required: true })}
                  type="text"
                  name="lastName"
                  value={formData.user.lastName}
                  onChange={(e)=>_updateField('user','lastName',e.target.value)}
                  className={`form-control ${errors.lastName && 'error'}`}
                />
                {errors.lastName && <div className="errorField">This field is required</div>}
              </div>
              </div>

              <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <div className="relative">
                <input
                  {...register("email", { required: true })}
                  type="email"
                  name="email"
                  value={formData.user.email}
                  onChange={(e)=>_updateField('user','email',e.target.value)}
                  className={`form-control ${errors.email && 'error'}`}
                />
                {!available1 && formData.email !== '' && <div className="errorCheck">Taken</div>}
                {available1 && formData.email !== '' && <div className="successCheck">Available</div>}
                </div>
                {errors.email && <div className="errorField">This field is required</div>}
              </div>
              </div>

              <div className="col-md-6">
              <div className="form-group">
                <label>Phone</label>
                <input
                  {...register("phone", { required: false })}
                  type="text"
                  name="phone"
                  value={formData.user.phone}
                  onChange={(e)=>_updateField('user','phone',e.target.value)}
                  className={`form-control ${errors.phone && 'error'}`}
                />
                {errors.phone && <div className="errorField">This field is required</div>}
              </div>
              </div>

              <div className="col-12"><hr/></div>

              <div className="col-md-6">
              <div className="form-group">
                <label>Max employees/users</label>
                <input
                  {...register("maxEmployees", { required: true, min:1, max:100 })}
                  type="number"
                  name="maxEmployees"
                  value={formData.client.maxEmployees}
                  onChange={(e)=>_updateField('client','maxEmployees',e.target.value)}
                  className={`form-control ${errors.maxEmployees && 'error'}`}
                  min="1"
                  max="100"
                />
                {errors.maxEmployees && <div className="errorField">This field is required</div>}
              </div>
              </div>

            </div>

          </div>
          <div className="col-md-5 col-lg-4">
            <div className="card mt-2 mb-4">

              <div><h4>Owner login</h4></div>

              <div className="form-group">
                <label>Username</label>
                <div className="relative">
                <input
                  {...register("username", { required: true })}
                  type="text"
                  name="username"
                  value={formData.user.username}
                  onChange={(e)=>_updateField('user','username',e.target.value)}
                  className={`form-control ${errors.username && 'error'}`}
                />
                {!available2 && formData.username !== '' && <div className="errorCheck">Taken</div>}
                {available2 && formData.username !== '' && <div className="successCheck">Available</div>}
                </div>
                {errors.username && <div className="errorField">This field is required</div>}
              </div>

              <div><hr/></div>

              {id !== 'new' && <p className="small">If you do't want change password, please leave fields blank.</p>}

              <div className="form-group">
                <label>Password</label>
                <div className="relative">
                <input
                  {...register("password", { required: id==='new' ? true : false, minLength: { value: 8, message: 'Password must have at least 8 characters' } })}
                  type={showPass1 ? 'text' : 'password'}
                  name="password"
                  value={formData.user.password}
                  onChange={(e)=>_updateField('user','password',e.target.value)}
                  className={`form-control ${errors.password && 'error'}`}
                />
                {!showPass1 ? <Eye onClick={()=>{ setShowPass1(true) }} className="appendIcon"/> : <EyeOff onClick={()=>{ setShowPass1(false) }} className="appendIcon"/>}
                </div>
                {errors.password && <div className="errorField">This field is required</div>}
              </div>

              <div className="form-group">
                <label>Confirm password</label>
                <div className="relative">
                <input
                  {...register("passwordConfirm", { validate: value => value === getValues('password') })}
                  type={showPass2 ? 'text' : 'password'}
                  name="passwordConfirm"
                  value={formData.user.passwordConfirm}
                  onChange={(e)=>_updateField('user','passwordConfirm',e.target.value)}
                  className={`form-control ${errors.passwordConfirm && 'error'}`}
                />
                {!showPass2 ? <Eye onClick={()=>{ setShowPass2(true) }} className="appendIcon"/> : <EyeOff onClick={()=>{ setShowPass2(false) }} className="appendIcon"/>}
                </div>
                {errors.passwordConfirm && <div className="errorField">The passwords do not match</div>}
              </div>

            </div>
          </div>
        </div>

        <Loading status={loading}/>
      </div>
      </form>

    </Fragment>
  );

}
