import { Fragment } from 'react'
import { Search, XCircle } from 'react-feather'

export default function TextSearchField(props) {


  return (
    <Fragment>
      <div className="formSearch">
        <input
          {...props}
          type="text"
          autoComplete="off"
          onChange={(e)=> props.onChange(e.target.value)}
        />
        {props.value === '' ? <Search size={20} className="stateEmpty"/> : <XCircle size={20} className="stateFilled" onClick={()=> props.onChange('')}/>}
      </div>
    </Fragment>
  );
};
