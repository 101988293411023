import React, { Fragment } from 'react';
import { Save, XCircle, PlusCircle } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import DatePicker, { registerLocale } from 'react-datepicker'

// components
import Loading from '../../components/Loading';


export default function RequestFormsStep3(props){

  const fields = {
    dateCreation: null
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(props.data?.company?.dateCreation){
        setFormData({
          dateCreation: props.data.company.dateCreation,
        })
        setValue('dateCreation', props.data.company.dateCreation)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[props.data])



  //
  //
  const _submit = async () => {
    props.action('step3',{company:{dateCreation:formData.dateCreation}})
  }


  //
  //
  return (
    <Fragment>
      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <div className="relative w-100">

        <div className="row">
          <div className="col-lg-4">

            <div className="form-group">
              <label>Date of creation {props.isShortcodeVisible && <small>{'{COMPANY_DATE_CREATION}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                    <DatePicker
                      selected={formData.dateCreation ? new Date(formData.dateCreation) : formData.dateCreation}
                      onChange={(date)=> {field.onChange(date); _updateField('dateCreation',date)}}
                      name={'dateCreation'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control"
                      dateFormat="dd.MM.yyyy"
                    />
                  }
                control={control}
                rules={{ required: true}}
                name={'dateCreation'}
              />
              {errors.dateCreation && <div className="errorField">This field is required</div>}
            </div>

          </div>
          <div className="col-lg-8">

          </div>
        </div>

        <hr/>

        <div className="d-flex justify-content-end mt-5">
          <button type="submit" className="button accent lg ml-1" disabled={props.data.status===11 ? true : false}><Save/>Save step #4</button>
        </div>

        <Loading status={loading}/>
      </div>
      </form>
    </Fragment>
  );

}
