import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { List, Save, ChevronLeft } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'

// variables
import { SITENAME } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPOST, _apiPUT } from '../../controllers/Api';
import { _goBack } from '../../controllers/Helpers';


export default function TemplateFormBanks(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const fields = {
    name: '',
    clientId: auth.response.clientId
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    let route = '/main-banks/'
    if(auth.response.role >= 10){
      route = '/banks/'
    }

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone(route+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          setFormData({...res})
          setValue('title', res.title)
          setValue('template', res.template)
        }
      }
      setLoading(false)
    }
    if(auth.status){
      getData()
    }

    return (() => [getData])
  },[id, auth.status, setValue, auth.response.role])


  //
  //
  const _title = () =>{
    return `Templates / Banks - ${id==='new' ? 'New entry' : 'Update'}`
  }


  //
  //
  const _submit = async () => {

    let route = '/main-banks/'
    if(auth.response.role >= 10){
      route = '/banks/'
    }

    if(id==='new'){

      delete formData.passwordConfirm

      const insert = await _apiPOST(route, formData)
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        history.push('/templates/banks/')
      }

    }else{

      delete formData.dateCreated
      delete formData.dateUpdated
      delete formData._id

      const update = await _apiPUT(route+id, formData)
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        history.push('/templates/banks/')
      }

    }

  }


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <PageHead
        title={_title()}
        icon={<List size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
        <button className="button accent lg ml-1"><Save/>save</button>
      </PageHead>

      <div className="relative w-100">

        <div className="row">
          <div className="col-12">

            <div className="form-group">
              <label>Title</label>
              <input
                {...register("name", { required: true })}
                type="text"
                name="name"
                value={formData.name}
                onChange={(e)=>_updateField('name',e.target.value)}
                className={`form-control ${errors.name && 'error'}`}
              />
              {errors.name && <div className="errorField">This field is required</div>}
            </div>

          </div>
        </div>

        <Loading status={loading}/>
      </div>
      </form>

    </Fragment>
  );

}
