import React from 'react';


//
//
export default function Loading(props){

  return (
    <div className={`_loading ${props.cssClass} ${props.status ? ' ' : ' hide'}`}>
      <i></i>
      <i></i>
      <i></i>
    </div>
  )

}
