import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useForm } from "react-hook-form"
import { Eye, EyeOff } from 'react-feather'
import { toast } from 'react-toastify'

// variables
import { SITENAME } from '../../configuration.js';

// components
import Loading from '../../components/Loading';

// controllers
import { _apiLogin } from '../../controllers/Api';
import _actions from '../../controllers/redux/actions'


//
//
export default function Login(props){

  const fields = {
    username: '',
    password: ''
  }

  const dispatch = useDispatch();

  const [ formData, setFormData ] = React.useState(fields)
  const { register, formState: { errors }, handleSubmit } = useForm()
  const [ showPass, setShowPass ] = React.useState(false)
  const [ loading, setLoading ] = React.useState(false)


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _submit = async () => {

    setLoading(true);

    const login = await _apiLogin(formData);

    if(login.error){
      setLoading(false);
      toast.error(login.error.message);
    }else{
      if(login.statusCode===403){
        toast.error(login.message);
      }else{
        dispatch(_actions.auth.authUpdate({...login}))
        window.location.href = '/'
      }
      setLoading(false);
    }

  }

  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Login | { SITENAME }</title>
      </Helmet>


      <div className="page-login">
        <div className="container">

          <div className="row no-gutters">
            <div className="col-md-5">
              <div className="form">
              <form onSubmit={handleSubmit(_submit)} autoComplete="off">

                <div className="form-group">
                  <label>Email or Username</label>
                  <input
                    {...register("username", { required: true })}
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={(e)=>_updateField('username',e.target.value)}
                    className={`form-control ${errors.username ? 'error' : ''}`}
                  />
                  {errors.username && <div className="errorField">This field is required</div>}
                </div>

                <div className="form-group">
                  <label>Password</label>
                  <div className="relative">
                    <input
                      {...register("password", { required: true })}
                      type={showPass ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      onChange={(e)=>_updateField('password',e.target.value)}
                      className={`form-control ${errors.password ? 'error' : ''}`}
                      onKeyPress={e => e.key === 'Enter' && e.currentTarget.blur()}
                    />
                    {!showPass && <Eye onClick={()=>{ setShowPass(true) }} className="appendIcon"/>}
                    {showPass && <EyeOff onClick={()=>{ setShowPass(false) }} className="appendIcon"/>}
                  </div>
                  {errors.password && <div className="errorField">This field is required</div>}
                </div>

                <button type="submit" className="button xl dark fullWidth mt-4">Login</button>

              </form>

              <Loading status={loading}/>
              </div>
            </div>
            <div className="col-md-7">
              <div className="promo">
                <h1>Hello,<br/><strong>Welcome Back</strong></h1>
                <p className="small"><strong>{SITENAME}</strong> Administration</p>
              </div>
            </div>
          </div>

        </div>
      </div>

    </Fragment>
  );

}



// {loading && <Loading status={true} cssClass="full"/>}
// <div key="1">{_box1()}</div>
// <div key="2">{_box2()}</div>
// <div key="3">{_box3()}</div>
// <div key="4">{_box4()}</div>
