import React, { Fragment } from 'react';
import { Save } from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import Select from 'react-select'

// components
import Loading from '../../components/Loading';
import ContentEditorBasic from '../../components/ContentEditor/basic';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiPOST, _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep10(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const fields = {
    subject: '',
    content: '',
    _id: null,
    to: null,
    template: 'none',
    userId:auth.response._id,
    clientId: auth.response.clientId
  }

  const [ loading, setLoading ] = React.useState(false);
  const [ formData, setFormData ] = React.useState(fields)

  const [ templates, setTemplates ] = React.useState([{_id:'none', title:'default'}]) 

  const { control, register, handleSubmit, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _handleEditorChange = (content, editor) => {
    setFormData({...formData, content:content})
  }


  //
  //
  React.useEffect(()=>{

    let route = '/emails/query'

    const getData = async () =>{
      const res = await _apiGETmulti(route, {
        limit:100, 
        offset: 0,
        orderBy: 'dateCreated|desc',
        search: '',
        clientId:auth.response.clientId
      })
      if(!res){

      }else{
        setTemplates([...templates, ...res.data])
      }
    }
    getData()

    return (() => [getData])
  },[])


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(props.data._id){
        setFormData({
          ...formData,
          _id: props.data._id,
          to: props.data.start.email
        })
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[props.data])



  //
  //
  const _submit = async () => {

    if(formData.subject === '' || formData.content === ''){
      toast.error('Field "subject" and "content" from editor is required.');
      return;
    }

    setLoading(true);

    const insert = await _apiPOST('/requests/send-email/', {...formData, template: formData.template})
    if(insert.statusCode !== 200){

      toast.error(insert.message);
      setLoading(false);

    }else{

      props.updateRand()
      setFormData({
        ...formData,
        subject: '',
        content: '',
        template: 'none'
      })
      toast.success(insert.message);
      setLoading(false);

    }

  }


  //
  //
  return (
    <Fragment>
      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <div className="relative w-100">

        <div className="row">
          <div className="col-12">

            <div className="form-group" style={{zIndex:9, position:'relative'}}>
              <label>Email template</label>
              <Controller
                render={
                  ({ field }) =>
                  <Select
                    name="template"
                    value={templates.find(x=> formData.template===x._id)}
                    options={templates}
                    onChange={(e)=> {
                      field.onChange(e._id);
                      setFormData({
                        ...formData,
                        template: e._id,
                        subject: e.title,
                        content: e.template
                      })
                    }}
                    className={`form-control p-0 ${errors.template && 'error'}`}
                    getOptionLabel={(d) => d.title}
                    getOptionValue={(d) => d._id}
                  />
                }
                control={control}
                rules={{ required: false}}
                name="objective"
              />
              {errors.objective && <div className="errorField">This field is required</div>}
            </div>

            <div className="form-group">
              <label>Title</label>
              <input
                {...register("subject", { required: true })}
                type="text"
                name="subject"
                value={formData.subject}
                onChange={(e)=>_updateField('subject',e.target.value)}
                className={`form-control ${errors.subject && 'error'}`}
              />
              {errors.subject && <div className="errorField">This field is required</div>}
            </div>

            <div className="form-group" style={{zIndex:1, position:'relative'}}>
              <label>Email content</label>
              <ContentEditorBasic
                value={formData.content}
                initialValue={formData.content}
                onEditorChange={_handleEditorChange}
              />
            </div>

          </div>
        </div>

        <hr/>

        <div className="d-flex justify-content-end">
          <button type="submit" className="button accent lg ml-1"><Save/>Send email</button>
        </div>

        <Loading status={loading}/>
      </div>
      </form>
    </Fragment>
  );

}
