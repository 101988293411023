import React, { Fragment } from 'react';
import { Save } from 'react-feather'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs';

// components
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiPOST, _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep9(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const fields = {
    message: '',
    userId:auth.response._id,
    requestId: null
  }

  const [ loading, setLoading ] = React.useState(false);
  const [ formData, setFormData ] = React.useState(fields)

  const { register, handleSubmit, formState: { errors } } = useForm();

  const charLimit = 500;
  const [charCount, setCharCount] = React.useState(charLimit);

  const [ data, setData ] = React.useState([])
  const [ total, setTotal ] = React.useState(0)

  const [ rand, setRand ] = React.useState(1);


  //
  //
  const _updateField = (field, data) => {
    const charCount = data.length;
    const charLeft = charLimit - charCount;
    setCharCount(charLeft);
    if(charLeft>0){
      setFormData({...formData, [field]:data});
    }
  }



  //
  //
  const _submit = async () => {

    if(formData.message === ''){
      toast.error('Message field is required.');
      return;
    }

    setLoading(true);

    const insert = await _apiPOST('/notes/', formData)
    if(insert.statusCode !== 200){

      toast.error(insert.message);
      setLoading(false);

    }else{

      // props.updateRand()
      setFormData({
        ...formData,
        message: ''
      })
      toast.success(insert.message);
      setLoading(false);
      setRand(rand+1)

    }

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(props.data._id){
        setFormData({
          ...formData,
          requestId: props.data._id
        })
        const res = await _apiGETmulti('/notes/'+props.data._id, {
          limit: 500,
          offset: 0,
          orderBy: 'dateCreated|desc'
        })
        if(!res){
          setTotal(0)
          setData([])
        }else{
          setTotal(res.total)
          setData(res.data)
          props.updateNotesTotal(res.total)
        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[props.data, rand])


  //
  //
  return (
    <Fragment>
      <div className="relative w-100">

        <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
        <div className="row d-flex align-items-end">
          <div className="col-md-9">

            <div className="form-group mb-0 pb-0">
              <textarea
                {...register("message", { required: true })}
                name="message"
                value={formData.message}
                onChange={(e)=>_updateField('message',e.target.value)}
                className={`form-control ${errors.message && 'error'}`}
                placeholder="Message...."
              />
              {errors.message && <div className="errorField">This field is required</div>}
            </div>

          </div>
          <div className="col-md-3">
            <small>{charCount}/{charLimit}</small>
            <button type="submit" className="button accent fullWidth lg ml-1"><Save/>Save</button>
          </div>
        </div>
        </form>

        <hr/>

        <div className="">
        {
          total>0 && data.map((item,index)=>{
            return (
              <div className="py-3" key={index}>
                <div className="row d-flex align-items-end">
                  <div className="col-7">
                    <strong className="ml-4">{item.user ? item.user[0].firstName+' '+item.user[0].lastName : 'N/A'}</strong>
                  </div>
                  <div className="col-5 text-right">
                  <small>{dayjs.tz(item.dateCreated).format('DD.MM.YYYY HH:mm')}</small>
                  </div>
                </div>
                <div className="notes">{item.message}</div>
              </div>
            )
          })
        }
        </div>

        <Loading status={loading}/>
      </div>
    </Fragment>
  );

}
