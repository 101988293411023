import React, { Fragment } from 'react';
import { Save, XCircle, PlusCircle } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import DatePicker, { registerLocale } from 'react-datepicker'

// components
import Loading from '../../components/Loading';


export default function RequestFormsStep5(props){

  const fields = {
    datePayment: null,
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)
  const [ sendEmail, setSendEmail ] = React.useState(false);

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(props.data.datePayment){
        setFormData({
          datePayment: props.data.datePayment,
        })
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[props.data])



  //
  //
  const _submit = async () => {
    props.action('step5',{...formData, sendEmail:sendEmail})
  }


  //
  //
  return (
    <Fragment>
      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <div className="relative w-100">

        <div className="row">
          <div className="col-lg-4">

            <div className="form-group">
              <label>Date of payment {props.isShortcodeVisible && <small>{'{PAYMENT_DATE}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                    <DatePicker
                      selected={formData.datePayment ? new Date(formData.datePayment) : formData.datePayment}
                      onChange={(date)=> {field.onChange(date); _updateField('datePayment',date)}}
                      name={'datePayment'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control"
                      dateFormat="dd.MM.yyyy"
                    />
                  }
                control={control}
                rules={{ required: true}}
                name={'dateInvoice'}
              />
              {errors.datePayment && <div className="errorField">This field is required</div>}
            </div>

          </div>
          <div className="col-lg-8">

          </div>
        </div>

        <hr/>

        <div className="d-flex justify-content-between mt-5">
          <div>
            {/* <div className="switch mb-4 mb-md-0">
              <input
                type="checkbox"
                name={'sendEmailStep1'}
                value="1"
                checked={sendEmail}
                onChange={(e)=>setSendEmail(!sendEmail)}
                className={`form-control`}
              />
                <div><i></i></div>
              <label>Send email to client | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
            </div> */}
          </div>
          <button type="submit" className="button accent lg ml-1" disabled={props.data.status===11 ? true : false}><Save/>Save step #4</button>
        </div>

        <Loading status={loading}/>
      </div>
      </form>
    </Fragment>
  );

}
