import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from "react-hook-form"
import { Eye, EyeOff } from 'react-feather'
import { toast } from 'react-toastify'

// variables
import { SITENAME } from '../../configuration.js';

// components
import Loading from '../../components/Loading';
import RequestFormsStep1 from '../Requests/step1';

// controllers
import { _apiPOST, _apiPUT } from '../../controllers/Api';
import _actions from '../../controllers/redux/actions'


//
//
export default function Request(props){

  const dispatch = useDispatch();
  const { token } = useParams()

  const { register, formState: { errors }, handleSubmit } = useForm()
  const [ showPass, setShowPass ] = React.useState(false)
  const [ loading, setLoading ] = React.useState(false)

  const [ data, setData ] = React.useState(null)

  const [ responseType, setResponseType ] = React.useState(null);
  const [ responseMessage, setResponseMessage] = React.useState('')


  //
  //
  const _submit = async (k,v) => {

    setLoading(true);

    let formData2 = {
      ...v,
      type: 'step #1',
      status:4,
      token: token
    }


    const update = await _apiPUT('/requests/public/', formData2)
    if(update.statusCode !== 200){
      setResponseType(1)
      setResponseMessage(update.message)
    }else{
      setResponseType(2)
      setResponseMessage(update.message)
    }


    setLoading(false);

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    let route = '/requests/by-token/'

    const getData = async () =>{

      const res = await _apiPOST(route, {token:token})
      if(!res._id){
        setResponseType(1)
        setResponseMessage(res.message)
      }else{
        setData({...res})
      }

      setLoading(false)
    }

    if(token){
      getData()
    }

    return (() => [getData])
  },[token])


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Request form | { SITENAME }</title>
      </Helmet>

      <div className="publicForm">
        <div className="container">

          {responseType===1 &&
            <div className="alert alert-danger"><h4>ERROR</h4>{responseMessage}</div>
          }
          {responseType===2 &&
            <div className="alert alert-success"><h4>Success</h4>{responseMessage}</div>
          }

          {data && !responseType && <div className="card"><RequestFormsStep1 data={data} action={_submit} alt={true}/></div>}

          <Loading status={loading}/>

        </div>
      </div>

    </Fragment>
  );

}



// {loading && <Loading status={true} cssClass="full"/>}
// <div key="1">{_box1()}</div>
// <div key="2">{_box2()}</div>
// <div key="3">{_box3()}</div>
// <div key="4">{_box4()}</div>
