import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { Mail, Save, ChevronLeft } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'

// variables
import { SITENAME } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import ContentEditorFull from '../../components/ContentEditor/full';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPOST, _apiPUT, _apiAvailability } from '../../controllers/Api';
import { _goBack } from '../../controllers/Helpers';


export default function TemplateFormEmail(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const fields = {
    title: '',
    key: '',
    template: ''
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [ available1, setAvailable1 ] = React.useState(true)


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _handleEditorChange = (content, editor) => {
    setFormData({...formData, template:content})
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    let route = '/main-emails/'
    if(auth.response.role>2){
      route = '/emails/'
    }

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone(route+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          setFormData({...res})
          setValue('title', res.title)
          setValue('template', res.template)
          setValue('key', res.key)
        }
      }
      setLoading(false)
    }
    if(auth.status){
      getData()
    }

    return (() => [getData])
  },[id, auth.status, setValue, auth.response.role])


  //
  //
  const _title = () =>{
    return `Templates / Mail - ${id==='new' ? 'New entry' : 'Update'}`
  }


  //
  //
  const _submit = async () => {

    let route = '/main-emails/'
    if(auth.response.role>2){
      route = '/emails/'
    }

    if(id==='new'){

      delete formData.passwordConfirm

      const insert = await _apiPOST(route, formData)
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        history.push('/templates/emails/')
      }

    }else{

      delete formData.dateCreated
      delete formData.dateUpdated
      delete formData._id

      const update = await _apiPUT(route+id, formData)
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        history.push('/templates/emails/')
      }

    }

  }



  //
  //
  const _checkAvailability = async(value) => {

    const isAvailable = await _apiAvailability('emailKey', value)
    if(isAvailable){
      setAvailable1(true)
    }else{
      setAvailable1(false)
    }

  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => auth.response.role <= 1 && formData.key !== '' ? _checkAvailability({key:formData.key, id:formData._id}) : null, 1000 );
    return () => clearTimeout(timeoutId);
  }, [formData.key, formData._id]);



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{_title()} | { SITENAME }</title>
      </Helmet>

      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <PageHead
        title={_title()}
        icon={<Mail size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
        <button className="button accent lg ml-1"><Save/>save</button>
      </PageHead>

      <div className="relative w-100">

        <div className="row">
          <div className="col-12">

            <div className="row">
              <div className="col-lg-9">
                <div className="form-group">
                  <label>Title / Subject</label>
                  <input
                    {...register("title", { required: true })}
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={(e)=>_updateField('title',e.target.value)}
                    className={`form-control ${errors.title && 'error'}`}
                  />
                  {errors.title && <div className="errorField">This field is required</div>}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>Template key</label>
                  <div className="relative">
                  <input
                    {...register("key", { required: auth.response.role < 2 ? true : false })}
                    type="text"
                    name="key"
                    value={formData.key}
                    onChange={(e)=>_updateField('key',e.target.value)}
                    className={`form-control ${errors.key && 'error'}`}
                    disabled={auth.response.role > 1 ? true : false}
                  />
                  {auth.response.role < 2 &&
                    <>
                    {!available1 && formData.key !== '' && <div className="errorCheck">Taken</div>}
                    {available1 && formData.key !== '' && <div className="successCheck">Available</div>}
                    </>
                  }
                  </div>
                  {errors.key && <div className="errorField">This field is required</div>}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Template content</label>
              <ContentEditorFull
                value={formData.template}
                initialValue={formData.template}
                onEditorChange={_handleEditorChange}
              />
            </div>

          </div>
        </div>

        <Loading status={loading}/>
      </div>
      </form>

    </Fragment>
  );

}
