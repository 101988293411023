import { Fragment } from 'react';
import { useDispatch } from 'react-redux'
// import { Link, useLocation } from 'react-router-dom'
import { Power, AlignLeft } from 'react-feather'

// controllers
import _actions from '../../controllers/redux/actions'


//
//
export default function Header(props) {

  const dispatch = useDispatch()


  //
  //
  const _logout = () => {
    dispatch(_actions.auth.authLogout())
    window.location.href = '/'
  }


  //
  //
  return (
    !props.isLoggedIn ? null :
    <Fragment>
      <header className="header">

        <div>
          <button className="navicon" ><AlignLeft size={30}/></button>
        </div>
        <div>
          <div className="username">Welcome back, <strong>{props.auth.response.username}</strong></div>
          <button onClick={()=> _logout()} className="logout"><Power /></button>
        </div>

      </header>
    </Fragment>
  );
};
