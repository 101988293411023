import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'

// variables
import { SITENAME } from '../../configuration.js';


export default function Page404(props){


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>404 not found | { SITENAME }</title>
      </Helmet>

      <div className="container d-flex py-5">
        <div className="ErrorPage d-flex flex-column align-items-start">
          <h1 style={{fontSize:'52px'}}>404 <small style={{fontSize:'18px'}}>Page not found</small></h1>
          <p>The requested page does not exist, or may have been deleted.</p>
          <a href="/" className="button accent">Back to the Dashboard</a>
        </div>
      </div>

    </Fragment>
  );

}
