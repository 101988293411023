import React, { Fragment } from 'react'

// components
import Header from '../Header'
import Sidebar from '../Sidebar';

export default function Layout(props) {

  const [isOpen, setOpen] = React.useState(true);


  return (
    <Fragment>
      {props.isLoggedIn && <Header isLoggedIn={props.isLoggedIn} auth={props.auth} open={(e)=> setOpen(e) }/>}
      <div className="wrapper">
        {props.isLoggedIn && <Sidebar isLoggedIn={props.isLoggedIn} auth={props.auth}/>}
        <div className={`main ${isOpen && props.isLoggedIn ? ' open' : ''}`}>
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};
