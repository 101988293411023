import React, { Fragment } from 'react';
import { Save, XCircle, PlusCircle } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import DatePicker, { registerLocale } from 'react-datepicker'

// components
import Loading from '../../components/Loading';


export default function RequestFormsStep4(props){

  const fields = {
    amount:null,
    dateInvoice: null,
    datePayment2: null,
    discount: null,
    additionalCharge:[]
  }

  const chargeFields = {
    description:'',
    price: null
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)
  const [ formDataCharge, setFormDataCharge ] = React.useState([])

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [ sendEmail, setSendEmail ] = React.useState(false);


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _updateFieldCharge = (field, data, index) => {
    let newArray = formDataCharge
    newArray[index] = {
      ...newArray[index],
      [field]: data
    }
    setFormDataCharge([...newArray]);
  }


  //
  //
  const _addRepeaterChargeItem = () => {
    setFormDataCharge(charge => [...formDataCharge, chargeFields])
  }

  //
  //
  const _removeRepeaterChargeItem = (index) => {
    let newArray = formDataCharge.filter((x, i)=>{
      return i !== index && x
    });
    setFormDataCharge(charge => newArray)
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(props.data.company){
        setFormData({
          amount: props.data.invoice ? props.data.invoice.amount : null,
          discount: props.data.invoice ? props.data.invoice.discount : null,
          dateInvoice: props.data.invoice ? props.data.invoice.dateInvoice : null,
          datePayment2: props.data.datePayment2 ? props.data.datePayment2 : null,
        })
        if(props.data.invoice){
          setValue('amount', props.data.invoice.amount)
          setValue('discount', props.data.invoice.discount)
          setValue('dateInvoice', props.data.invoice.dateInvoice)
          if(props.data.invoice.additionalCharge){

            setFormDataCharge(props.data.invoice.additionalCharge)
            props.data.invoice.additionalCharge.map((item,index)=>{
              setValue('description'+index, item.description)
              setValue('price'+index, item.price)
            })

          }
        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[props.data.company, props.data.invoice])



  //
  //
  const _submit = async () => {
    props.action('step4',{invoice:{dateInvoice:formData.dateInvoice, discount:formData.discount, additionalCharge:formDataCharge, amount:formData.amount}, datePayment2:formData.datePayment2, sendEmail:sendEmail})
  }


  //
  //
  const _repeaterChargeItem = (item, index) => {

    const ___description = 'description'+index
    const ___price = 'price'+index

    return (
      <div className="repeaterItem" key={index}>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group">
            <label>Description {props.isShortcodeVisible && <small>{'(description)'}</small>}</label>
            <input
              {...register(___description, { required: true })}
              type="text"
              name={___description}
              value={item.description}
              onChange={(e)=>_updateFieldCharge('description',e.target.value, index)}
              className={`form-control ${errors.___description && 'error'}`}
            />
            {errors[___description] && <div className="errorField">This field is required</div>}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Price {props.isShortcodeVisible && <small>{'(price)'}</small>}</label>
            <input
              {...register(___price, { required: true })}
              type="number"
              name={___price}
              value={item.price}
              onChange={(e)=>_updateFieldCharge('price',e.target.value, index)}
              className={`form-control ${errors.___price && 'error'}`}
            />
            {errors[___price] && <div className="errorField">This field is required</div>}
          </div>
        </div>
      </div>
      <div className="options">
        <span>{index+1}</span>
        <button type="button" className="button blank" onClick={()=> _removeRepeaterChargeItem(index)}><XCircle sizes={24} color={'#ff0000'}/></button>
      </div>
      </div>
    )
  }


  //
  //
  return (
    <Fragment>
      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <div className="relative w-100">


        <div className="row">
          <div className="col-lg-4">

            <div className="form-group">
              <label>Amount {props.isShortcodeVisible && <small>{'{INVOICE_AMOUNT}'}</small>}</label>
              <input
                {...register("amount", { required: true })}
                type="number"
                name="amount"
                value={formData.amount}
                onChange={(e)=>_updateField('amount',e.target.value)}
                className={`form-control`}
                step="0.01"
              />
              {errors.amount && <div className="errorField">This field is required</div>}
            </div>

            <div className="form-group">
              <label>Discount in % {props.isShortcodeVisible && <small>{'{INVOICE_DISCOUNT}'}</small>}</label>
              <input
                type="number"
                name="discount"
                value={formData.discount}
                onChange={(e)=>_updateField('discount',e.target.value)}
                className={`form-control`}
              />
            </div>

            <div className="form-group">
              <label>Date of invoice {props.isShortcodeVisible && <small>{'{INVOICE_DATE}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                    <DatePicker
                      selected={formData.dateInvoice ? new Date(formData.dateInvoice) : formData.dateInvoice}
                      onChange={(date)=> {field.onChange(date); _updateField('dateInvoice',date)}}
                      name={'dateInvoice'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control"
                      dateFormat="dd.MM.yyyy"
                    />
                  }
                control={control}
                rules={{ required: true}}
                name={'dateInvoice'}
              />
              {errors.dateInvoice && <div className="errorField">This field is required</div>}
            </div>

            <div className="form-group">
              <label>Date of payment {props.isShortcodeVisible && <small>{'{PAYMENT_DATE2}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                    <DatePicker
                      selected={formData.datePayment2 ? new Date(formData.datePayment2) : formData.datePayment2}
                      onChange={(date)=> {field.onChange(date); _updateField('datePayment2',date)}}
                      name={'datePayment2'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control"
                      dateFormat="dd.MM.yyyy"
                    />
                  }
                control={control}
                rules={{ required: false}}
                name={'datePayment2'}
              />
              {errors.datePayment2 && <div className="errorField">This field is required</div>}
            </div>

          </div>
          <div className="col-lg-8">

            <h4>Additional charge</h4>
            {formDataCharge.length===0 && <p className="my-2 pt-1 mb-5">There's no additional charge</p>}
            {
              formDataCharge.length>0 && formDataCharge.map((item,index)=>{
                return _repeaterChargeItem(item, index)
              })
            }
            <button type="button" onClick={()=> _addRepeaterChargeItem()} className="button dark outline lg ml-1"><PlusCircle/>Add new</button>

          </div>
        </div>

        <hr/>

        <div className="d-flex justify-content-between mt-5">
          <div>
            <div className="switch mb-4 mb-md-0">
              <input
                type="checkbox"
                name={'sendEmailStep1'}
                value="1"
                checked={sendEmail}
                onChange={(e)=>setSendEmail(!sendEmail)}
                className={`form-control`}
              />
                <div><i></i></div>
              <label>Send email to client | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
            </div>
          </div>
          <button type="submit" className="button accent lg ml-1" disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>Save step #4</button>
        </div>

        <Loading status={loading}/>
      </div>
      </form>
    </Fragment>
  );

}
