import React, { Fragment } from 'react';
import { Save } from 'react-feather'
import { useForm, Controller  } from "react-hook-form"
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'

// components
import Loading from '../../components/Loading';
import ContentEditorBasic from '../../components/ContentEditor/basic'

// controllers
import _actions from '../../controllers/redux/actions'
import { _itemByValue } from '../../controllers/Helpers';
import { _apiGETmulti } from '../../controllers/Api';


export default function RequestFormsStep2(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const fields = {
    bankId: null,
    date1stCloture: null,
    dateStatus: null,
    dateDeposit: null,
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)
  const [ banks, setBanks ] = React.useState([])

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [ sendEmail, setSendEmail ] = React.useState(false);

  const [ goalErrors, setGoalError ] = React.useState(false)


  //
  //
  const _handleEditorChange = (content, editor) => {
    setFormData({...formData, additonalContent:content})
  }


  //
  //
  const _handleEditorChange2 = (content, editor) => {
    setGoalError(null)
    setFormData({...formData, goal:content})
  }


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{
    if(formData.capital !== 0 && formData.partsNumbers !== 0){
      const partsValue = (formData.capital/formData.partsNumbers)
      _updateField('partsValue', partsValue.toFixed(2));
      // formDataPeoples.length>0 && formDataPeoples.map((item,index)=>{
      //   _updateFieldPeoples('totalNumber',partsValue !== 0 && item.actionsNumber !== 0 ? (parseFloat(partsValue)*parseFloat(item.actionsNumber)).toFixed(2) : 0,index)
      // })
    }
  },[formData.capital, formData.partsNumbers])


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      const res = await _apiGETmulti('/banks/query', {limit:1000})
      if(!res){
        setBanks([])
      }else{
        let newBanksArray = [];
        res.data && res.data.map((item,index)=>{
          newBanksArray.push({value:item._id, label:item.name})
          return null
        })
        setBanks(newBanksArray)

        if(props.data.company){
          setFormData({...props.data.company})
          // setValue('bankId', props.data.company.bankId)
          // setValue('date1stCloture', props.data.company.date1stCloture)
          setValue('dateStatus', props.data.company.dateStatus)
          // setValue('dateDeposit', props.data.company.dateDeposit)

          setValue('goal', props.data.company.goal)
          setValue('capital', props.data.company.capital)
          setValue('capitalWritten', props.data.company.capitalWritten)
          setValue('partsNumbers', props.data.company.partsNumbers)
          setValue('partsWritten', props.data.company.partsWritten)
          setValue('partsValue', props.data.company.partsValue)
          setValue('partsValueWritten', props.data.company.partsValueWritten)
          setValue('capitalLibere', props.data.company.capitalLibere)
        }
      }
      setLoading(false)
    }
    if(auth.status){
      getData()
    }

    return (() => [getData])
  },[props.data.company])



  //
  //
  const _submit = async () => {
    // props.action('step3',formData)
    if(formData.goal === ''){
      setGoalError(true);
      return;
    }
    props.action('step2',{company:formData, sendEmail:sendEmail})
  }


  //
  //
  return (
    <Fragment>
      <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
      <div className="relative w-100">

        <div className="row">
          {/* <div className="col-md-6 col-lg-4">

            <div className="form-group">
              <label>Date of 1st cloture {props.isShortcodeVisible && <small>{'{COMPANY_DATE_1ST_CLOTURE}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <DatePicker
                    selected={formData.date1stCloture ? new Date(formData.date1stCloture) : formData.date1stCloture}
                    onChange={(date)=> {field.onChange(date); _updateField('date1stCloture',date)}}
                    name={'date1stCloture'}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    dateFormat="dd.MM.yyyy"
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'date1stCloture'}
              />
              {errors.date1stCloture && <div className="errorField">This field is required</div>}
            </div>

          </div> */}
          <div className="col-md-6 col-lg-4">

            <div className="form-group">
              <label>Date of status {props.isShortcodeVisible && <small>{'{COMPANY_DATE_STATUS}'}</small>}</label>
              <Controller
                render={
                  ({ field }) =>
                  <DatePicker
                    selected={formData.dateStatus ? new Date(formData.dateStatus) : formData.dateStatus}
                    onChange={(date)=> {field.onChange(date); _updateField('dateStatus',date)}}
                    name={'dateStatus'}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    dateFormat="dd.MM.yyyy"
                  />
                }
                control={control}
                rules={{ required: true}}
                name={'dateStatus'}
              />
              {errors.dateStatus && <div className="errorField">This field is required</div>}
            </div>

          </div>
          
          <div className="col-md-12">
            <hr/>
          </div>

          <div className="col-12">
            <div className="form-group">
                <label>Goal {props.isShortcodeVisible && <small>{'{COMPANY_GOAL}'}</small>}</label>
                <ContentEditorBasic
                  value={formData.goal}
                  initialValue={formData.goal}
                  onEditorChange={_handleEditorChange2}
                />
                {goalErrors && <div className="errorField">This field is required</div>}
              </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Capital {props.isShortcodeVisible && <small>{'{COMPANY_CAPITAL}'}</small>}</label>
              <input
                {...register("capital", { required: true, minvalue:20000 })}
                type="number"
                name="capital"
                value={formData.capital}
                onChange={(e)=>_updateField('capital',e.target.value)}
                className={`form-control ${errors.capital && 'error'}`}
                min="20000"
              />
              {errors.capital && <div className="errorField">This field is required</div>}
            </div>
          </div>
          <div className="col-9">
            <div className="form-group">
              <label>Capital in written {props.isShortcodeVisible && <small>{'{COMPANY_CAPITAL_WRITTEN}'}</small>}</label>
              <input
                {...register("capitalWritten", { required: true })}
                type="text"
                name="capitalWritten"
                value={formData.capitalWritten}
                onChange={(e)=>_updateField('capitalWritten',e.target.value)}
                className={`form-control ${errors.capitalWritten && 'error'}`}
              />
              {errors.capitalWritten && <div className="errorField">This field is required</div>}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Number of parts in numbers {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_NUMBERS}'}</small>}</label>
              <input
                {...register("partsNumbers", { required: true, min:1 })}
                type="number"
                name="partsNumbers"
                value={formData.partsNumbers}
                onChange={(e)=> _updateField('partsNumbers',e.target.value) }
                className={`form-control ${errors.partsNumbers && 'error'}`}
                min="1"
              />
              {errors.partsNumbers && <div className="errorField">This field is required</div>}
            </div>
          </div>
          <div className="col-9">
            <div className="form-group">
              <label>Number of parts in written {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_WRITTEN}'}</small>}</label>
              <input
                {...register("partsWritten", { required: true })}
                type="text"
                name="partsWritten"
                value={formData.partsWritten}
                onChange={(e)=>_updateField('partsWritten',e.target.value)}
                className={`form-control ${errors.partsWritten && 'error'}`}
              />
              {errors.partsWritten && <div className="errorField">This field is required</div>}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Parts value {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_VALUE}'}</small>}</label>
              <input
                type="number"
                name="partsValue"
                value={formData.capital !== 0 && formData.partsNumbers !== 0 ? (formData.capital/formData.partsNumbers).toFixed(2) : 0}
                onChange={(e)=>_updateField('partsValue',e.target.value)}
                className={`form-control`}
                readOnly={true}
              />
            </div>
          </div>
          <div className="col-9">
            <div className="form-group">
              <label>Parts value in written {props.isShortcodeVisible && <small>{'{COMPANY_PARTS_VALUE_WRITTEN}'}</small>}</label>
              <input
                {...register("partsValueWritten", { required: true })}
                type="text"
                name="partsValueWritten"
                value={formData.partsValueWritten}
                onChange={(e)=>_updateField('partsValueWritten',e.target.value)}
                className={`form-control ${errors.partsValueWritten && 'error'}`}
              />
              {errors.partsValueWritten && <div className="errorField">This field is required</div>}
            </div>
          </div>
          {formData.legalStructures === 2 &&
            <div className="col-3">
              <div className="form-group">
                <label>Capital libéré {props.isShortcodeVisible && <small>{'{COMPANY_LIBERE}'}</small>}</label>
                <input
                  {...register("capitalLibere", { required: true, min:0, step:0.01 })}
                  type="number"
                  name="capitalLibere"
                  value={formData.capitalLibere}
                  onChange={(e)=> _updateField('capitalLibere',e.target.value) }
                  className={`form-control ${errors.capitalLibere && 'error'}`}
                  min="0"
                  step="0.01"
                />
                {errors.capitalLibere && <div className="errorField">This field is required</div>}
              </div>
            </div>
          }

        </div>
        

        <hr/>
        
        <ContentEditorBasic
          value={formData.additonalContent}
          initialValue={formData.additonalContent}
          onEditorChange={_handleEditorChange}
        />

        <hr/>

        <div className="d-flex justify-content-between mt-5">
          <div>
            {/* <div className="switch mb-4 mb-md-0">
              <input
                type="checkbox"
                name={'sendEmailStep1'}
                value="1"
                checked={sendEmail}
                onChange={(e)=>setSendEmail(!sendEmail)}
                className={`form-control`}
              />
                <div><i></i></div>
              <label>Send email to client | <strong>{sendEmail ? 'YES' : 'NO'}</strong></label>
            </div> */}
          </div>
          <button type="submit" className="button accent lg ml-1" disabled={props.data.status===10 || props.data.status===11 ? true : false}><Save/>Save step #2</button>
        </div>

        <Loading status={loading}/>
      </div>
      </form>
    </Fragment>
  );

}
